import ApiService from '../../assets/js/api.service';
import {
    ACT_GET_BIZ_LIST2,
    ACT_GET_MY_SCOMMUNITY_COUNT,
    ACT_GET_MY_SCOMMUNITY_LIST,
    ACT_GET_DHFESTA_MY_CAMPN_LIST,
    ACT_GET_DHFESTA_MANPRD_APRV_LIST,
    ACT_UPDATE_DHFESTA_CAMPN_MANPRD_APRV_STS,
    ACT_UPDATE_DHFESTA_CAMPN_MANPRD_EXPSR,
	ACT_GET_DHFESTA_CAMPN_MANPRO,
	ACT_GET_DHFESTA_CAMPN_MANPRO_APRV_HSR_LIST,
} from '../_act_consts';
import {
    MUT_SET_DHFESTA_CAMPN_APRV_STS_DCD_LIST,
} from '../_mut_consts';

const state = {
    dhfesta: {
        aprvStsDcds:[]
    }
}

const getters = {
    dhfestaAprvStsDcds (state) {
        return state.dhfesta.aprvStsDcds;
    },
}

const mutations = {
    [MUT_SET_DHFESTA_CAMPN_APRV_STS_DCD_LIST] (state, aprvStsDcds) {
        state.dhfesta.aprvStsDcds = aprvStsDcds;
    },
}

const actions = {
    [ACT_GET_MY_SCOMMUNITY_COUNT]({}, userId){

        return new Promise(resolve => {
            ApiService.query('/v1/app/scommunity/myCmntCnt', userId).then(response => resolve(response));
        });
    },
    [ACT_GET_MY_SCOMMUNITY_LIST]({}, userId, pageForm){

        return new Promise(resolve => {
            ApiService.query('/v1/app/scommunity/myCmntList', userId, pageForm).then(response => resolve(response));
        });
    },
    [ACT_GET_BIZ_LIST2]({}, params){

        return new Promise(resolve => {
            ApiService.query('/v1/app/supt/biz', params).then(response => resolve(response));
        });
    },
    [ACT_GET_DHFESTA_MY_CAMPN_LIST]({}){

        return new Promise(resolve => {
            ApiService.query('/v1/app/dhfesta/sent/myCampnlist').then(response => resolve(response));
        });
    },
    [ACT_GET_DHFESTA_MANPRD_APRV_LIST]({}, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/dhfesta/manprd/aprvList', params).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_DHFESTA_CAMPN_MANPRD_APRV_STS]({}, {manprdId, params}){
        return new Promise(resolve => {
        ApiService.put(`/v1/app/dhfesta/manprd/${manprdId}/aprvSts`, params).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_DHFESTA_CAMPN_MANPRD_EXPSR]({}, {manprdId, params}){
        return new Promise(resolve => {
        ApiService.put(`/v1/app/dhfesta/manprd/${manprdId}/expsr`, params).then(response => resolve(response));
        });
    },
    [ACT_GET_DHFESTA_CAMPN_MANPRO]({}, {manprdId, params}){
        return new Promise(resolve => {
            ApiService.query(`/v1/app/dhfesta/manprd/${manprdId}`, params).then(response => resolve(response));
        });
    },
    [ACT_GET_DHFESTA_CAMPN_MANPRO_APRV_HSR_LIST]({}, manprdId){
        return new Promise(resolve => {
            ApiService.query(`/v1/app/dhfesta/manprd/${manprdId}/aprvHsrList`).then(response => resolve(response));
        });
    },
}

export const mypage2 = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};