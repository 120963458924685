<template>
  <!-- begin::layer-popup -->
  <div class="layer-popup-container">
    <div class="layer-popup" id="popup-notice">
      <div>
        <div class="popup-wrapper">
          <button type="button" class="btn-close" @click="clickClose">
            <i class="icon-close">팝업닫기</i>
          </button>
          <div class="popup-body">
            <div id="popup-notice-image">
            <swiper class="swiper" :options="swiperOptions">
              <swiper-slide v-for="(item, idx) in notice" :key="idx">
                <a :href="item.ntcUrl" style="display:block;"><img :src="item.ntcImgFilePtUrl" :alt="item.ntcTit"></a>
              </swiper-slide>
              <div v-show="noticeShow" class="swiper-button-prev prevImg" slot="button-prev"></div>
              <div v-show="noticeShow" class="swiper-button-next nextImg" slot="button-next"></div>
            </swiper>    
            </div>
          </div>
          <div class="popup-footer">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="chk"  @click="clickCloseToday"/>
              <label for="chk" class="form-check-label">오늘 하루 이창을 열지 않음</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end::layer-popup -->
</template>

<script>
import {MUT_CLOSE_COMMON_NOTICE_MODAL} from '../../store/_mut_consts';
import {ACT_GET_NOTICE} from '../../store/_act_consts';
import {getItems, lengthCheck} from '../../assets/js/utils';
import {swiperNoticePopOptions} from '../../assets/js/swiper.config';
import {mapGetters} from "vuex";

export default {
  name: 'NotificationModal',
  computed: {
    ...mapGetters('auth', ['isAuth', 'session', 'profile', 'creatorModal'])
  },
 data: () => ({
    swiperOptions: swiperNoticePopOptions(),
    notice: [],
    noticeShow : false
  }),
  created(){

    this.$store.dispatch(`notice/${ACT_GET_NOTICE}`).then(res=>{
        if(lengthCheck(res)  && !this.$cookies.get('gachi-notice-popup')){
          this.notice.push(...getItems(res));
        }
        if(this.notice.length > 1){
          this.noticeShow = true;
        }
      }).catch(e=>{
        console.error(e);
      })
  },
  methods: {
    clickClose(){
      this.$store.commit(`notice/${MUT_CLOSE_COMMON_NOTICE_MODAL}`);
    },
    clickCloseToday(){
      this.$cookies.set('gachi-notice-popup', 1, '1d');
    }
  }
};
</script>