import ApiService from '../../assets/js/api.service';
import {
	ACT_GET_VOD_CMNT,
	ACT_GET_VOD_CMNT_REPLY,
	ACT_INSERT_VOD_CMNT,
	ACT_UPDATE_VOD_CMNT,
	// ACT_DELETE_VOD_CMNT,
	ACT_UPSERT_VOD_CMNT_LK_DSK
} from '../_act_consts';
import {
	MUT_SET_AVATAR,
} from '../_mut_consts';

const state = {
	cmntNmTyp: {
		VOD_CMNT: "vodCmnt",
		MDFCN_CMNT: "mdfcnCmnt",
		REPLY_CMNT: "replyCmnt",
	},
	cmntSmthPssLimit: 400000,

	avatar: "",

	lkTmsCdVal: "100",
	dskTmsCdVal: "200",
}

const getters = {
	cmntNmTyp(state) {
		return state.cmntNmTyp;
	},
	cmntSmthPssLimit(state) {
		return state.cmntSmthPssLimit;
	},
	avatar(state) {
		return state.avatar;
	},
	lkTmsCdVal(state) {
		return state.lkTmsCdVal;
	},
	dskTmsCdVal(state) {
		return state.dskTmsCdVal;
	},
}

const mutations = {
	[MUT_SET_AVATAR](state, avatar) {
		state.avatar = avatar;
	},
}

const actions = {
	[ACT_GET_VOD_CMNT]({ }, params) {
		return new Promise(resolve => {
			ApiService.query('/v1/app/vod/vodCmnt', params).then(response => resolve(response));
		});
	},
	[ACT_GET_VOD_CMNT_REPLY]({ }, params) {
		return new Promise(resolve => {
			ApiService.query('/v1/app/vod/vodCmntReply', params).then(response => resolve(response));
		});
	},
	[ACT_INSERT_VOD_CMNT]({ }, params) {
		return new Promise(resolve => {
			ApiService.post('/v1/app/vod/vodCmnt', params).then(response => resolve(response));
		});
	},
	[ACT_UPDATE_VOD_CMNT]({ }, params) {
		return new Promise(resolve => {
			ApiService.put('/v1/app/vod/vodCmnt', params).then(response => resolve(response));
		});
	},
	// [ACT_DELETE_VOD_CMNT]({ }, params) {
	// 	return new Promise(resolve => {
	// 		ApiService.delete('/v1/app/vod/vodCmnt', params).then(response => resolve(response));
	// 	});
	// },
	[ACT_UPSERT_VOD_CMNT_LK_DSK]({ }, params) {
		return new Promise(resolve => {
			ApiService.put('/v1/app/vod/vodCmntLkDsk', params).then(response => resolve(response));
		});
	},
}


export const vod = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}