import ApiService from '../../assets/js/api.service';
import {
  ACT_GET_SENTCONT_LIST,
  ACT_GET_SENTCONT_NTFCTN_LIST,
  ACT_GET_SENTCONT_INFO,
} from '../_act_consts';
import {
  MUT_SET_SENT_CONT_CTGR_ITEMS,
} from '../_mut_consts';

const state = {
  ctgrItems: [],
}

const getters = {
  //ctgrItems: state => state.ctgrItems,
  ctgrItems(state){
    return state.ctgrItems;
  },
}

const mutations = {
  //setCtgrItems: (state, ctgrItems) => state.ctgrItems = ctgrItems,
  [MUT_SET_SENT_CONT_CTGR_ITEMS](state, ctgrItems){
    state.ctgrItems = ctgrItems;
  },
};

const actions = {
  [ACT_GET_SENTCONT_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/sentcont/list', params).then(response => resolve(response));
    });
  },
  [ACT_GET_SENTCONT_NTFCTN_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/sentcont/list/ntfctn', params).then(response => resolve(response));
    });
  },
  [ACT_GET_SENTCONT_INFO]({}, params){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/sentcont/${params.sentContId}/view`, params).then(response => resolve(response));
    });
  },
}

export const sentcont = {
  namespaced: true,
  state,
	getters,
	mutations,
  actions
}