import ApiService from '../../assets/js/api.service';
import {
  ACT_GET_ISSUE,
  ACT_GET_ISSUE_LIST,
  ACT_GET_PLAYGROUND,
  ACT_GET_PLAYGROUND_LIST,
  ACT_GET_INTERVIEW,
  ACT_GET_INTERVIEW_LIST,
} from '../_act_consts';

const actions = {
  [ACT_GET_PLAYGROUND_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/information/plygrnds', params).then(response => resolve(response));
    });
  },
  [ACT_GET_PLAYGROUND]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/information/plygrnd', params).then(response => resolve(response));
    });
  },
  [ACT_GET_ISSUE_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/information/issues', params).then(response => resolve(response));
    });
  },
  [ACT_GET_ISSUE]({}, issueId){
    return new Promise(resolve => {
      ApiService.get('/v1/app/information/issue', issueId).then(response => resolve(response));
    });
  },
  [ACT_GET_INTERVIEW_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/information/interviews', params).then(response => resolve(response));
    });
  },
  [ACT_GET_INTERVIEW]({}, interviewId){
    return new Promise(resolve => {
      ApiService.get('/v1/app/information/interview', interviewId).then(response => resolve(response));
    });
  },
}

export const information = {
  namespaced: true,
  actions
}
