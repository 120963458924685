<template>


  <table class="ls-calendar">
    <caption>달력 테이블</caption>
    <thead class="tb-header">
    <tr>
      <th scope="col" class="calendar-sun">일</th>
      <th scope="col">월</th>
      <th scope="col">화</th>
      <th scope="col">수</th>
      <th scope="col">목</th>
      <th scope="col">금</th>
      <th scope="col" class="calendar-sat">토</th>
    </tr>
    </thead>
    <tbody class="tb-body">
    <tr v-for="(week, idx) in calendar" :key="`week-${idx}`">
      <td v-for="(day, i) in week" class="calendar-date"
          :class="getDayClasses(i, day)"
          :key="`day-${i}`">

        <a href="javascript:" class="date" @click="selectFunc(day, checkDisabled(i, day))">
          <span class="num">{{ day.day }}</span>
<!--          <span v-if="isCurrent(day)" class="text">오늘</span>-->
          <span class="text">{{ getHolyDayText(day) }}</span>
        </a>
      </td>
    </tr>
    </tbody>
  </table>

</template>

<script>
import {getCalendar} from '../../assets/js/utils';
import {holyDay} from '../../assets/js/holy-day';

export default {
  name: 'Calendar',
  props:{
    selectYear: Number,
    selectMonth: Number,
    selectDay: Number,
    weekendYn: String,
    holyDayYn: String,
    selectFunc: Function
  },
  data: () => ({
    currentDay: 0,
    currentMonth : 0,
    currentYear: 0,
    currentYMD: ''
  }),
  computed: {
    calendar(){
      if(this.selectYear && this.selectMonth){
        return getCalendar(this.selectYear, this.selectMonth);
      }
      return [];
    }
  },
  created() {
    const current = new Date();
    this.currentDay = current.getDate();
    this.currentMonth = current.getMonth() + 1;
    this.currentYear = current.getFullYear();
    this.currentYMD = `${this.currentYear}${this.currentMonth < 10 ?  `0${this.currentMonth}`: this.currentMonth}${this.currentDay < 10 ? `0${this.currentDay}`: this.currentDay}`
  },
  methods: {
    isCurrent(dayObj){
      return this.currentDay === dayObj.day && this.currentMonth === dayObj.month && this.currentYear === dayObj.year;
    },
    getDayClasses(weekOfDay, day){
      const targetYmd = `${day.year}${day.month < 10 ? `0${day.month}`:day.month}${day.day < 10 ? `0${day.day}` : day.day}`;

      const classes = [];
      if(this.selectYear === day.year && this.selectMonth === day.month && this.selectDay === day.day && !this.checkDisabled(weekOfDay, day)) classes.push('calendar-date-selected');
      if(weekOfDay === 0) classes.push('calendar-sun');
      if(weekOfDay === 0) classes.push('calendar-sat');
      // if(this.weekendYn === 'Y' && this.holyDayYn === 'Y'){
      //   // 다른 달만 처리
      //   if(this.selectMonth !== day.month) classes.push('calendar-date-disabled');
      // }else if(this.weekendYn === 'Y' && this.holyDayYn === 'N'){
      //   if(this.selectMonth !== day.month || holyDay[targetYmd]) classes.push('calendar-date-disabled');
      // }else if(this.weekendYn === 'N' && this.holyDayYn === 'Y'){
      //   if(this.selectMonth !== day.month || (weekOfDay === 0 || weekOfDay === 6)) classes.push('calendar-date-disabled');
      // }else{
      //   if(this.selectMonth !== day.month || (weekOfDay === 0 || weekOfDay === 6) || holyDay[targetYmd]) classes.push('calendar-date-disabled');
      // }
      if(this.checkDisabled(weekOfDay, day)) classes.push('calendar-date-disabled');
      return classes;
    },
    getHolyDayText(day){
      const targetYmd = `${day.year}${day.month < 10 ? `0${day.month}`:day.month}${day.day < 10 ? `0${day.day}` : day.day}`;
      let text = holyDay[targetYmd] ? holyDay[targetYmd] : '';
      if(text === '' && this.currentDay === day.day && this.currentMonth === day.month && this.currentYear === day.year){
        text = '오늘';
      }
      return text;
    },
    checkDisabled(weekOfDay, day){
      const targetYmd = `${day.year}${day.month < 10 ? `0${day.month}`:day.month}${day.day < 10 ? `0${day.day}` : day.day}`;
      if( targetYmd <= this.currentYMD ) return true;
      else if(this.weekendYn === 'Y' && this.holyDayYn === 'Y'){
        if(this.selectMonth !== day.month) return true;
      }else if(this.weekendYn === 'Y' && this.holyDayYn === 'N'){
        if(this.selectMonth !== day.month || holyDay[targetYmd]) return true;
      }else if(this.weekendYn === 'N' && this.holyDayYn === 'Y'){
        if(this.selectMonth !== day.month || (weekOfDay === 0 || weekOfDay === 6)) return true;
      }else{
        if(this.selectMonth !== day.month || (weekOfDay === 0 || weekOfDay === 6) || holyDay[targetYmd]) return true;
      }
      return false;
    }
  }


};
</script>
