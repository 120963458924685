<template>
	<div class="gachi-search">
		<search :search-data="result" />
	</div>
</template>

<script>
import Search from './search/Search';

export default {
	name: 'SearchModal',
	components: {
		Search,
	},
	methods: {
		result() {},
	},
};
</script>
