<template>
  <div class="layer-popup-container gachi-layer-popup-container">
    <div class="layer-popup" id="popup-community-write">
      <div>
        <div class="popup-wrapper">
          <header class="popup-header">
            <h3 class="header-title">글쓰기</h3>
            <button type="button" class="btn-close" @click="closeCommunity">
              <i class="icon-close">팝업닫기</i>
            </button>
          </header>
          <div class="popup-body">
            <div class="write-cont">
              <p class="mb-2 write-prh">소상공인의 든든한 디지털 닥터</p>
              <div class="write-category">
                <select v-model="params.comunCtgrDcd" class="form-select">
                  <option v-for="(option, idx) in comunCtgrDcdOptions" :value="option.dcd" :key="idx">{{option.dcdVal}}</option>
                </select>
              </div>
            </div>
            <div class="form-row mb-2">
              <input v-model="params.comunBltartclTit" type="text" class="form-control" placeholder="게시글 제목을 입력해주세요." required />
            </div>
<!--            <div class="write-editor">-->
              <ckeditor v-model="params.comunBltartclCn" :config="editorConfig" @namespaceloaded="onNamespaceLoaded" @ready="onReady" />
<!--            </div>-->
          </div>
          <div class="popup-buttons">
            <button class="btn btn-light btn-lg" @click="closeCommunity">
              <span class="text">취소</span>
            </button>
            <loading-button
                :button-text="action"
                :is-loading="isLoading"
                :click-func="clickSave"
                :button-classes="['btn-lg', 'btn-primary']"
                :style-obj="{'margin-right': '5px'}"
                :text-classes="[]"
                loading-size="sm"
            ></loading-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {mapGetters} from 'vuex';
import {gachiConfig} from '../../assets/js/ckeditor4/ckeditor-config';
import embed from '../../assets/js/ckeditor4/embed';
import cloudservice from '../../assets/js/ckeditor4/cloudservice-full';
import easyimage from '../../assets/js/ckeditor4/easyimage-full';
import imagebase from '../../assets/js/ckeditor4/imagebase';
import cateEmbed from '../../assets/js/ckeditor4/cate-embed';
import nasEmbed from '../../assets/js/ckeditor4/nas-embed';
import {slideImage, slideImageInit} from '../../assets/js/ckeditor4/ckeditor-slide-image';
import LoadingButton from '../common/LoadingButton';
import {
  ACT_GET_COMMON_CODE_LIST,
  ACT_GET_SB_COMMUNITY,
  ACT_GET_SHOP_MALL_COMMON_CODE_LIST,
  ACT_INSERT_SB_COMMUNITY,
  ACT_UPDATE_SB_COMMUNITY,
} from '../../store/_act_consts';
import {getCheckItems, getItem, isSuccess, lengthCheck, validate} from '../../assets/js/utils';
import {
  MUT_CLOSE_COMMUNITY,
  MUT_SET_COMMON_CODE_ITEMS,
  MUT_SHOW_ALERT,
  MUT_SHOW_CONFIRM,
} from '../../store/_mut_consts';

export default {
  name: 'CommunityEdit',
  components:{
    LoadingButton
  },
  props: {},
  data: () => ({
    comunBltartclId: 0,
    params: {
      comunCtgrDcd: '1007001',
      comunBltartclTit: '',
      comunBltartclCn: '',
      thmbnlImgPtUrl: ''
    },
    imgSrcRex: /<img.*?src="(.*?)"[^>]+>/g,
    // categorySubText: [
    //   {dcd: '1007001',text: '더이상 쓰지 않는 내 물건, 다른 소상공인분들께 판매하세요.'},
    //   {dcd: '1007002',text: '필요하신 물건이 있다구요? 글을 남겨보세요.'},
    //   {dcd: '1007003',text: '무엇이든 물어보세요. 누구든지 답합니다.'},
    //   {dcd: '1007004',text: '우리 함께 만들어가요.'},
    // ],
    subText: '',
    instances: null,
    ckeditor: null,
    editorConfig: gachiConfig,
    isLoading: false,
    isRmLoading: false
  }),
  computed: {
    ...mapGetters('community', ['communityEdit']),
    ...mapGetters('common', ['isMobile', 'commonCode']),
    comunCtgrDcdOptions(){
      return this.commonCode.filter(x => (x.dbColumn === 'COMUN_CTGR_DCD' && x.dcd !== '1007000'));
    },
    action(){
      return this.comunBltartclId > 0 ? '수정' : '등록';
    },
    subTexts(){
      const result =  this.categorySubText.filter(x => x.dcd === this.params.comunCtgrDcd);
      return result[0].text;
    }
  },
  created() {
    if(this.commonCode.length === 0) this.getCommonCdList();
    if(this.$route.params.comunBltartclId) this.comunBltartclId = this.$route.params.comunBltartclId;
  },
  methods: {
    async onReady(instances){
      this.instances = instances;
      if(this.comunBltartclId > 0) {
        this.getSbCommunity(instances.document.$);
      }
      // if(this.ckeditor === null) this.ckeditor = CKEDITOR;
      // slideImageInit(CKEDITOR.document.$);
    },
    onNamespaceLoaded( CKEDITOR ) {
      this.ckeditor = CKEDITOR;
      embed(CKEDITOR);
      cloudservice(CKEDITOR);
      easyimage(CKEDITOR);
      slideImage(CKEDITOR);
      cateEmbed(CKEDITOR);
      imagebase(CKEDITOR);
      nasEmbed(CKEDITOR);
    },
    getCommonCdList(){
      this.$store.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, {}).then(res => {
        this.$store.commit(`common/${MUT_SET_COMMON_CODE_ITEMS}`, getCheckItems(res));
      });
    },
    getSbCommunity($document){
      this.$store.dispatch(`community/${ACT_GET_SB_COMMUNITY}`, {
        comunBltartclId: this.comunBltartclId,
        params: {
          comunCtgrDcd: this.$route.query.ctgrCode
        }
      }).then(res => {
        if(lengthCheck(res)){
          const item = getItem(res);
          Object.keys(this.params).forEach(key => {
            if(item[key] !== null && item[key] !== undefined){
              this.params[key] = item[key];
            }
          });
          setTimeout(() => {
            slideImageInit($document);
          }, 500)

        }else{
          this.comunBltartclId = 0;
        }
      }).catch(e => {
        console.error(e);
        this.comunBltartclId = 0;
      });
    },
    async clickSave(){
      if(this.isLoading) return;
      this.isLoading = true;
      if(
          validate(this.$store, this.params, [
            {key: 'comunCtgrDcd', msg: '카테고리를 선택해주세요.'},
            {key: 'comunBltartclTit', msg: '커뮤니티 게시글 제목을 입력해주세요.'},
          ])
      ){
        await this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
          title: `커뮤니티 게시글을 ${this.action}하시겠습니까?`,
          showCancelButton: true,
          yesfunc: this.saveSbCommunity,
          nofunc: () => {this.isLoading = false;},
        });
      }else{
        this.isLoading = false;
      }
    },
    async saveSbCommunity(){
      try {
        this.minifyCn();
        const res = this.comunBltartclId > 0 ?
            await this.$store.dispatch(`community/${ACT_UPDATE_SB_COMMUNITY}`, {comunBltartclId: this.comunBltartclId, params: this.params}) :
            await this.$store.dispatch(`community/${ACT_INSERT_SB_COMMUNITY}`, this.params);

        if(isSuccess(res)){
          await this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
            title: `커뮤니티 게시글이 ${this.action}되었습니다.`,
            yesfunc: () => {
              this.closeCommunity();
              this.$router.go();
            },
            rightNow: true
          });
        }else{
          await this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '오류가 발생했습니다. 다시 시도해주세요.'});
        }
        this.isLoading = false;
      } catch (e) {
        console.error(e);
        await this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '오류가 발생했습니다. 다시 시도해주세요.'});
        this.isLoading = false;
      }
    },
    minifyCn(){
      if(this.instances != null){
        const editorContents = this.instances.getData();
        if(editorContents !== null && editorContents !== undefined){
          const m = this.imgSrcRex.exec( editorContents );
          if(m) this.params.thmbnlImgPtUrl = m[1];
          this.params.comunBltartclCn = editorContents.replace(/^\s+|\r\n|\n|\r|(>)\s+(<)|\s+$/gm, '$1$2');
        }
      }
    },
    closeCommunity(){
      this.$store.commit(`community/${MUT_CLOSE_COMMUNITY}`);
    }
  }
}
</script>
