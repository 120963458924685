import store from '../../../store';
import {MUT_SHOW_ALERT} from '../../../store/_mut_consts';

const businessmanRegistrationNumRule = /^\d{3}-\d{2}-\d{5}$/;

export const checkBusinessmanRegistrationNum = (bzmnRegNo) => {
  return businessmanRegistrationNumRule.test(bzmnRegNo);
}

const businessmanRegistrationNumRule1 = /^\d{10}$/;

export const checkBusinessmanRegistrationNum1 = (bzmnRegNo) => {
  return businessmanRegistrationNumRule1.test(bzmnRegNo);
}

const businessmanCorporationNumRule = /^\d{6}-\d{7}$/;

export const checkBusinessmanCorporationNum = (bzmnCrno) => {
  return businessmanCorporationNumRule.test(bzmnCrno);
}

const emailRule = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const checkEmail = (email) => {
  return emailRule.test(email);
};

// const regRule = /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]{8,13}$/
const regRule = /^(?=.*[a-zA-Z])((?=.*\d)(?=.*\W)).{9,20}$/
export const checkPassword = (passwd) => {
  // 숫자 + 문자 9~20자리
  return regRule.test(passwd);
}

// const idRule = /^[a-z][a-z\d]{4,11}$/;
const idRule = /^[a-z0-9_-]{5,20}$/;
export const checkId = (id) => {
  // 5~20자의 영문 소문자, 숫자와 특수기호(_),(-)만 사용 가능합니다.
  return idRule.test(id);
}

export const validateAlert = (validateKeys, target, title) => {
  let msgs = [];
  if(validateKeys instanceof Object){
    msgs = validateObject(validateKeys, target);
  }
  const result = (msgs.length > 0)
  if(result) msgAlert(title, msgs);
  return result;
}

const validateObject = (validateKeys, target) => {
  const keys = Object.keys(validateKeys);
  const msgs = [];

  for(let i = 0; i < keys.length; i++){
    if(!target[keys[i]]){
      msgs.push(validateKeys[keys[i]]);
    }
  }
  return msgs;
}

export const msgAlert = (title, msgs, width) => {
  store.commit(`common/${MUT_SHOW_ALERT}`, {
    title:  `${title ? title : '입력정보를 확인하세요.'}`,
    subText: msgs.join(',<br>'),
    html: true,
    bodyClass: width ? `gachi-body-width-${width}` : 'gachi-popup-body'
  });
}

// const validateArray = (validateKeys, target) => {
//   const msg = [];
//   for(let i = 0; i < validateKeys.length; i++){
//     if(validateKeys[i].type === 'file'){
//
//     }else if(validateKeys[i].type === 'number'){
//
//     }else{
//       if(!target[validateKeys[i].key] || !target[validateKeys[i].key].trim()){
//         msgs.push(validateKeys[keys[i]]);
//       }
//     }
//   }
//   return msgs;
// }