import ApiService from '../../assets/js/api.service';
import {
  ACT_GET_SURVEY_LIST,
  ACT_GET_SURVEY_QUESTION_LIST, ACT_INSERT_SURVEY_QUESTION_ANSWER,
} from '../_act_consts';
import {MUT_CLOSE_SURVEY, MUT_SHOW_SURVEY} from '../_mut_consts';

const state = {
  survey: {
    // show: false,
    show: false,
    // surveyId: 0,
    surveyId: 0,
    surveyNm: '',
    submitFunc: null
  }
}

const getters =  {
  survey (state) {
    return state.survey
  },
}

const mutations = {
  [MUT_SHOW_SURVEY] (state, {surveyNm, surveyId, submitFunc}) {
    state.survey.surveyId = surveyId;
    state.survey.surveyNm = surveyNm;
    state.survey.submitFunc = submitFunc;
    state.survey.show = true;
  },
  [MUT_CLOSE_SURVEY] (state) {
    state.survey.show = false;
    state.survey.surveyId = 0;
  },
}

const actions = {
  [ACT_GET_SURVEY_LIST]({}){
    return new Promise(resolve => {
      ApiService.query('/v1/app/surveys', {}).then(response => resolve(response));
    });
  },
  [ACT_GET_SURVEY_QUESTION_LIST]({}, surveyId){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/survey/${surveyId}/quests`, {}).then(response => resolve(response));
    });
  },
  [ACT_INSERT_SURVEY_QUESTION_ANSWER]({}, params){
    return new Promise(resolve => {
      ApiService.post(`/v1/app/survey/quest/anses`, params).then(response => resolve(response));
    });
  },
}

export const survey = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
