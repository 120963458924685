export default function(CKEDITOR) {
  CKEDITOR.addCss(`iframe.gachi-cate-embed{width: 100%;}`);

  CKEDITOR.plugins.add( 'gachi-cate-embed', {
    icons: 'GachiCateEmbed',
    init: function( editor ) {
      editor.addCommand( 'cateInsertEmbed', new CKEDITOR.dialogCommand( 'cateInsertEmbed'));
      CKEDITOR.dialog.add( 'cateInsertEmbed', function( editor ) {
        return {
          title: 'Kollus 링크 입력',
          minWidth: 300,
          minHeight: 50,
          onOk: function() {
            const link = CKEDITOR.tools.trim( this.getValueOf( 'info', 'cateLink' ) );
            if (link) {
              const sliders = CKEDITOR.dom.element.createFromHtml( `<iframe class="gachi-cate-embed" height="400" width="100%" src="${link}"  allowfullscreen webkitallowfullscreen mozallowfullscreen></iframe>`);
              editor.insertElement(sliders);
              editor.insertElement(CKEDITOR.dom.element.createFromHtml('<p></p>'));
            }
          },
          onShow: function() {
            var linkTextField = this.getContentElement( 'info', 'cateLink' );
            linkTextField.focus();
          },
          contents: [{
            id: 'info',
            accessKey: 'Y',
            elements: [{
              type: 'text',
              id: 'cateLink',
              label: 'Kollus 영상 링크'
            }]
          }]
        };
      });

      editor.ui.addButton( 'GachiCateEmbed', {
        label: 'Kollus 영상',
        command: 'cateInsertEmbed',
        icon: `${process.env.VUE_APP_OBJECT_STORAGE}/common/img/video-icon.png`
      });
    }
  });
}

