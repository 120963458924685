import ApiService from '@/assets/js/api.service';

import {
    ACT_GET_PRVC_PRCS_POLIC_LIST,
    ACT_GET_PRVC_PRCS_POLIC_LATEST,
    // ACT_DOWN_ATTACH_PRVC_PRCS_POLIC,
} from '@/store/_act_consts';

const state = {
    SEARCH_OPT: {
        0: { code: "title", name: "전체" },
        1: { code: "title", name: "제목" },
        // 1: { code: "content", name: "내용" },
        // 2: { code: "altogether", name: "제목+내용" },
    },
    PRVC_DCD: "1025001",    // portal
    PAGING: {
        hasMore: false,
        totalCount: 0,
        pageSize: 10,
        pageNo: 1,
    },
    DIV_TABLE: "div_table",
}

const getters = {
    SEARCH_OPT(state) {
        return state.SEARCH_OPT
    },
    PRVC_DCD(state) {
        return state.PRVC_DCD
    },
    PAGING(state) {
        return state.PAGING
    },
    DIV_TABLE(state) {
        return state.DIV_TABLE
    }
}

const mutations = {}

const actions = {
    [ACT_GET_PRVC_PRCS_POLIC_LIST]({ }, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/privacy/prvcPrcsPolic', params).then(response => resolve(response));
        });
    },
    [ACT_GET_PRVC_PRCS_POLIC_LATEST]({ }, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/privacy/prvcPrcsPolicLatest', params).then(response => resolve(response));
        });
    },
    // [ACT_DOWN_ATTACH_PRVC_PRCS_POLIC]({ }, prvcPrcsPolicId) {
    //     let url = '/v1/app/privacy/prvcPrcsPolicAtch'
    //     const params = { prvcPrcsPolicId }
    //     if (!!params) {
    //         const keys = Object.keys(params)
    //         url += ('?' + keys.map(key => `${key}=${params[key]}`).join('&'))
    //     }

    //     return new Promise((resolve, reject) => {
    //         axios({
    //             url: url,
    //             method: 'GET',
    //             responseType: 'blob',
    //         })
    //             .then(
    //                 res => { resolve(res) },
    //                 error => { reject(error) }
    //             )
    //     })
    // }
}

export const privacy = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}