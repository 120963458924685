<template>
  <!-- begin::layer-popup -->
  <div class="layer-popup-container">
    <div class="layer-popup" id="popup-notice">
      <div>
        <div class="popup-wrapper">
          <button type="button" class="btn-close" @click="clickClose">
            <i class="icon-close">팝업닫기</i>
          </button>
          <div class="popup-body">
            <div id="popup-notice-image">
              <swiper-slide v-for="(item, idx) in supportNotice" :key="idx">
                <a href="/file/2023 소상공인 온라인판로 지원사업 안내.pdf" style="display:block;" download><img src="/img/online_support_2023.jpg" :alt="item.ntcTit"></a>
              </swiper-slide>
            </div>
          </div>
          <div class="popup-footer">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="chk2"  @click="clickCloseDay"/>
              <label for="chk2" class="form-check-label">오늘 하루 이창을 열지 않음</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end::layer-popup -->
</template>

<script>
import {MUT_CLOSE_SUPPORT_NOTICE_MODAL} from '../../store/_mut_consts';
import {swiperNoticePopOptions} from '../../assets/js/swiper.config';
import {mapGetters} from "vuex";

export default {
  name: 'supportNoticeModal',
  computed: {
    ...mapGetters('auth', ['isAuth', 'session', 'profile', 'creatorModal'])
  },
 data: () => ({
    swiperOptions: swiperNoticePopOptions(),
    supportNotice: [
      {
        "ntcId":29
        , "ntcTit":"2023 소상공인 온라인판로 지원사업 안내"
        , "ntcCn":""
        //, "ntcUrl": window.location.protocol + "//" + window.location.host + "/mypage/profile"
        , "ntcUrl": window.location.protocol + "//" + window.location.host + "/mypage2/home"
        , "ntcImgFilePtUrl":"@/assets/img/popup/online_support_2023.jpg"
        , "ntcImgFileNm":"2023_소상공인_온라인판로_지원사업_안내.jpg"
        , "pstgBgngYmd":"20230101"
        , "pstgEndYmd":"20990131"
        , "expsrYn":null
        , "useYn":null
        , "rgtrId":0
        , "regDt":null
        , "mdfrId":0
        , "mdfcnDt":null
      }
    ],
    supportNoticeShow : true
  }),
  methods: {
    clickClose(){
      this.$store.commit(`notice/${MUT_CLOSE_SUPPORT_NOTICE_MODAL}`);
    },
    clickCloseDay(){
      this.$cookies.set('support-notice-popup', 1, '1d');
    }
  }
};
</script>