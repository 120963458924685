<template>
	<!-- begin::gachi-header -->
	<header class="gachi-header" :data-header="dataHeader" :class="{ 'is-active': isHeaderOver }" @mouseover.prevent="headerOver(true)" @mouseout.prevent="headerOver(false)">
		<!--<div class="header-banner is-visible">-->
		<div v-if="!isConnect" class="header-banner" :class="{ 'is-visible': msgCount > 0 }">
			<div class="header-component">
				<!--          <a href="" class="banner-link btn-icontext" title="확인하러 가기">-->
				<router-link to="/mypage/connect" class="banner-link btn-icontext" title="확인하러 가기">
					<i class="icon-banner-noti"></i>
					<span class="banner-text">{{ msgCount }}건의 알람이 있어요 !</span>
				</router-link>
				<!--          </a>-->
				<button type="button" class="btn-icon" title="팝업 닫기" @click="closeTopPopup">
					<i class="icon-banner-close"></i>
				</button>
			</div>
		</div>
		<div class="header-gnb">
			<div class="header-component">
				<div class="header-logo">
					<router-link to="/" class="logo-link">
						<span class="logo-gachi">가치삽시다</span>
					</router-link>
					<!-- <ul v-if="isLogoLinkShow" class="header-logo-link">
						<li>
							<router-link to="/" class="header-logo-link__item is-active"> 포털 </router-link>
						</li>
						<li>
							<a :href="marketUrl" target="_blank" class="header-logo-link__item"> 마켓 </a>
						</li>
					</ul> -->
				</div>
				<div class="header-menu" id="gachi-header">
					<ul class="header-menu-list">
						<li v-for="(menu, idx) in menus" class="header-menu-item" :key="idx" :class="{ 'is-active': menu.isActive }" @mouseover.prevent="menuVisible(idx, $event)" @mouseleave.prevent="menuVisible(idx, $event)">
							<router-link v-if="menu.path" :to="menu.path" class="header-menu-link" target="">
								<span class="header-menu-text" v-html="menu.title"></span>
							</router-link>
							<a v-else href="javascript:" class="header-menu-link">
								<span class="header-menu-text" v-html="menu.title"></span>
							</a>
							<template v-if="menu.children && menu.children.length > 0">
								<div v-if="menu.isSubActive" class="header-sub">
									<ul class="header-sub-list header-sub-community" @mouseleave.prevent="menuHidden(idx)">
										<li v-for="(child, i) in menu.children" class="header-sub-item" :key="`sub-${idx}-${i}`">
											<a v-if="child.isTargetBlank" :href="child.path" class="header-sub-link" target="_blank">
												<div class="header-sub-cont">
													<span class="header-sub-text">{{ child.title }}</span>
													<span v-if="child.isTargetBlank" class="header-sub-arrow">
														<img src="~@/assets/img/common/link-arrow.svg" />
													</span>
												</div>
											</a>
											<router-link v-else :to="child.path" class="header-sub-link" @mouseup.native="clickSameRoute(child.path)">
												<div class="header-sub-cont">
													<span class="header-sub-text">{{ child.title }}</span>
													<span v-if="child.isTargetBlank" class="header-sub-arrow">
														<img src="~@/assets/img/common/link-arrow.svg" />
													</span>
												</div>
											</router-link>
										</li>
									</ul>
								</div>
							</template>
						</li>
						<!--              <li class="header-menu-item">-->
						<!--                <a href="javascript:" class="header-menu-link">-->
						<!--                  <span class="header-menu-text">가치삽시다 마켓</span>-->
						<!--                </a>-->
						<!--              </li>-->
					</ul>
				</div>
				<div class="header-util">
					<ul class="header-util-list">
						<!-- <a href="javascript:" class="header-util-link" @click="clickMoveFAQ">
							<i class="icon-util-faq" title="FAQ">common/faq_w.svg</i>
						</a> -->
						<li class="header-util-item">
							<router-link to="/service/onfan" class="header-util-link">
								<i class="icon icon-header-onfan"><icon name="icon-header-onfan" /></i>
								<span class="header-util-text">플랫폼소개</span>
							</router-link>
						</li>
						<li class="header-util-item">
							<a href="https://www.k-shoppingfesta.org" target="_blank" class="header-util-link">
								<i class="icon icon-header-dhfesta"><icon name="logo-dhfesta" /></i>
								<span class="header-util-text">동행축제</span>
							</a>
						</li>
						<li class="header-util-item">
							<a @click="clickMoveFANFANAgent" class="header-util-link">
								<i class="icon icon-header-fanfan"><icon name="icon-header-fanfan" /></i>
								<span class="header-util-text">판판대로</span>
							</a>
						</li>
						<li class="header-util-item">
							<router-link to="/search" class="header-util-link">
								<i class="icon icon-header-search"><icon name="icon-header-search" /></i>
								<span class="header-util-text">검색</span>
							</router-link>
						</li>

						<!-- <li class="header-util-item">
							<router-link to="/notice" class="header-util-link">
								<i class="icon icon-header-news"><icon name="icon-header-notice" /></i>
								<span class="header-util-text">공지사항</span>
							</router-link>
						</li> -->

						<router-link v-if="isAuth" custom to="/mypage2/home" v-slot="{ href, navigate, isActive, isExactActive }">
							<li class="header-util-item">
								<a :href="href" class="header-util-link" @click="navigate">
									<i class="icon icon-header-mypage"><icon name="icon-header-my" /></i>
									<span class="header-util-text">마이페이지</span>
								</a>
							</li>
						</router-link>

						<li v-if="isAuth" class="header-util-item">
							<a href="javascript:" class="header-util-link" @click="logout">
								<i class="icon icon-header-logout"><icon name="icon-header-logout" /></i>
								<span class="header-util-text">로그아웃</span>
							</a>
						</li>
						<!-- <li v-if="!isAuth" class="header-util-item">
							<a @click="certification" class="header-util-link">
								<i class="icon icon-header-join"><icon name="icon-header-join" /></i>
								<span class="header-util-text">회원가입</span>
							</a>
						</li> -->
						<router-link v-if="!isAuth" custom to="/login" v-slot="{ href, navigate, isActive, isExactActive }">
							<li class="header-util-item">
								<a :href="href" class="header-util-link" @click="navigate">
									<i class="icon icon-header-login"><icon name="icon-header-login" /></i>
									<span class="header-util-text">로그인</span>
									<!-- <span class="gachi-auth">로그인</span> -->
								</a>
							</li>
						</router-link>
						<li class="header-util-item header-util-aside" data-toggle="aside-open">
							<button type="button" class="header-util-link" @click="asideOpen">
								<i class="icon icon-util-menu"><icon name="icon-menu" /></i>
							</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="header-sub-overlay" :class="{ 'is-active': isSubHeaderOver }" @mouseleave.prevent="menuLeave"></div>
		<floating-buttons :fullpage="fullpage" />
	</header>
</template>

<script>
import { mapGetters } from 'vuex';
import { MUT_REMOVE_MESSAGE_COUNT, MUT_SET_RETURN_ROUTE, MUT_SHOW_CONFIRM } from '../../../store/_mut_consts';
import { ACT_REMOVE_AUTH_TOKEN } from '../../../store/_act_consts';
import menus from '../../../assets/js/menus';
import Icon from '@/components/common/Icon';
import FloatingButtons from '@/components/common/FloatingButtons';

export default {
	name: 'MainHeader',
	components: { Icon, FloatingButtons },
	props: {
		fullpage: {
			type: Boolean,
			default: false,
		},
		openAsideMenu: Function, // 모바일 aside 메뉴 open
	},
	computed: {
		...mapGetters('auth', ['isAuth', 'session']),
		...mapGetters('common', ['msgCount', 'headerSticky', 'isMobile']),
		dataHeader() {
			if (this.stickyNames.includes(this.$route.name) || this.headerSticky) {
				// [dev] 20230615 data-header="sticky" 를 사용하지 않으므로 주석 처리함.
				// return 'sticky';
				return '';
			} else if (this.darkNames.includes(this.$route.name)) {
				return 'dark';
			}
			return '';
		},
		isConnect() {
			return this.$route.path === '/mypage/connect';
		},
		isActiveMenu: {
			get: function () {
				for (let i = 0; i < this.menus.length; i++) {
					if (this.menus[i].isActive && this.menus[i].children && this.menus[i].children.length > 0) {
						//if (this.menus[i].isActive) {
						return true;
					}
				}
				return false;
			},
			set: function (newVal) {
				return newVal;
			},
		},
		isMenuActive: {
			get: function () {
				for (let i = 0; i < this.menus.length; i++) {
					if (this.menus[i].children && this.menus[i].children.length > 0) {
						for (let j = 0; j < this.menus[i].children.length; j++) {
							const path = this.menus[i].children[j].path;
							if (path.includes(this.$route.path)) {
								return true;
							}
						}
					} else {
						const path = this.menus[i].path;
						if (path.includes(this.$route.path)) {
							return true;
						}
					}
				}
				return false;
			},
		},
	},
	data: () => ({
		stickyNames: ['MyPage', 'Playground', 'PlaygroundView', 'Issue', 'IssueView', 'Issue2', 'Campaign', 'CampaignView', 'RoomView', 'Support', 'Support1', 'Support2', 'SupportView', 'Playground2', 'Playground2View', 'Playground3', 'Playground3View', 'Policy', 'Privacy', 'Notice', 'NoticeView', 'Sodam', 'Self', 'Guide', 'Interview', 'InterviewView', 'Event1', 'Event2', 'BroadCast', 'VodView', 'ScommunityMain', 'ScommunityMainSearch', 'ScommunityDetail', 'ScommunityDetailSearch', 'ScommunityBbsList', 'ScommunityBbsDetail', 'ScommunityBbsDataList', 'ScommunityBbsDataDetail', 'ScommunityHome', 'MyPage2'],
		darkNames: ['Studio'],
		menus: menus,
		mainPath: false,
		marketUrl: `${process.env.VUE_APP_GACHI_MALL_HOST}`,
		isHeaderOver: false,
		isSubHeaderOver: false,
		isLogoLinkShow: false,
		fanfan_url: `${process.env.VUE_APP_FANFAN_SSO_AGENT_HOST}` + '/RequestConnect.jsp?RelayState=https://fanfandaero.kr/portal/main.do',
		gachiAgentUrl: `${process.env.VUE_APP_GACHI_SSO_AGENT_HOST}`,
	}),
	created() {
		this.activeMenus();

		// const tagetDate = new Date('2024-01-01 00:00:00');
		// //const tagetDate = new Date('2023-12-21');
		// //console.log('tagetDate : ', tagetDate);
		// const nowDate = new Date();
		// //console.log('nowDate : ', nowDate);
		// if(nowDate < tagetDate){	//몰 링크를 2023-12-31 까지만 노출
		// 	this.isLogoLinkShow = true;
		// }
	},
	watch: {
		$route: function () {
			this.activeMenus();
		},
	},
	methods: {
		logout() {
			this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
				title: `로그아웃을 하시겠습니까?`,
				showCancelButton: true,
				yesfunc: () => {
					if (this.session.userPattnCd === '400') {
						this.$store.dispatch(`auth/${ACT_REMOVE_AUTH_TOKEN}`);
						window.location = this.gachiAgentUrl + '/Logout.jsp';
					} else {
						this.$store.dispatch(`auth/${ACT_REMOVE_AUTH_TOKEN}`);
						if (this.$route.name === 'MyPage' || this.$route.name === 'MyPage2') {
							//마이페이지(신규) 에서도 로그아웃 시 로그인 화면으로 이동 추가
							this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
							this.$router.push({ name: 'Login' });
						} else {
							//console.log(this.$route.fullPath);
							this.$router.go(this.$router.currentRoute);
							//this.$router.go(0);
						}
					}
				},
			});
		},
		certification() {
			if (!window.location.hostname.includes('portal') && !window.location.hostname.includes('localhost')) {
				window.location = 'https://portal.valuebuy.kr/join';
			} else {
				this.$router.push({ name: 'Join' });
			}
		},
		closeTopPopup() {
			this.$store.commit(`common/${MUT_REMOVE_MESSAGE_COUNT}`);
		},
		// showSearch() {
		// 	this.$store.commit(`common/${MUT_SHOW_SEARCH_MODAL}`);
		// },
		menuVisible(idx, e) {
			// console.log(e.relatedTarget)
			// console.log(e.target)
			// console.log('-------------')
			// if(e.relatedTarget){
			//   return;
			// }
			// console.log(e.type);

			// 단일 active
			// for (let i = 0; i < this.menus.length; i++) {
			// 	this.menus[i].isActive = false;
			// 	this.isSubHeaderOver = false;
			// }

			let routePath = this.$route.path;
			// playground 와 playground2 가 있어 playground에 ? 추가
			if (routePath == '/playground') {
				routePath = routePath + '?';
			}

			let routeActive = false;
			if (routePath != '/') {
				for (let i = 0; i < this.menus.length; i++) {
					if (this.menus[i].children && this.menus[i].children.length > 0) {
						let childPathActive = false;
						for (let j = 0; j < this.menus[i].children.length; j++) {
							const path = this.menus[i].children[j].path;
							if (path.includes(routePath)) {
								childPathActive = true;
							}
						}
						if (childPathActive) {
							this.menus[i].isActive = true;
						} else {
							this.menus[i].isActive = false;
						}
					} else {
						const path = this.menus[i].path;
						if (path.includes(routePath)) {
							this.menus[i].isActive = true;
						} else {
							this.menus[i].isActive = false;
						}
					}

					if (i == idx) {
						routeActive = true;
					}
				}
			}

			if (e.type == 'mouseleave') {
				if (!routeActive) {
					this.menus[idx].isActive = false;
				}

				if (this.menus[idx].children && this.menus[idx].children.length > 0) {
					this.menus[idx].isSubActive = false;
					this.isSubHeaderOver = false;
				}
			} else {
				this.menus[idx].isActive = true;
				if (this.menus[idx].children && this.menus[idx].children.length > 0) {
					this.menus[idx].isSubActive = true;
					this.isSubHeaderOver = true;
				}
			}
		},
		menuHidden(idx) {
			//this.isSubHeaderOver = false;
			// // for (let i = 0; i < this.menus.length; i++) {
			// // 	this.menus[i].isActive = false;
			// // }
			// let loutePath = this.$route.path;
			// // playground 와 playground2 가 있어 playground에 ? 추가
			// if(loutePath == '/playground'){
			// 	loutePath = loutePath + '?'
			// }
			// if(this.menus[idx].children && this.menus[idx].children.length > 0){
			// 	for (let j = 0; j < this.menus[idx].children.length; j++) {
			// 		const path = this.menus[idx].children[j].path;
			// 		if(path.includes(loutePath)){
			// 			this.menus[idx].isActive = true;
			// 			return;
			// 		}
			// 	}
			// } else {
			// 	const path = this.menus[idx].path;
			// 	if(path.includes(loutePath)){
			// 		this.menus[idx].isActive = true;
			// 		return;
			// 	}
			// }
			// this.menus[idx].isActive = false;
		},
		headerOver(bool) {
			this.isHeaderOver = bool;
			if (bool === false) {
				this.isActiveMenu = false;
			}
		},
		menuLeave(idx) {
			// let loutePath = this.$route.path;
			// // playground 와 playground2 가 있어 playground에 ? 추가
			// if(loutePath == '/playground'){
			// 	loutePath = loutePath + '?'
			// }
			// for (let i = 0; i < this.menus.length; i++) {
			// 	// if(this.menus[i].children && this.menus[i].children.length > 0){
			// 	// 	for (let j = 0; j < this.menus[i].children.length; j++) {
			// 	// 		const path = this.menus[i].children[j].path;
			// 	// 		if(path.includes(loutePath)){
			// 	// 			this.menus[i].isActive = true;
			// 	// 			return;
			// 	// 		}
			// 	// 	}
			// 	// } else {
			// 	// 	const path = this.menus[i].path;
			// 	// 	if(path.includes(loutePath)){
			// 	// 		this.menus[i].isActive = true;
			// 	// 		return;
			// 	// 	}
			// 	// }
			// 	this.menus[i].isActive = false;
			// 	this.isSubHeaderOver = false;
			// }
		},
		blankFunc(e) {
			console.log(e);
		},
		clickMoveFAQ() {
			window.open(`${process.env.VUE_APP_GACHI_MALL_HOST}/mn/cm/mncm005?faqTypCd=900`);
		},
		clickSameRoute(path) {
			if (path.indexOf('?') > -1) {
				path = path.substring(0, path.indexOf('?'));
			}
			if (path === this.$route.path) {
				if (path === '/studio') {
					this.$router.push('/studio');
				}
				this.$router.go(0);
			}
		},
		asideOpen() {
			// 메뉴 열기
			this.openAsideMenu(true);
		},
		activeMenus() {
			// console.log('activeMenus');
			let routePath = this.$route.path;
			// playground 와 playground2 가 있어 playground에 ? 추가
			if (routePath == '/playground') {
				routePath = routePath + '?';
			}

			if (routePath == '/') {
				for (let i = 0; i < this.menus.length; i++) {
					this.menus[i].isActive = false;
				}
				return;
			}

			for (let i = 0; i < this.menus.length; i++) {
				if (this.menus[i].children && this.menus[i].children.length > 0) {
					let childPathActive = false;
					for (let j = 0; j < this.menus[i].children.length; j++) {
						const path = this.menus[i].children[j].path;
						if (path.includes(routePath)) {
							childPathActive = true;
						}
					}
					if (childPathActive) {
						this.menus[i].isActive = true;
					} else {
						this.menus[i].isActive = false;
					}
				} else {
					const path = this.menus[i].path;
					if (path.includes(routePath)) {
						this.menus[i].isActive = true;
					} else {
						this.menus[i].isActive = false;
					}
				}
			}
		},
		clickMoveFANFANAgent() {
			if (typeof this.session == 'undefined' || typeof this.session.userId === 'undefined') {
				this.$router.push({ name: 'Login' });
			} else if (this.session.userPattnCd === '400') {
				//const path = this.isMobile ? '/RequestConnect.jsp?RelayState=/mobile/main.do' : '/RequestConnect.jsp?RelayState=/portal/main.do';
				const path = '/RequestConnect.jsp?RelayState=https://fanfandaero.kr/portal/main.do';
				const url = `${process.env.VUE_APP_FANFAN_SSO_AGENT_HOST}` + path;

				window.location = url;
			} else {
				window.location = 'https://fanfandaero.kr/';
			}
		},
	},
};
</script>
