<template>
	<!--이전 버튼 추가 -->
	<div class="nextprev-btn-cont">
		<button v-if="preId > 0 && isMobile" class="button-default is-large is-rounded is-primary" style="margin-right: 140px" @click="clickMove('prev')">
			<span class="text">이전</span>
		</button>
		<button v-else-if="isMobile" class="button-default is-large is-rounded is-primary" style="margin-right: 140px; pointer-events: none; opacity: 0.5" @click="clickMove('prev')">
			<span class="text">이전</span>
		</button>
		<button v-else-if="preId > 0" class="button-default is-large is-rounded is-primary" style="margin-right: 180px" @click="clickMove('prev')">
			<span class="text">이전</span>
		</button>
		<button v-else class="button-default is-large is-rounded is-primary" style="margin-right: 180px; pointer-events: none; opacity: 0.5; cursor: not-allowed" @click="clickMove('prev')">
			<span class="text">이전</span>
		</button>
		<!--다음 버튼 추가 -->
		<button v-if="nextId > 0" class="button-default is-large is-rounded is-primary" @click="clickMove('next')">
			<span class="text">다음</span>
		</button>
		<button v-else class="button-default is-large is-rounded is-primary" style="pointer-events: none; opacity: 0.5" @click="clickMove('next')">
			<span class="text">다음</span>
		</button>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'NextPrevButton',
	props: {
		routeName: String,
		paramKey: String,
		query: String,
		queryKey: String,
		preId: Number,
		nextId: Number,
		detailFun: Function,
	},
	computed: {
		...mapGetters('common', ['isMobile']),
	},
	methods: {
		clickMove(type) {
			const paramId = type === 'prev' ? this.preId : this.nextId;

			const targetQuery = {};
			if (this.queryKey) {
				targetQuery[this.queryKey] = this.query;
			}

			this.$router.push({ name: this.routeName, params: { [this.paramKey]: paramId }, query: targetQuery });
		},
	},
};
</script>
