import ApiService from '../../assets/js/api.service';
import {
	ACT_GET_LOCAL_ECONO_LIST, ACT_UPDATE_LOCAL_ECONO_INQ
} from '../_act_consts';

const state = {}

const getters = {}

const mutations = {}

const actions = {
	[ACT_GET_LOCAL_ECONO_LIST]({ }, params) {
		return new Promise(resolve => {
			ApiService.query('/v1/app/local/econo', params).then(response => resolve(response));
		});
	},
	[ACT_UPDATE_LOCAL_ECONO_INQ]({ }, params) {
		return new Promise(resolve => {
			ApiService.put('/v1/app/local/econoInq', params).then(response => resolve(response));
		});
	},
}

export const econo = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}