<template>
  <div class="layer-popup-container gachi-layer-popup-container">
    <!--    <div class="layer-popup" :class="{'gachi-mobile-popup': isMobile}" id="popup-community-contact">-->
    <div class="layer-popup" :class="{'gachi-mobile-popup': isMobile}" id="popup-community-contact">
      <div>
        <div class="popup-wrapper">
          <header class="popup-header">
            <h3 class="header-title">{{ msgType }} 하기</h3>
            <button type="button" class="btn-close" @click="closeConnect">
              <i class="icon-close">팝업닫기</i>
            </button>
          </header>
          <div class="popup-body">
            <div v-if="isMobile">
              <pre v-if="msgOrigin && msgOrigin.length > 0" style="text-align: left;padding: 10px;max-height: 120px;overflow: auto;margin-bottom: 20px;box-shadow: 0 0 0 2px #ebebeb inset;">※ 상담 내용 :  <br/>{{ msgOrigin }} </pre>
              <textarea v-model="connect.msgCn" name="" id="" cols="30" rows="10" class="form-control" placeholder="내용을 적어주세요. 빠른 연락을 원하시면 메일 주소등을 남겨주셔도 좋아요." style="padding:10px;box-shadow: 0 0 0 2px #ebebeb inset;">
            </textarea>
            </div>
            <div v-else>
              <pre v-if="msgOrigin && msgOrigin.length > 0" style="text-align: left;padding: 10px;max-height: 120px;overflow: auto;margin-bottom: 20px;box-shadow: 0 0 0 2px #ebebeb inset;">※ 상담 내용 :  <br/>{{ msgOrigin }} </pre>
              <textarea v-model="connect.msgCn" name="" id="" cols="30" rows="10" class="form-control" placeholder="내용을 적어주세요. 빠른 연락을 원하시면 메일 주소등을 남겨주셔도 좋아요." style="max-height: 220px;overflow: auto">
            </textarea>
            </div>
          </div>
          <div style="display: flex;justify-content: center;">
            <vue-recaptcha language="ko" @verify="verify" @expired="expired" sitekey="6LeDFfEfAAAAAMSc6rGLoCMiYVwGOI4bj_LfMqPz"></vue-recaptcha>
          </div>
          <div class="popup-buttons">
            <button class="btn btn-light btn-lg" @click="closeConnect">
              <span class="text">취소</span>
            </button>

            <loading-button
                button-text="보내기"
                :is-loading="isLoading"
                :click-func="clickSend"
                :button-classes="['btn-lg', 'btn-primary']"
                :text-classes="[]"
                loading-size="sm"
            ></loading-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VueRecaptcha } from 'vue-recaptcha';
import {MUT_CLOSE_CONNECT, MUT_SHOW_ALERT, MUT_SHOW_CONFIRM} from '../../store/_mut_consts';
import {mapGetters} from 'vuex';
import LoadingButton from './LoadingButton';
import {ACT_INSERT_MESSAGE} from '../../store/_act_consts';
import {isSuccess, validate} from '../../assets/js/utils';

export default {
  name: 'ConnectModal',
  components: {
    VueRecaptcha,
    LoadingButton
  },
  beforeMount() {
    this.msgOrigin = this.connect.msgCn;
    if (this.connect.rltdTblNo !== 3) {
      this.msgType = '상담'
      if( this.msgOrigin &&  this.msgOrigin.length > 0) {
        this.connect.msgCn = '안녕하십니까, 가치삽시다 담당자입니다.\n' +
            '문의주신 내용에 대해 답변드립니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '더 자세한 내용이 있으실 경우, ' +
            '\n' +
            '1899-0309로 연락부탁드리겠습니다.\n' +
            '(운영시간 : 월~금 09:00~18:00,' +
            '\n' +
            '점심시간 12:00~13:00) \n' +
            '앞으로도 소상공인 여러분에게 도움이 되는\n' +
            '가치삽시다가 되도록 노력하겠습니다.\n' +
            '감사합니다.'
      } else {
        this.connect.msgCn = '1.카테고리 : (포털이용/지원사업/애로사항)' + '\n' +
            '2.질문내용 : '
      }
    } else {
      this.msgType = '연락'
    }
  },
  data: () => ({
    msgCn: '',
    msgOrigin: '',
    msgType: '상담',
    isLoading: false,
    isAuth : false,
  }),
  computed: {
    ...mapGetters('common', ['isMobile', 'connect']),
  },
  methods: {
    closeConnect(){
      this.$store.commit(`common/${MUT_CLOSE_CONNECT}`);
    },
    clickSend(){
      if (!this.isAuth) {
        alert('자동 방지 버튼을 체크해주시기 바랍니다.');
        return;
      }
      //console.log("this.isLoading=="+this.isLoading);
      //console.log("this.isAuth=="+this.isAuth);
      if(this.isLoading) return;
      this.isLoading = true;
      //console.log("this.isLoading1=="+this.isLoading);
      //console.log("this.isAuth1=="+this.isAuth);
      this.msgCn = this.connect.msgCn;
      if(validate(this.$store, this, [{key: 'msgCn', msg: '내용을 입력해주세요.'}])) {
        this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
          title: `메시지를 보내시겠습니까?`,
          showCancelButton: true,
          yesfunc: this.sendMsg,
          nofunc: () => {this.isLoading = false;},
        });
      }else{
        this.isLoading = false;
        this.isAuth = false;
      }
    },
    verify() {
      this.isAuth = true;
    },
    expired(){
      this.isAuth = false;
    },
    sendMsg(){
      //console.log("this.isLoading2=="+this.isLoading);
      //console.log("this.isAuth2=="+this.isAuth);
      if(this.isAuth){
        this.$store.dispatch(`common/${ACT_INSERT_MESSAGE}`, {
          relMsgId: this.connect.relMsgId,
          msgTit: '',
          msgCn: this.connect.msgCn,
          rcvrUserId: this.connect.rcvrUserId,
          rltdTblNo: this.connect.rltdTblNo,
          rltdTblKey: this.connect.rltdTblKey,
          reCAPTCHA: this.isAuth
        }).then(res => {
          if(isSuccess(res)){
            this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
              title: `메시지가 전송되었습니다.`,
              yesfunc: this.closeConnect,
              rightNow: true
            });
          }else if(res.result.number === 900){
            this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '인증이 만료되었습니다.<br/>체크박스를 다시 선택하세요.', html: true,});
          }else{
            this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '오류가 발생했습니다. 다시 시도해주세요.'});
          }
  
        }).catch(e => {
          console.error(e);
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '오류가 발생했습니다. 다시 시도해주세요.'});
          this.isLoading = false;
        });
      }else {
        alert('자동 방지 버튼을 체크해주시기 바랍니다.');
        return;
      }
    },
  }
}
</script>