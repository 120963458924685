<template>
  <div class="layer-popup-container gachi-layer-popup-container" >
    <div class="layer-popup" id="popup-survey-preview" :class="{'gachi-mobile-popup': isMobile}">
      <div class="popup-wrapper">
        <header class="popup-header">
          <h3 class="header-title">{{ survey.surveyNm ? survey.surveyNm : '설문' }}</h3>
          <button type="button" class="btn-close" @click="clickClose">
            <i class="icon-close">팝업닫기</i>
          </button>
        </header>

        <div class="popup-body" :class="{'gachi-mobile-view': isMobile}">
          <!-- begin::survey-preview-container -->
          <div class="survey-preview-container">
            <!-- begin::preview-header -->
            <!-- begin::preview-body -->
            <div class="preview-body">
              <ul class="survey-list">
                <li v-for="(item, idx) in items" class="survey-item" :key="idx">
                  <p class="survey-option-title"><span class="order">{{ (idx + 1) }}</span>{{ item.questNm }}</p>
                  <ol class="survey-option-list">
                    <template v-if="item.questPattnDcd === '1006001'">
                      <template v-for="(quesitm, i) in item.quesitms">
                        <li  :key="`${idx}-${i}`" class="survey-option-item">
                          <input v-model="item.selected" type="radio" :id="`item-${idx}-${i}`" class="form-check-input gachi-cursor-pointer" :name="`question-${idx}`" :value="quesitm"/>
                          <label class="form-check-label" :for="`item-${idx}-${i}`">{{(i + 1)}} - {{ quesitm.quesitmNm }}</label>
                        </li>
                      </template>
                    </template>
                    <template v-else-if="item.questPattnDcd === '1006003'">
                      <div class="rating-wrapper">
                        <div class="rating">
                          <button class="start-btn" type="button" @click="setStarRateScore(idx, 1)">
                            <span class="star" :class="{'is-active': item.chcAns > 0}"></span>
                          </button>
                          <button class="start-btn" type="button" @click="setStarRateScore(idx, 2)">
                            <span class="star" :class="{'is-active': item.chcAns > 1}"></span>
                          </button>
                          <button class="start-btn" type="button" @click="setStarRateScore(idx, 3)">
                            <span class="star" :class="{'is-active': item.chcAns > 2}"></span>
                          </button>
                          <button class="start-btn" type="button" @click="setStarRateScore(idx, 4)">
                            <span class="star" :class="{'is-active': item.chcAns > 3}"></span>
                          </button>
                          <button class="start-btn" type="button" @click="setStarRateScore(idx, 5)">
                            <span class="star" :class="{'is-active': item.chcAns > 4}"></span>
                          </button>
                        </div>
                      </div>
                    </template>

                    <template v-else>
                      <div style="padding: 0 15px">
                        <textarea v-model="item.inptAns" rows="5" class="form-control" placeholder="내용을 적어주세요."></textarea>
                      </div>
                    </template>

                  </ol>
                </li>
              </ul>
            </div>
          </div>

          <div class="popup-buttons " :class="{'gachi-survey-buttons': !isMobile}" >
            <button class="btn btn-light btn-lg" @click="clickClose">
              <span class="text">취소</span>
            </button>
            <loading-button
                button-text="등록"
                :is-loading="isLoading"
                :click-func="clickSave"
                :button-classes="['btn-lg', 'btn-primary']"
                :text-classes="[]"
                loading-size="sm"
            >
            </loading-button>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>
<script>

import {mapGetters, mapState} from 'vuex';
import {getItems, getResult, isSuccess, lengthCheck} from '../../assets/js/utils';
import {ACT_GET_SURVEY_QUESTION_LIST, ACT_INSERT_SURVEY_QUESTION_ANSWER} from '../../store/_act_consts';
import {MUT_CLOSE_SURVEY, MUT_SHOW_ALERT, MUT_SHOW_CONFIRM} from '../../store/_mut_consts';
import LoadingButton from '../common/LoadingButton';
import {msgAlert} from '../../assets/js/modules/validation';

export default {
  name: 'SurveyModal',
  components: {
    LoadingButton
  },
  props: {},
  computed: {
    ...mapGetters('common', ['isMobile']),
    ...mapGetters('survey', ['survey'])
  },
  data: () => ({
    items: [],
    isLoading: false
  }),
  created(){
    if(this.survey && this.survey.surveyId > 0){
      this.$store.dispatch(`survey/${ACT_GET_SURVEY_QUESTION_LIST}`, this.survey.surveyId).then(res => {
        if(lengthCheck(res)){
          this.items = getItems(res);
          // answer key 만들기
          // for(let i = 0; i < this.items.length; i++){
            // 단일 선택형 or 별점형
            // if(this.items[i].questPattnDcd === '1006001' || this.items[i].questPattnDcd === '1006003'){
            // }
          // }
        }
      });
    }
  },
  mounted() {},
  methods: {
    clickSave(){
      if(this.isLoading) return;
      this.isLoading = true;

      const questions = this.items.map(x => {
        if(x.questPattnDcd === '1006001' && x.selected){
          return {
            questId: x.questId,
            quesitmId: x.selected.quesitmId,
            sortSeq: x.sortSeq,
            questPattnDcd: x.questPattnDcd,
            chcAns: (x.quesitms.indexOf(x.selected) + 1),
            inptAns: null
          }
        }
        return {
          questId: x.questId,
          quesitmId: 0,
          sortSeq: x.sortSeq,
          questPattnDcd: x.questPattnDcd,
          chcAns: x.chcAns,
          inptAns: x.inptAns
        }
      });

      // 질문 빈거 체크
      if(this.validateParams(questions)){
        const params = questions.map(x => ({
          questId: x.questId,
          quesitmId: x.quesitmId,
          sortSeq: x.sortSeq,
          chcAns: x.chcAns,
          inptAns: x.inptAns
        }));

        this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
          title: '설문을 제출 하시겠습니까?',
          showCancelButton: true,
          yesfunc: () => {this.insertSurveyQuestionAnswer(params);},
          nofunc: () => {this.isLoading = false;}
        });
      }else{
        this.isLoading = false;
      }
    },
    insertSurveyQuestionAnswer(params){
      this.$store.dispatch(`survey/${ACT_INSERT_SURVEY_QUESTION_ANSWER}`, {
        surveyId: this.survey.surveyId,
        questAnsJson: JSON.stringify(params)
      })
      .then(res => {
        this.isLoading = false;
        if(isSuccess(res)){
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '설문이 제출되었습니다.'});
          this.survey.submitFunc();
          this.clickClose();
        }else{
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: getResult(res).message});
        }
      })
      .catch(e => {
        console.error(e);
        this.isLoading = false;
        this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '오류가 발생했습니다.'});
      })
    },
    clickClose(){
      this.$store.commit(`survey/${MUT_CLOSE_SURVEY}`);
    },
    setStarRateScore(idx, score){
      this.items[idx].chcAns = score;
    },
    validateParams(params){
      let msgs = [];

      for(let i = 0; i < params.length; i++){
        if(params[i].questPattnDcd === '1006001' || params[i].questPattnDcd === '1006003'){
          if(!params[i].chcAns){
            msgs.push(`${(i + 1)}번 질문`);
          }
        }else{
          if(!params[i].inptAns){
            msgs.push(`${(i + 1)}번 질문`);
          }
        }
      }
      const len = msgs.length;
      if(len > 0) msgAlert('설문 질문을 전부 응시해주세요.', msgs);
      return len === 0;
    }
  }
}
</script>
