<template>
	<div class="layer-popup-container gachi-layer-popup-container">
		<div class="layer-popup" id="popup-mypage-withdrawal">
			<div class="popup-wrapper">
				<header class="popup-header">
					<h3 class="header-title">회원탈퇴</h3>
					<button type="button" class="btn-close" @click="closeModal">
						<i class="icon-close">팝업닫기</i>
					</button>
				</header>

				<div class="popup-body">
					<p class="section-subtext"></p>
					<h6 class="title">* 유의사항</h6>
					<p>
						<strong>사용하고 계신 ID는 탈퇴할 경우, 재사용 및 복구가 불가능합니다.</strong>
						<br />
						탈퇴한 ID는 본인과 타인 모두 재사용 및 복구가 불가능하며,<br />
						탈퇴 후 다른 ID로의 재가입은 가능합니다.
					</p>
					<p>
						<strong>회원탈퇴 후, 해당 ID를 통한 하단 서비스는 확인이 불가능합니다.</strong><br />
						스튜디오 예약내역,지원사업 진행현황, 설문, 상담내역
					</p>
					<p><strong>회원탈퇴 후에도 게시판형 서비스에 등록하신 게시물은 남아있습니다.</strong></p>
					<p>댓글, 게시글에 대한 삭제를 원하신다면 반드시 탈퇴 전에 삭제해주세요.</p>
					<br />
					<div class="form-checkbox">
						<input v-model="agreeWhdwl" type="checkbox" id="privacyAgreeCheck" name="privacyAgreeCheck" />
						<label class="form-check-label" for="privacyAgreeCheck">회원 탈퇴시 발생하는 유의사항을 확인하였습니다.</label>
					</div>
				</div>
				<div class="popup-buttons">
					<button type="button" class="button-default is-rounded is-primary" :class="{ 'is-disabled': !agreeWhdwl }" @click="clickWhdwlModal">
						<span class="text">회원 탈퇴</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { MUT_CLOSE_WHDWL_MODAL, MUT_SHOW_ALERT, MUT_SHOW_CONFIRM, MUT_SHOW_PROMPT, MUT_SHOW_WHDWL_MODAL } from '@/store/_mut_consts';
import { ACT_GET_SESSION, ACT_LOGIN_USER, ACT_REMOVE_AUTH_TOKEN, ACT_USER_WHDWL } from '@/store/_act_consts';
import { getItem, isSuccess, strToHex } from '@/assets/js/utils';
import SHA512 from 'crypto-js/sha512';
import Base64 from 'crypto-js/enc-base64';
import { mapGetters } from 'vuex';

export default {
	name: 'WhdwlModal',
	data: () => ({
		agreeWhdwl: false,
	}),
	computed: {
		...mapGetters('auth', ['isAuth', 'session']),
	},
	methods: {
		async clickWhdwlModal() {
			this.closeModal();

			await this.$store.commit(`common/${MUT_SHOW_PROMPT}`, {
				title: `비밀번호를 입력해주세요.`,
				showCancelButton: true,
				yesfunc: async (param) => {
					const res = await this.$store.dispatch(`auth/${ACT_GET_SESSION}`);
					this.item = getItem(res);
					const SYS_TYP_CD = '800';
					// this.isLoading = true;
					const LGN_ID = this.item.lgnId;
					// const PASSWD = this.passwd.trim();
					let PASSWD = strToHex(SHA512(param.trim()).toString(Base64));

					try {
						const authRes = await this.$store.dispatch(`auth/${ACT_LOGIN_USER}`, { LGN_ID, PASSWD, SYS_TYP_CD });
						this.lgnFailReason = authRes.result.code;
						this.isLoading = false;
						if (isSuccess(authRes) || this.session.userPattnCd == '400') {
							this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
								title: `탈퇴하시겠습니까?`,
								showCancelButton: true,
								yesfunc: async () => {
									this.isLoading = true;
									await this.$store.dispatch(`auth/${ACT_USER_WHDWL}`, { lgnId: '' }).then((res) => {
										if (isSuccess(res)) {
											this.$store.dispatch(`auth/${ACT_REMOVE_AUTH_TOKEN}`);
											this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
												title: '탈퇴가 완료되었습니다.',
												yesfunc: () => {
													this.$router.push({ name: 'Main' });
												},
											});
										} else if (res && res.result && res.result.number === 301) {
											this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
												title: '마켓 입점 이력이 있습니다. <br/>고객센터에 문의해주세요. <br/>1899-0309',
												html: true,
											});
										} else if (res && res.result && res.result.number === 302) {
											this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
												title: '관리자 권한이 부여되어 있습니다. <br/>고객센터에 문의해 주세요.<br/>1899-0309',
												html: true,
											});
										} else {
											this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
												title: '탈퇴처리가 실패하였습니다. <br/>고객센터에 문의해 주세요.<br/>1899-0309',
												html: true,
											});
										}
									});
									this.isLoading = false;
								},
							});
						} else if (this.lgnFailReason === 'LGN_FAIL_TMS') {
							this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
								title: '비밀번호를 5회 잘못 입력하셨습니다. <br/>본인인증 후 비밀번호를 재설정 해주세요.',
								yesfunc: () => {
									this.$store.dispatch(`auth/${ACT_REMOVE_AUTH_TOKEN}`);
									this.$router.push({ name: 'FindInfo', params: { division: 'password' } });
								},
								html: true,
							});
						} else {
							await this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
								title: '비밀번호가 잘못되었습니다.',
								confirmButtonText: '다시입력',
								cancelButtonText: '닫기',
								showCancelButton: true,
								yesfunc: () => {
									this.clickWhdwlModal();
								},
							});
						}
					} catch (e) {
						console.error(e);
						this.viewError();
					}
				},
				nofunc: async () => {
					this.isLoading = false;
				},
			});
		},
		closeModal() {
			this.$store.commit(`auth/${MUT_CLOSE_WHDWL_MODAL}`);
		},
	},
};
</script>
