import ApiService from '../../assets/js/api.service';
import {
	ACT_GET_SUBS_ECNMY_LIST,
	ACT_UPDATE_SUBS_ECNMY_INQ,
} from '../_act_consts';

const state = {}

const getters = {}

const mutations = {}

const actions = {
	[ACT_GET_SUBS_ECNMY_LIST]({ }, params) {
		return new Promise(resolve => {
			ApiService.query('/v1/app/subsecnmy/subsEcnmy', params).then(response => resolve(response));
		});
	},
	[ACT_UPDATE_SUBS_ECNMY_INQ]({ }, params) {
		return new Promise(resolve => {
			ApiService.put('/v1/app/subsecnmy/subsEcnmyInq', params).then(response => resolve(response));
		});
	},
}

export const subsecnmy = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}