import ApiService from '../../assets/js/api.service';
import {
	ACT_GET_DIAGN_AREA_LIST,
	ACT_GET_MSS_SPRT_BIZ_CMPNY_STATUS_LIST,
	ACT_GET_DIAGN_SELF_CHECK,
	ACT_GET_DIAGN_HIS_LIST
} from '../_act_consts';

const state = {}

const getters = {}

const mutations = {}

const actions = {
	[ACT_GET_DIAGN_AREA_LIST]({ }, id) {
		return new Promise(resolve => {
			ApiService.query('/v1/app/diagn/area?parentId=' + id).then(response => resolve(response));
		});
	},
	[ACT_GET_MSS_SPRT_BIZ_CMPNY_STATUS_LIST]({ }, brno) {
		return new Promise(resolve => {
			ApiService.query('/v1/app/mss/sprt/biz/cmpny/statusList?brno=' + brno).then(response => resolve(response));
		});
	},
	[ACT_GET_DIAGN_SELF_CHECK]({ }, params) {
		return new Promise(resolve => {
			ApiService.post('/v1/app/diagn/self/check', params).then(response => resolve(response));
		});
	},
	[ACT_GET_DIAGN_HIS_LIST]({ }) {
		return new Promise(resolve => {
			ApiService.query('/v1/app/diagn/his').then(response => resolve(response));
		});
	},
}

export const diagn = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}

export const Tabs = [
	{
		text: '기본정보',
		complete: true,
		current: true,
	},
	{
		text: '경영정보',
		complete: false,
		current: false,
	},
	{
		text: '추가정보',
		complete: false,
		current: false,
	},
	// {
	// 	text: '선택정보',
	// 	complete: false,
	// 	current: false,
	// },
	{
		text: '결과',
		complete: false,
		current: false,
	},
]

export const Gender = [
	{
		label: '남자',
		value: 'M',
	},
	{
		label: '여자',
		value: 'W',
	},
]
export const Age = [
	{
		label: '20대',
		value: 'AGE_20',
	},
	{
		label: '30대',
		value: 'AGE_30',
	},
	{
		label: '40대',
		value: 'AGE_40',
	},
	{
		label: '50대',
		value: 'AGE_50',
	},
	{
		label: '60대',
		value: 'AGE_60',
	},
	{
		label: '70대 이상',
		value: 'AGE_70',
	},
]

export const SectorVal = {
    WHSAL_RTLSAL: '도소매'
    , MNFCTUR: '제조'
    , SERVICE: '서비스'
    , FOOD: '음식점'
    , ETC: '기타'
}
export const SectorDetail =  {
    WHSAL_RTLSAL: [
		{ label: '전자상거래', value: 'EME_COMRC' },
		{ label: '슈퍼마켓', value: 'SUPERMKT' },
		{ label: '편의점', value: 'CONVENIENCE' },
		{ label: 'PC/부품', value: 'PC_PARTS' },
		{ label: '핸드폰', value: 'CELLPHONE' },
		{ label: '주류', value: 'LIQUOR' },
		{ label: '미곡', value: 'RICE' },
		{ label: '육류', value: 'MEAT' },
		{ label: '수산물', value: 'SEAFOOD' },
		{ label: '과일/채소', value: 'FRUIT_VEG' },
		{ label: '반찬', value: 'SIDEDISH' },
		{ label: '의류', value: 'CLOTHING' },
		{ label: '패션/잡화', value: 'FASHION' },
		{ label: '안경', value: 'GLASSES' },
		{ label: '시계/귀금속', value: 'WATCH_JEWELRY' },
		{ label: '의약품', value: 'MEDICINE' },
		{ label: '서적', value: 'BOOKS' },
		{ label: '완구/문구', value: 'TOYS_STATIONERY' },
		{ label: '화장품', value: 'COSMETICS' },
		{ label: '미용재료', value: 'BEAUTY_SUPPLIES' },
		{ label: '스포츠용품', value: 'SPORTS_GOODS' },
		{ label: '자전거/장비', value: 'BIKE_EQUIPMENT' },
		{ label: '화초', value: 'FLOWERS' },
		{ label: '반려동물', value: 'PETS' },
		{ label: '가구', value: 'RETAIL_FURNITURE' },
		{ label: '가전', value: 'APPLIANCES' },
		{ label: '철물', value: 'HARDWARE' },
		{ label: '약기', value: 'MEDICAL_INSTRUMENTS' },
		{ label: '인테리어/조명', value: 'INTERIOR_LIGHTING' },
		{ label: '자동차/중고차', value: 'CARS_USED_CARS' },
		{ label: '모터사이클', value: 'MOTORCYCLE' },
		{ label: '예술품', value: 'ART' },
		{ label: '주유소', value: 'GAS_STATION' }
	  ]
    , MNFCTUR: [
		{ label: '식료품', value: 'FOOD' },
		{ label: '음료', value: 'BEVERAGE' },
		{ label: '담배', value: 'TOBACCO' },
		{ label: '섬유제품', value: 'TEXTILES' },
		{ label: '의류/액세서리', value: 'CLOTHING_ACCESSORIES' },
		{ label: '가방/신발', value: 'BAGS_SHOES' },
		{ label: '목재', value: 'WOOD' },
		{ label: '종이', value: 'PAPER' },
		{ label: '인쇄', value: 'PRINTING' },
		{ label: '연탄/석유정제품', value: 'COAL_PETROLEUM' },
		{ label: '화학제품', value: 'CHEMICALS' },
		{ label: '의약품', value: 'PHARMACEUTICALS' },
		{ label: '고무/플라스틱제품', value: 'RUBBER_PLASTIC' },
		{ label: '비금속/광물제품', value: 'NONMETAL_MINERALS' },
		{ label: '금속', value: 'METALS' },
		{ label: '금속 가공제품', value: 'METAL_PRODUCTS' },
		{ label: '전자/컴퓨터', value: 'ELECTRONICS_COMPUTERS' },
		{ label: '의료/정밀/광학기기', value: 'MEDICAL_PRECISION_OPTICAL' },
		{ label: '전기장비', value: 'ELECTRICAL_EQUIPMENT' },
		{ label: '기계/장비', value: 'MACHINERY_EQUIPMENT' },
		{ label: '자동차', value: 'AUTOMOBILES' },
		{ label: '운송장비', value: 'TRANSPORT_EQUIPMENT' },
		{ label: '가구', value: 'MAF_FURNITURE' },
		{ label: '산업용기계', value: 'INDUSTRIAL_MACHINERY' },
		{ label: '기타제품', value: 'OTHER_PRODUCTS' }
	  ]
    , SERVICE: [
		{ label: '일반교습학원', value: 'GENERAL_TRAINING_ACADEMY' },
		{ label: '외국어학원', value: 'LANGUAGE_ACADEMY' },
		{ label: '예술학원', value: 'ART_ACADEMY' },
		{ label: '컴퓨터학원', value: 'COMPUTER_ACADEMY' },
		{ label: '스포츠강습', value: 'SPORTS_LESSON' },
		{ label: '의원', value: 'CLINIC' },
		{ label: '동물병원', value: 'VETERINARY_CLINIC' },
		{ label: '변호사', value: 'LAWYER' },
		{ label: '변리사', value: 'PATENT_ATTORNEY' },
		{ label: '법무사', value: 'JUDICIAL_SCRIVENER' },
		{ label: '회계사', value: 'ACCOUNTANT' },
		{ label: '세무사', value: 'TAX_ACCOUNTANT' },
		{ label: '당구/골프/볼링', value: 'BILLIARDS_GOLF_BOWLING' },
		{ label: 'PC/게임', value: 'PC_GAME' },
		{ label: '기타오락', value: 'OTHER_ENTERTAINMENT' },
		{ label: '복권', value: 'LOTTERY' },
		{ label: '통신기기', value: 'COMMUNICATION_DEVICE' },
		{ label: '자동차/사이클수리', value: 'CAR_CYCLE_REPAIR' },
		{ label: '미용실', value: 'HAIR_SALON' },
		{ label: '네일/피부관리', value: 'NAIL_SKINCARE' },
		{ label: '세탁', value: 'LAUNDRY' },
		{ label: '가전제품수리', value: 'APPLIANCE_REPAIR' },
		{ label: '부동산중개', value: 'REAL_ESTATE_AGENT' },
		{ label: '숙박', value: 'ACCOMMODATION' },
		{ label: '노래방', value: 'KARAOKE' },
		{ label: '독서실', value: 'READING_ROOM' },
		{ label: 'DVD방', value: 'DVD_ROOM' },
		{ label: '녹음실', value: 'RECORDING_STUDIO' },
		{ label: '사진관', value: 'PHOTO_STUDIO' },
		{ label: '통번역', value: 'TRANSLATION' },
		{ label: '청소', value: 'CLEANING' },
		{ label: '여행', value: 'TRAVEL' },
		{ label: '비디오/서적임대', value: 'VIDEO_BOOK_RENTAL' },
		{ label: '의류임대', value: 'CLOTHING_RENTAL' },
		{ label: '가정용품임대', value: 'HOUSEHOLD_ITEM_RENTAL' }
	  ]
    , FOOD: [
		{ label: '한식', value: 'KOREAN_FOOD' },
		{ label: '분식', value: 'SNACK_FOOD' },
		{ label: '일식', value: 'JAPANESE_FOOD' },
		{ label: '양식', value: 'WESTERN_FOOD' },
		{ label: '커피/음료', value: 'COFFEE_BEVERAGE' },
		{ label: '패스트푸드', value: 'FAST_FOOD' },
		{ label: '치킨', value: 'CHICKEN' },
		{ label: '제과', value: 'BAKERY' },
		{ label: '주류', value: 'ALCOHOL' }
	  ]
    , ETC: []
}
export const Sector = [
	{
		label: SectorVal.WHSAL_RTLSAL,
		value: 'WHSAL_RTLSAL',
		sub: SectorDetail.WHSAL_RTLSAL
	},
	{
		label: SectorVal.MNFCTUR,
		value: 'MNFCTUR',
		sub: SectorDetail.MNFCTUR
	},
	{
		label: SectorVal.SERVICE,
		value: 'SERVICE',
		sub: SectorDetail.SERVICE,
	},
	{
		label: SectorVal.FOOD,
		value: 'FOOD',
		sub: SectorDetail.FOOD,
	},
	{
		label: SectorVal.ETC,
		value: 'ETC',
		sub: SectorDetail.ETC,
	},
]
export const Exper = [
	{
		label: '1년 미만',
		value: 'YEAR_1',
	},
	{
		label: '1년 이상 ~ 3년 미만',
		value: 'YEAR_1_3',
	},
	{
		label: '3년 이상 ~ 5년 미만',
		value: 'YEAR_3_5',
	},
	{
		label: '5년 이상 ~ 7년 미만',
		value: 'YEAR_5_7',
	},
	{
		label: '7년 이상 ~ 10년 미만',
		value: 'YEAR_7_10',
	},
	{
		label: '10년 이상',
		value: 'YEAR_10',
	},
]
export const CmpnyScale = [
	{
		label: '1인 사업자',
		value: 'PERSONNEL_1',
	},
	{
		label: '2~5인',
		value: 'PERSONNEL_2_5',
	},
	{
		label: '5~10인',
		value: 'PERSONNEL_5_10',
	},
	{
		label: '10인이상',
		value: 'PERSONNEL_10',
	},
]
export const Sales = [
	{
		label: '100만원 미만',
		value: 'SALES_100',
	},
	{
		label: '100만원~500만원',
		value: 'SALES_100_500',
	},
	{
		label: '500만원~1,000만원',
		value: 'SALES_500_1000',
	},
	{
		label: '1,000만원~2,000만원',
		value: 'SALES_1000_2000',
	},
	{
		label: '2,000만원 이상',
		value: 'SALES_2000',
	},
]
export const CmpnyType = [
	{
		label: '점포만 운영',
		value: 'OFFLINE',
	},
	{
		label: '온라인만 운영',
		value: 'ONLINE',
	},
	{
		label: '점포+온라인 병행',
		value: 'OFF_ONLINE',
	},
]
export const DiffField = [
	{
		label: '온라인 진출',
		value: 'ONLINE',
	},
	{
		label: '촬영장비/장소 대여',
		value: 'LOCATION',
	},
	{
		label: '판로개척',
		value: 'PIONEER',
	},
	{
		label: '세무/회계',
		value: 'TAX',
	},
	{
		label: '홍보/마케팅',
		value: 'PROMOTION',
	},
	{
		label: '라이브 커머스',
		value: 'LIVE',
	},
	{
		label: '해외판매',
		value: 'GLOBAL',
	},
	{
		label: '상품 콘텐츠',
		value: 'CONTENTS',
	},
	{
		label: '사업 컨설팅',
		value: 'CONSULT',
	},
	{
		label: '상품 성공사례',
		value: 'EXAMPLE',
	},
]
export const ScoreManage = [
	{
		label: '아직&nbsp;<strong>많이 부족</strong>하다.',
		value: 1,
	},
	{
		label: '<strong>기초적인 수준</strong>이라고 생각한다.',
		value: 2,
	},
	{
		label: '<strong>적당한 수준</strong>이라고 생각한다.',
		value: 3,
	},
	{
		label: '<strong>꽤 탄탄</strong>하다고 생각한다.',
		value: 4,
	},
	{
		label: '<strong>아주 높은 자신감</strong>을 가지고 있다.',
		value: 5,
	},
]
export const ScoreMarketing = [
	{
		label: '아직&nbsp;<strong>많이 부족</strong>하다.',
		value: 1,
	},
	{
		label: '<strong>기초적인 수준</strong>이라고 생각한다.',
		value: 2,
	},
	{
		label: '<strong>적당한 수준</strong>이라고 생각한다.',
		value: 3,
	},
	{
		label: '<strong>꽤 자신 있는 편</strong>이라고 생각한다.',
		value: 4,
	},
	{
		label: '<strong>깊이 있고 뛰어난 능력</strong>을 가지고 있다.',
		value: 5,
	},
]
export const ScoreItem = [
	{
		label: '<strong>매우 부족</strong>하다고 생각한다.',
		value: 1,
	},
	{
		label: '<strong>다소 부족</strong>하다고 생각한다.',
		value: 2,
	},
	{
		label: '<strong>유사상품과 비슷한 수준</strong>이라고 생각한다.',
		value: 3,
	},
	{
		label: '<strong>꽤 뛰어난 편</strong>이라고 생각한다.',
		value: 4,
	},
	{
		label: '<strong>매우 우수</strong>하다고 생각한다.',
		value: 5,
	},
]
export const ScoreFinance = [
	{
		label: '<strong>매우 불안정하고 부족</strong>하다고 생각한다.',
		value: 1,
	},
	{
		label: '<strong>기본적인 수준에는 미치지만 부족한 부분이 많다</strong>고 생각한다.',
		value: 2,
	},
	{
		label: '<strong>평균적인 수준</strong>이라고 생각한다.',
		value: 3,
	},
	{
		label: '<strong>꽤 안정적이고 좋은 편</strong>이라고 생각한다.',
		value: 4,
	},
	{
		label: '<strong>매우 건전하고 우수</strong>하다고 생각한다.',
		value: 5,
	},
]
export const ScoreLocation = [
	{
		label: '<strong>매우 부족하고 불리한 상황</strong>이라고 생각한다.',
		value: 1,
	},
	{
		label: '<strong>다소 약하고 개선이 필요</strong>하다고 생각한다.',
		value: 2,
	},
	{
		label: '<strong>특출나지 않지만 적당한 수준</strong>이라고 생각한다.',
		value: 3,
	},
	{
		label: '<strong>꽤 유리하고 좋은 편</strong>이라고 생각한다.',
		value: 4,
	},
	{
		label: '<strong>매우 유리하고 뛰어난 상황</strong>이라고 생각한다.',
		value: 5,
	},
]
export const ScoreReady = [
	{
		label: '<strong>매우 부족하고 미비</strong>하다고 생각한다.',
		value: 1,
	},
	{
		label: '<strong>기본적인 수준에 미치지만 보완이 필요하다</strong>고 생각한다.',
		value: 2,
	},
	{
		label: '<strong>평균적인 수준</strong>이라고 생각한다.',
		value: 3,
	},
	{
		label: '<strong>꽤 잘 갖추어져 있다</strong>고 생각한다.',
		value: 4,
	},
	{
		label: '<strong>매우 철저하고 완벽하게 준비되었다</strong>고 생각한다.',
		value: 5,
	},
]
export const Yn = [
	{
		label: '예',
		value: 'Y',
	},
	{
		label: '아니오',
		value: 'N',
	},
]

export const Shop = [
	{
	  label: '11번가',
	  value: 'STREET11'
	},
	{
	  label: '롯데온',
	  value: 'LOTTE_ON'
	},
	{
	  label: '쿠팡',
	  value: 'COUPANG'
	},
	{
	  label: 'GS샵',
	  value: 'GSSHOP'
	},
	{
	  label: '네이버쇼핑',
	  value: 'NAVER_SHOPPING'
	},
	{
	  label: '신세계몰/이마트몰',
	  value: 'SHINSEGAE_EMART'
	},
	{
	  label: '기타',
	  value: 'OTHER'
	},
	{
		label: '해당없음',
		value: 'NONE'
	}
];
  
export const Gshop = [
	{
	  label: '아마존',
	  value: 'AMAZON'
	},
	{
	  label: '큐텐',
	  value: 'QOO10'
	},
	{
	  label: '이베이',
	  value: 'EBAY'
	},
	{
	  label: '지마켓글로벌',
	  value: 'GMARKET_GLOBAL'
	},
	{
	  label: '알리익스프레스',
	  value: 'ALIEXPRESS'
	},
	{
	  label: '기타',
	  value: 'OTHER'
	},
	{
		label: '해당없음',
		value: 'NONE'
	}
];
export const Global = [
	{
	  label: '중국',
	  value: 'CHINA'
	},
	{
	  label: '미국',
	  value: 'USA'
	},
	{
	  label: '베트남',
	  value: 'VIETNAM'
	},
	{
	  label: '일본',
	  value: 'JAPAN'
	},
	{
	  label: '홍콩',
	  value: 'HONG_KONG'
	},
	{
	  label: '기타',
	  value: 'OTHER'
	},
	{
		label: '해당없음',
		value: 'NONE'
	}
];