export const swiperOptions = (options) => {
	let defaultOptions = {
		direction: 'horizontal',
		slidesPerView: 'auto', //공통
		centeredSlides: true, // 공통
		spaceBetween: 0, // 캠페인, 크리에이터, 소상공인
		speed: 1000, // 공통
		parallax: true, // 공통
		loop: true, // 공통
		autoplay: {
			// 크리에이터, 소상공인, 배너
			delay: 4000,
			disableOnInteraction: false,
		},
		allowTouchMove: true,
	};

	if (options) defaultOptions = Object.assign(defaultOptions, options);

	return defaultOptions;
};

//메인화면 공지팝업용 스와이퍼옵션 (20220712 - hib)
export const swiperNoticePopOptions = (options) => {
	let defaultOptions = {
		slidesPerView: 1,
		loop: true,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
	};

	if (options) defaultOptions = Object.assign(defaultOptions, options);

	return defaultOptions;
};
