import ApiService from '../api.service';

export const gachiConfig = {
  allowedContent : true,
  removePlugins: 'image,cloudservices',
  extraPlugins: 'gachi-nas-embed,gachi-cate-embed,gachi-embed,gachi-cloudservices,gachi-easyimage,colorbutton,font,slideImage,justify',
  toolbar: [
    {
      name: 'document',
      items: ['Undo', 'Redo','Source']
    },
    {
      name: 'basicstyles',
      // items: ['FontSize', 'Bold', 'Italic', 'Strike', '-', 'RemoveFormat', ]
      items: ['Bold', 'Italic', 'Strike', '-', 'RemoveFormat', ]
    },
    {
      name: 'colors',
      items: ['TextColor', 'BGColor'],
    },
    {
      name: 'styles',
      items: [ 'Styles', 'Format', 'Font', 'FontSize' ]
    },
    {
      name: 'paragraph',
      items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl']
    },
    {
      name: 'links',
      items: ['Link', 'Unlink']
    },
    {
      name: 'insert',
      items: ['EasyImageUpload']
    },
    {
      name: 'gachi-embed',
      items: ['GachiEmbed']
    },
    {
      name: 'gachi-cate-embed',
      items: ['GachiCateEmbed']
    },
    {
      name: 'gachi-nas-embed',
      items: ['GachiNasEmbed']
    },
    {
      name: 'gachi-slide',
      items: ['SlideImage']
    },
  ],
  height: '500',
  cloudServices_uploadUrl: `${ApiService.apisHost}/v1/app/nhn/upload`,
  cloudServices_tokenUrl: `${ApiService.apisHost}/v1/app/nhn/upload-auth`,
  easyimage_toolbar: [
    'EasyImageFull',
    'EasyimageResize',
    'EasyImageAlignLeft',
    'EasyImageAlignCenter',
    'EasyImageAlignRight',
  ],
  embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}'
};