<template>
  <div class="layer-popup-container gachi-layer-popup-container" >
    <div class="layer-popup" id="popup-mypage-profile">
      <div>
        <div class="popup-wrapper">
          <header class="popup-header">
            <h3 class="header-title">인플루언서 {{ creatorText }}</h3>
            <button type="button" class="btn-close" @click="closeModal">
              <i class="icon-close">팝업닫기</i>
            </button>
          </header>
          <div class="popup-body" :class="{'is-pc': !isMobile}">
            <div class="form-field">
              <div class="form-row">
                <div class="form-cellth">
                  <label>인플루언서 <br/>이름 <span style="color: red">*</span></label>
                </div>
                <div class="form-celltd">
                  <input v-model="params.ctrNm" type="text" class="form-control" placeholder="인플루언서 이름" required="">
                </div>
              </div>

              <div class="form-row">
                <div class="form-cellth">
                  <label>인플루언서 <br/>활동명 <span style="color: red">*</span></label>
                </div>
                <div class="form-celltd">
                  <input v-model="params.ctrActvtNm" type="text" class="form-control" placeholder="인플루언서 활동명" required="">
                </div>
              </div>

              <div class="form-row">
                <div class="form-cellth">
                  <label>전화번호</label>
                </div>
                <div class="form-celltd">
                  <input v-model="telNo" type="text" maxlength="13" class="form-control" placeholder="전화번호 입력">
                </div>
              </div>
              <div class="form-row">
                <div class="form-cellth">
                  <label>휴대폰 <span style="color: red">*</span></label>
                </div>
                <div class="form-celltd">
                  <input v-model="mblNo" type="text" maxlength="13" class="form-control" placeholder="번호입력">
                </div>
              </div>
              <div class="form-row">
                <div class="form-cellth">
                  <label>이메일 <span style="color: red">*</span></label>
                </div>
                <div class="form-celltd">
                  <input v-model="ctrEmail" type="text" class="form-control" placeholder="이메일 입력">
                  <div class="text-left">
                    <small class="small-text" style="color: red" v-if="!isEmailCheck && ctrEmail.length > 0">* 이메일 형식이 맞지 않습니다.</small>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="form-cellth">
                  <label> 성별 <span style="color: red">*</span></label>
                </div>
                <div class="form-celltd">
                  <select v-model="params.gndrCd" class="form-control">
                    <option value="">선택</option>
                    <option v-for="(option, idx) in gndrCdOptions" :value="option.cmmnCdVal" :key="idx">{{ option.cmmnCdValNm }}</option>
                  </select>
                </div>
              </div>

              <div class="form-row">
                <div class="form-cellth">
                  <label>유튜브 <span style="color: red">*</span></label>
                </div>
                <div class="form-celltd">
                  <select v-model="params.ytbChnlCtgrTypCd" class="form-control mb-1">
                    <option value="">선택</option>
                    <option v-for="(option, idx) in ytbChnlCtgrTypCdOptions" :value="option.cmmnCdVal" :key="idx">{{ option.cmmnCdValNm }}</option>
                  </select>
                  <input v-model="params.ytbChnlUrl" type="text" class="form-control mb-1" placeholder="유튜브 URL" required="">
                  <input v-model="params.ytbChnlNm" type="text" class="form-control" placeholder="유튜브 채널명" required="">
                </div>
              </div>

              <div class="form-row">
                <div class="form-cellth">
                  <label>우편번호</label>
                </div>
                <button v-if="isMobile" class="btn btn-primary btn-form mb-2" type="button" @click="showDaumPost"><span class="text">우편 번호 찾기</span></button>

                <div class="form-celltd" v-if="!isMobile">
                  <input v-model="params.zipNo" class="form-control" type="text" placeholder="우편 번호 입력" required="">
                </div>
                <button v-if="!isMobile" class="btn btn-lg btn-primary btn-form" type="button" @click="showDaumPost"><span class="text">우편 번호 찾기</span></button>
              </div>

              <div class="form-row"  v-if="isMobile">
                <div class="form-celltd">
                  <input v-model="params.zipNo" class="form-control" type="text" placeholder="우편 번호 입력">
                </div>
              </div>

              <div class="form-row">
                <div class="form-celltd">
                  <input v-model="params.bscAddr" class="form-control" type="text" placeholder="주소 입력">
                </div>
              </div>

              <div class="form-row">
                <div class="form-celltd">
                  <input v-model="params.dtlAddr" class="form-control" type="text" placeholder="상세 주소 입력">
                </div>
              </div>

              <div class="form-row">
                <div class="form-cellth">
                  <label>비고</label>
                </div>
                <div class="form-celltd">
                  <div class="form-celltd">
                    <textarea v-model="params.ctrNote" class="form-control" rows="4" placeholder="비고를 입력하세요"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="popup-buttons">
            <button class="btn btn-light btn-lg" @click="closeModal">
              <span class="text">취소</span>
            </button>
            <button class="btn btn-primary btn-lg" @click="clickSave">
              <span class="text">{{creatorText}}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  MUT_CLOSE_CREATOR_CHANGE_MODAL,
  MUT_SET_COMMON_CODE_ITEMS,
  MUT_SET_SESSION,
  MUT_SHOW_ADDRESS_MODAL,
  MUT_SHOW_ALERT,
  MUT_SHOW_CONFIRM,
} from '../../store/_mut_consts';
import {mapGetters} from 'vuex';
import {
  ACT_GET_CREATOR,
  ACT_GET_SESSION,
  ACT_GET_SHOP_MALL_COMMON_CODE_LIST,
  ACT_SAVE_CREATOR,
} from '../../store/_act_consts';
import {
  getCheckItems,
  getItem,
  isSuccess,
  lengthCheck,
  phoneNumberMask,
  setParams,
  validate,
} from '../../assets/js/utils';
import {checkEmail} from '../../assets/js/modules/validation';

export default {
  name: 'CreatorChangeModal',
  data: () => ({
    isLoading: false,
    postLoading: false,
    params: {
      ctrNm: '',
      ctrActvtNm: '',
      ytbChnlUrl: '',
      ytbChnlNm: '',
      ytbChnlCtgrTypCd: '',

      telNo: '',
      mblNo: '',
      ctrEmail: '',
      zipNo: '',
      bscAddr: '',
      dtlAddr: '',

      ctrNote: '',
      gndrCd: '',
    },

    telNo: '',
    mblNo: '',
    ctrEmail: '',
    isEmailCheck: false
  }),
  computed: {
    ...mapGetters('auth', ['session']),
    ...mapGetters('common', ['isMobile', 'commonCode']),
    creatorText(){
      return this.session.userPattnCd === '300' ? '수정' : '전환'
    },
    gndrCdOptions(){
      return this.commonCode.filter(x => x.cmmnCd === 'GNDR_CD');
    },
    ytbChnlCtgrTypCdOptions(){
      return this.commonCode.filter(x => x.cmmnCd === 'YTB_CHNL_CTGR_TYP_CD');
    }
  },
  watch: {
    telNo(){
      this.telNo = phoneNumberMask(this.telNo);
    },
    mblNo(){
      this.mblNo = phoneNumberMask(this.mblNo)
    },
    ctrEmail(){
      this.isEmailCheck = checkEmail(this.ctrEmail);
    }
  },
  created(){
    if(this.commonCode.length === 0) this.getCommonCdList();

    if(this.session.userPattnCd === '300') {
      this.getCreator();
    }
  },
  methods: {
    getCreator(){
      this.$store.dispatch(`auth/${ACT_GET_CREATOR}`).then(res=>{
        if(lengthCheck(res)){
          const item = getItem(res);
          setParams(this.params, item);

          this.telNo = item.telNo;
          this.mblNo = item.mblNo;
          this.ctrEmail = item.ctrEmail;
        }
      });
    },
    getCommonCdList(){
      this.$store.dispatch(`common/${ACT_GET_SHOP_MALL_COMMON_CODE_LIST}`, {}).then(res => {
        this.$store.commit(`common/${MUT_SET_COMMON_CODE_ITEMS}`, getCheckItems(res));
      });
    },
    showDaumPost(){
      if(this.postLoading) return;
      this.postLoading = true;
      this.$store.commit(`common/${MUT_SHOW_ADDRESS_MODAL}`, {
        size: 'md',
        okFunc: (a) => {
          this.params.zipNo = a.zonecode;
          this.params.bscAddr = a.address;

          this.postLoading = false;
        },
        cancelFunc: () => {this.postLoading = false}
      });
    },
    clickSave(){
      if(this.isLoading) return;
      this.isLoading = true;

      this.params.mblNo = this.mblNo.replace('-','').replace('-','');
      this.params.telNo = this.telNo.replace('-','').replace('-','');
      this.params.ctrEmail = this.ctrEmail;

      if(validate(this.$store, this.params, [
        {key: 'ctrNm', msg: '인플루언서 이름을 입력해주세요.'},
        {key: 'ctrActvtNm', msg: '인플루언서 활동명을 입력해주세요.'},
        {key: 'mblNo', msg: '휴대폰 번호를 입력해주세요.'},
        {key: 'ctrEmail', msg: '이메일을 입력해주세요.'},
        {key: 'gndrCd', msg: '성별를 선택해주세요.'},
        {key: 'ytbChnlCtgrTypCd', msg: '유튜브 카테고리를 선택해주세요.'},
        {key: 'ytbChnlUrl', msg: '유튜브 URL를 입력해주세요.'},
        {key: 'ytbChnlNm', msg: '유튜브 채널명을 입려해주세요.'},
      ])) {
        this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
          title: `인플루언서 ${this.creatorText} 하시겠습니까?`,
          showCancelButton: true,
          yesfunc: this.saveCreator,
          nofunc: () => {this.isLoading = false;},
        });
      } else {
        this.isLoading = false;
      }

    },
    saveCreator(){
      this.$store.dispatch(`auth/${ACT_SAVE_CREATOR}`, this.params).then(res=>{
        if(isSuccess(res)){
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
            title: `인플루언서 ${this.creatorText}이 완료 되었습니다.`,
            yesfunc: () => {
              this.reloadSession();
              this.closeModal();
            },
            rightNow: true
          });
        }else {
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '오류가 발생했습니다. 다시 시도해주세요.'});
          this.isLoading = false;
        }
      })

    },
    closeModal() {
      this.$store.commit(`auth/${MUT_CLOSE_CREATOR_CHANGE_MODAL}`);
    },
    reloadSession(){
      this.$store.dispatch(`auth/${ACT_GET_SESSION}`).then(res => {
        if (lengthCheck(res)) {
          this.$store.commit(`auth/${MUT_SET_SESSION}`, getItem(res));
        }
      });
    }
  }
};
</script>