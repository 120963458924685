import Vue from 'vue';
import VueRouter from 'vue-router';
import mobileUtils from '../assets/js/navigationUtils';
import MainLayout from '../views/layout/MainLayout.vue';
import MainFullLayout from '../views/layout/FullpageLayout.vue';
import PopupLayout from '../views/layout/PopupLayout.vue';
import LiveViewLayout from '../views/layout/LiveViewLayout.vue';
import MobileRoomView from '../views/mobile/pages/studio/RoomView';
import RoomView from '../views/pages/studio/RoomView';
import MobileCommunityView from '../views/mobile/pages/community/CommunityView';
import CommunityView from '../views/pages/community/CommunityView';
import MobileCampaignView from '../views/mobile/pages/promotion/CampaignView';
import CampaignView from '../views/pages/promotion/CampaignView';
import MobileFootholdView from '../views/mobile/pages/community/FootholdView';
import FootholdView from '../views/pages/community/FootholdView';
import SupportView from '../views/pages/support/SupportView';
import MobileSupportView from '../views/mobile/pages/support/SupportView';

import store from '../store';
import { ACT_CHECK_AUTH_RES } from '../store/_act_consts';
import { MUT_SET_AUTH_RES } from '../store/_mut_consts';
import fullpageLayout from '@/views/layout/FullpageLayout.vue';

Vue.use(VueRouter);

const isMobile = !!mobileUtils.any();

const routes = [
	// Fullpage 용 별도 레이아웃
	{
		path: '/',
		component: MainFullLayout,
		children: [
			// [DEV]퍼블 : 메인페이지 리뉴얼 Fullpage
			{
				path: '',
				name: 'Main',
				component: () => import('../views/pages/Main'),
			},
		],
	},
	{
		path: '/',
		// component: () => import('../views/layout/MainLayout'),
		component: MainLayout,
		children: [
			// {
			// 	path: '',
			// 	name: 'Main',
			// 	component: () => import('../views/pages/Main'),
			// },
			{
				path: 'mypage/:path',
				name: 'MyPage',
				meta: {
					title: '마이페이지',
				},
				component: () => import('../views/pages/auth/MyPage'),
				// beforeEnter: (to, from, next) => {
				//   next();
				// }
			},
			{
				path: 'community',
				name: 'Community',
				meta: {
					title: '온라인 진출 지원사례',
				},
				component: () => import('../views/pages/community/Community'),
			},
			{
				path: 'community/:comunBltartclId/view',
				name: 'CommunityView',
				meta: {
					title: '온라인 진출 지원사례',
				},
				component: isMobile ? MobileCommunityView : CommunityView,
			},
			{
				path: 'community/:comunBltartclId/edit',
				name: 'CommunityEdit',
				component: () => import('../views/pages/community/CommunityEdit'),
			},

			{
				path: 'foothold',
				name: 'Foothold',
				component: () => import('../views/pages/community/Foothold'),
			},
			{
				path: 'foothold/:ctrFthldId/view',
				name: 'FootholdView',
				component: isMobile ? MobileFootholdView : FootholdView,
			},
			{
				path: 'notice',
				name: 'Notice',
				mete: {
					title: '공지사항',
				},
				component: () => import('../views/pages/community/Notice'),
			},
			{
				path: 'notice/:ntcId/view',
				name: 'NoticeView',
				meta: {
					title: '공지사항',
				},
				component: () => import('../views/pages/community/NoticeView'),
			},
			{
				path: 'playground',
				name: 'Playground',
				meta: {
					title: '소상공인 뉴스',
				},
				component: () => import('../views/pages/information/Playground'),
			},
			{
				path: 'playground/:plygrndId/view',
				name: 'PlaygroundView',
				meta: {
					title: '소상공인 뉴스',
				},
				component: () => import('../views/pages/information/PlaygroundView'),
			},
			{
				path: 'issue',
				name: 'Issue',
				meta: {
					title: '소상공인 포스트',
				},
				component: () => import('../views/pages/information/Issue'),
			},
			{
				path: 'broadcast',
				name: 'BroadCast',
				meta: {
					title: '판로TV',
				},
				component: () => import('../views/pages/broadcast/Broadcast'),
			},
			{
				path: 'broadcast/:brcInfoNo/view',
				name: 'VodView',
				meta: {
					title: 'Vod 다시보기',
				},
				component: () => import('../views/pages/broadcast/VodView'),
			},
			{
				path: 'broadcast/shorts/:brcInfoNo/view',
				name: 'ShortFormView',
				meta: {
					title: '하이라이트 다시보기',
				},
				component: () => import('../views/pages/broadcast/ShortFormView'),
			},
			{
				path: 'issue/:issueId/view',
				name: 'IssueView',
				meta: {
					title: '소상공인 포스트',
				},
				component: () => import('../views/pages/information/IssueView'),
			},
			{
				path: 'issue2',
				name: 'Issue2',
				meta: {
					title: '소상공인 이야기',
				},
				component: () => import('../views/pages/information/Issue2'),
			},
			{
				path: 'interview',
				name: 'Interview',
				meta: {
					title: '소상공인 인터뷰',
				},
				component: () => import('../views/pages/information/Interview'),
			},
			{
				path: 'interview/:interviewId/view',
				name: 'InterviewView',
				meta: {
					title: '소상공인 인터뷰',
				},
				component: () => import('../views/pages/information/InterviewView'),
			},
			{
				path: 'playground2',
				name: 'Playground2',
				meta: {
					title: '소담스퀘어',
				},
				component: () => import('../views/pages/information/Playground2'),
			},
			{
				path: 'playground2/:plygrndId/view',
				name: 'Playground2View',
				meta: {
					title: '소담스퀘어',
				},
				component: () => import('../views/pages/information/Playground2View'),
			},
			{
				path: 'playground3',
				name: 'Playground3',
				component: () => import('../views/pages/information/Playground3'),
			},
			{
				path: 'playground3/:plygrndId/view',
				name: 'Playground3View',
				component: () => import('../views/pages/information/Playground3View'),
			},
			{
				path: 'campaign',
				name: 'Campaign',
				component: () => import('../views/pages/promotion/Campaign'),
			},
			{
				path: 'campaign/:matchCampnId/view',
				name: 'CampaignView',
				component: isMobile ? MobileCampaignView : CampaignView,
			},
			{
				path: 'studio',
				name: 'Studio',
				meta: {
					title: '라이브 스튜디오',
				},
				component: () => import('../views/pages/studio/Studio'),
			},
			{
				path: 'sodam',
				name: 'Sodam',
				meta: {
					title: '소담상회',
				},
				component: () => import('../views/pages/sodam/Sodam'),
			},
			{
				path: 'studio/room/:roomId/:studoId',
				name: 'RoomView',
				meta: {
					title: '라이브 스튜디오',
				},
				// component: () => import('../views/pages/studio/RoomView')
				component: isMobile ? MobileRoomView : RoomView,
			},
			{
				path: 'education',
				name: 'Eduction',
				meta: {
					title: '인플루언서 교육수강',
				},
				component: () => import('../views/pages/education/Education'),
			},
			{
				path: 'educationpre',
				name: 'EductionPre',
				meta: {
					title: '21년 온라인 진출 배움터 비회원 맛보기 강좌',
				},
				component: () => import('../views/pages/education/EducationPre'),
			},
			{
				path: 'educationapply',
				name: 'EductionApply',
				meta: {
					title: '22년 소상공인 인플루언서 교육 기본・심화 정규코스',
				},
				component: () => import('../views/pages/education/EducationApply'),
			},
			{
				path: 'support',
				name: 'Support',
				meta: {
					title: '중소벤처기업부',
				},
				component: () => import('../views/pages/support/Support'),
			},
			{
				path: 'support1',
				name: 'Support1',
				meta: {
					title: '중앙부처',
				},
				component: () => import('../views/pages/support/Support1'),
			},
			{
				path: 'support2',
				name: 'Support2',
				meta: {
					title: '지자체',
				},
				component: () => import('../views/pages/support/Support2'),
			},
			{
				path: 'support/:sprtBizId/view',
				name: 'SupportView',
				meta: {
					title: '중소벤처기업부',
				},
				component: isMobile ? MobileSupportView : SupportView,
			},
			{
				path: 'search',
				name: 'Search',
				component: () => import('../views/pages/search/SearchResult'),
			},
			// [DEV] 퍼블 추가
			{
				path: 'search2',
				name: 'Search2',
				component: () => import('../views/pages/search/SearchResult2'),
			},
			{
				path: 'service/policy',
				name: 'Policy',
				component: () => import('../views/pages/service/Policy'),
			},
			// {
			// 	path: 'service/privacy',
			// 	name: 'Privacy',
			// 	component: () => import('../views/pages/service/Privacy'),
			// },
			{
				path: 'service/privacy-policy',
				name: 'PrivacyPolicy',
				component: () => import('../views/pages/service/PrivacyPolicy'),
			},
			{
				path: 'service/freeedudetail',
				name: 'FreeEduDetail',
				component: () => import('../views/pages/service/FreeEduDetail'),
			},
			{
				path: 'self',
				name: 'Self',
				meta: {
					title: '셀프페이지',
				},
				component: () => import('../views/pages/self/Self'),
			},
			{
				path: 'guide',
				name: 'Guide',
				meta: {
					title: '가이드페이지',
				},
				component: () => import('../views/pages/guide/Guide'),
			},

			{
				path: 'scommunity',
				name: 'ScommunityMain',
				meta: {
					title: '소상공인 커뮤니티 메인',
				},
				component: () => import('../views/pages/scommunity/Main'),
			},
			{
				path: 'scommunity/:cmntId',
				name: 'ScommunityHome',
				meta: {
					title: '소상공인 커뮤니티 홈',
				},
				component: () => import('../views/pages/scommunity/Home'),
			},
			{
				path: 'scommunity/:cmntId/search',
				name: 'ScommunityHomeSearch',
				meta: {
					title: '소상공인 커뮤니티 검색',
				},
				component: () => import('../views/pages/scommunity/Search'),
			},
			// [DEV]퍼블 : 소상공인 커뮤니티
			{
				path: 'scommunity',
				name: 'ScommunityMainPub',
				meta: {
					title: '소상공인 커뮤니티 메인',
				},
				component: () => import('../views/pages/scommunity/Main_Pub'),
			},
			{
				path: 'scommunity/search',
				name: 'ScommunityMainSearch',
				meta: {
					title: '소상공인 커뮤니티 메인 검색결과',
				},
				component: () => import('../views/pages/scommunity/MainSearch'),
			},
			{
				path: 'scommunity/detail',
				name: 'ScommunityDetail',
				meta: {
					title: '소상공인 커뮤니티 상세',
				},
				component: () => import('../views/pages/scommunity/Detail'),
			},
			{
				path: 'scommunity/detail/search',
				name: 'ScommunityDetailSearch',
				meta: {
					title: '소상공인 커뮤니티 상세 검색',
				},
				component: () => import('../views/pages/scommunity/DetailSearch'),
			},
			{
				path: 'scommunity/bbs/notice',
				name: 'ScommunityBbsList',
				meta: {
					title: '소상공인 커뮤니티 공지사항',
				},
				component: () => import('../views/pages/scommunity/BbsList'),
			},
			{
				path: 'scommunity/bbs/notice/detail',
				name: 'ScommunityBbsDetail',
				meta: {
					title: '소상공인 커뮤니티 공지사항 상세',
				},
				component: () => import('../views/pages/scommunity/BbsDetail'),
			},
			{
				path: 'scommunity/bbs/data',
				name: 'ScommunityBbsDataList',
				meta: {
					title: '소상공인 커뮤니티 자료실',
				},
				component: () => import('../views/pages/scommunity/BbsDataList'),
			},
			{
				path: 'scommunity/bbs/data/detail',
				name: 'ScommunityBbsDataDetail',
				meta: {
					title: '소상공인 커뮤니티 자료실 상세',
				},
				component: () => import('../views/pages/scommunity/BbsDataDetail'),
			},
			{
				path: 'mypage2/:path',
				name: 'MyPage2',
				meta: {
					title: '마이페이지',
				},
				component: () => import('../views/pages/mypage2/MyPage'),
			},
			{
				path: 'supportbiz/intrd/:intrdId?',
				name: 'supportBizIntrd',
				meta: {
					title: '소상공인 온라인 판로지원사업 홈',
				},
				component: () => import('../views/pages/support/SupportBizIntrdHome'),
			},
			{
				path: 'supportbiz',
				name: 'supportBiz',
				meta: {
					title: '소상공인 지원 사업',
				},
				component: () => import('../views/pages/support/SuptBiz'),
			},
			{
				path: 'supportbiz/intrd/:intrdId/exms',
				name: 'supportBizIntrdExmList',
				meta: {
					title: '소상공인 온라인 판로지원사업 목록',
				},
				component: () => import('../views/pages/support/SupportBizIntrdExmList'),
			},
			{
				path: 'supportbiz/intrd/:intrdId/exm/:exmId',
				name: 'supportBizIntrdExmDetail',
				meta: {
					title: '소상공인 온라인 판로지원사업 상세',
				},
				component: () => import('../views/pages/support/SupportBizIntrdExmDetail'),
			},
			// [DEv] 라이브홍보
			{
				path: 'live/list',
				name: 'LiveList',
				meta: {
					title: '라이브홍보 목록',
				},
				component: () => import('../views/pages/live/LiveList'),
			},
			{
				path: 'live/schedule',
				name: 'LiveSchedule',
				meta: {
					title: '라이브홍보 방송편성표',
				},
				component: () => import('../views/pages/live/LiveSchedule'),
			},
			{
				path: 'live/coming/:brcInfoNo',
				name: 'LiveComing',
				meta: {
					title: '라이브홍보 Coming Soon',
				},
				component: () => import('../views/pages/live/LiveComing'),
			},
			// {
			// 	path: 'live/view/:brcInfoNo',
			// 	name: 'LiveView',
			// 	meta: {
			// 		title: '라이브홍보 상세',
			// 	},
			// 	component: () => import('../views/pages/live/LiveView'),
			// },
			{
				path: 'live/vod/:brcInfoNo',
				name: 'LiveVodView',
				meta: {
					title: '라이브홍보 VOD',
				},
				component: () => import('../views/pages/live/LiveVodView'),
			},
			{
				path: 'promotion/subscription',
				name: 'SubscriptionCenter',
				meta: {
					title: '정기구독관',
				},
				component: () => import('../views/pages/promotion/SubscriptionCenter'),
			},
			{
				path: 'promotion/localeconomic',
				name: 'LocalEconomicCenter',
				meta: {
					title: '지역경제관',
				},
				component: () => import('../views/pages/promotion/LocalEconomicCenter'),
			},
			// */

			// [DEV]퍼블 : 통합회원가입 20231108
			{
				path: 'join',
				name: 'Join',
				meta: {
					title: '회원 유형 선택',
				},
				component: () => import('../views/pages/user/Join'),
			},
			{
				path: 'join/test/main',
				name: 'JoinTestMain',
				meta: {
					title: 'SSO Test Main',
				},
				component: () => import('../views/pages/user/JoinTestMain.vue'),
			},
			{
				path: 'join/test/login',
				name: 'JoinTestLogin',
				meta: {
					title: 'SSO Test Login',
				},
				component: () => import('../views/pages/user/JoinTestLogin.vue'),
			},
			{
				path: 'join/test/trans',
				name: 'JoinTestTrans',
				meta: {
					title: 'SSO Test Trans',
				},
				component: () => import('../views/pages/user/JoinTestTrans.vue'),
			},
			{
				path: 'join/integrated',
				name: 'JoinIntegrated',
				meta: {
					title: '통합회원 약관 동의',
				},
				component: () => import('../views/pages/user/JoinIntegrated'),
			},
			{
				path: 'join/integrated/certificate',
				name: 'JoinIntegratedCert',
				meta: {
					title: '통합회원 개인사업자 가입 - 기업회원 인증',
				},
				component: () => import('../views/pages/user/JoinIntegratedCert'),
			},
			{
				path: 'join/integrated/form',
				name: 'JoinIntegratedForm',
				meta: {
					title: '통합회원 개인사업자 가입 - 회원정보 입력',
				},
				component: () => import('../views/pages/user/JoinIntegratedForm'),
			},
			{
				path: 'join/integrated/complete',
				name: 'JoinIntegratedComplete',
				meta: {
					title: '통합회원 개인사업자 가입 - 회원가입 완료',
				},
				component: () => import('../views/pages/user/JoinIntegratedComplete'),
			},
			{
				path: 'join/general',
				name: 'JoinGeneral',
				meta: {
					title: '일반회원 약관 동의',
				},
				component: () => import('../views/pages/user/JoinGeneral'),
			},
			{
				path: 'join/general/form',
				name: 'JoinGeneralForm',
				meta: {
					title: '일반회원 회원정보 입력',
				},
				component: () => import('../views/pages/user/JoinGeneralForm'),
			},
			{
				path: 'join/general/complete',
				name: 'JoinGeneralComplete',
				meta: {
					title: '일반회원 회원가입 완료',
				},
				component: () => import('../views/pages/user/JoinGeneralComplete'),
			},
			{
				path: 'join/loading',
				name: 'JoinLoading',
				meta: {
					title: '통합 로그인 진행중',
				},
				component: () => import('../views/pages/user/JoinLoading'),
			},
			{
				path: 'convert-membership',
				name: 'ConvertMembership',
				meta: {
					title: '통합기업회원 전환 - 기업회원 인증',
				},
				component: () => import('../views/pages/user/ConvertMembership'),
			},
			{
				path: 'convert-membership/form',
				name: 'ConvertMembershipForm',
				meta: {
					title: '통합기업회원 전환 - 회원정보 입력',
				},
				component: () => import('../views/pages/user/ConvertMembershipForm'),
			},
			{
				path: 'convert-membership/complete',
				name: 'ConvertMembershipComplete',
				meta: {
					title: '통합기업회원 전환 - 회원가입 완료',
				},
				component: () => import('../views/pages/user/ConvertMembershipComplete'),
			},

			// [DEV] 퍼블
			{
				path: 'information/home',
				name: 'InformationHome',
				meta: {
					title: '소상공인 뉴스 홈',
				},
				component: () => import('../views/pages/information/InfoHome'),
			},
			{
				path: 'information/list',
				name: 'InformationList',
				meta: {
					title: '소상공인 콘텐츠 목록',
				},
				component: () => import('../views/pages/information/InfoList'),
			},
			{
				path: 'information/detail',
				name: 'InformationDetail',
				meta: {
					title: '소상공인 콘텐츠 상세',
				},
				component: () => import('../views/pages/information/InfoDetail'),
			},
			{
				path: 'support/smb-sales',
				name: 'SupportSmbSalesHome',
				meta: {
					title: '소상공인 온라인 판로지원사업 홈',
				},
				component: () => import('../views/pages/support/SupportSmbSalesHome'),
			},
			{
				path: 'support/smb-sales/list',
				name: 'SupportSmbSalesList',
				meta: {
					title: '소상공인 온라인 판로지원사업 목록',
				},
				component: () => import('../views/pages/support/SupportSmbSalesList'),
			},
			{
				path: 'support/smb-sales/detail',
				name: 'SupportSmbSalesDetail',
				meta: {
					title: '소상공인 온라인 판로지원사업 상세',
				},
				component: () => import('../views/pages/support/SupportSmbSalesDetail'),
			},

			// [DEV]퍼블 : 소상공인 커뮤니티
			{
				path: 'service/onfan',
				name: 'OnFan',
				meta: {
					title: '온판 플랫폼 소개',
				},
				component: () => import('../views/pages/service/OnFan'),
			},
			//이벤트용 iframe메뉴페이지 주석처리(20221223 hib)
			/* {
        path: 'event1',
        name: 'Event1',
        meta: {
          title: "국민MD 이벤트",
        },
        component: () => import('../views/pages/event/Event1')
      },
      {
        path: 'event2',
        name: 'Event2',
        meta: {
          title: "누리집",
        },
        component: () => import('../views/pages/event/Event2')
      }, */

			{
				path: 'sentcont/home',
				name: 'SentContHome',
				meta: {
					title: '소상공인 뉴스 홈',
				},
				component: () => import('../views/pages/sentcont/SentContHome'),
			},
			{
				path: 'sentcont/:sentContCtgrDcd/list',
				name: 'SentContList',
				meta: {
					title: '소상공인 콘텐츠 목록',
				},
				component: () => import('../views/pages/sentcont/SentContList'),
			},
			{
				path: 'sentcont/:sentContCtgrDcd/view/:sentContId',
				name: 'SentContDetail',
				meta: {
					title: '소상공인 콘텐츠 상세',
				},
				component: () => import('../views/pages/sentcont/SentContDetail'),
			},
			// {
			// 	path: 'join/dhfesta',
			// 	name: 'JoinDhFesta',
			// 	meta: {
			// 		title: '동행축제 일반회원 약관 동의',
			// 	},
			// 	component: () => import('../views/pages/user/JoinDhFesta'),
			// },
			// {
			// 	path: 'join/dhfesta/form',
			// 	name: 'JoinDhFestaForm',
			// 	meta: {
			// 		title: '동행축제 일반회원 회원정보 입력',
			// 	},
			// 	component: () => import('../views/pages/user/JoinDhFestaForm'),
			// },
			// {
			// 	path: 'join/dhfesta/complete',
			// 	name: 'JoinDhFestaComplete',
			// 	meta: {
			// 		title: '동행축제 일반회원 회원가입 완료',
			// 	},
			// 	component: () => import('../views/pages/user/JoinDhFestaComplete'),
			// },
			// [[ DEV ]] 퍼블 : 자가진단 서비스
			{
				path: 'self-diagnosis',
				name: 'SelfDiagnosisStart',
				meta: {
					title: '자가진단 맞춤정보 정보',
				},
				component: () => import('../views/pages/selfdx/SelfDiagnosisStart'),
			},
			{
				path: 'self-diagnosis/input',
				name: 'SelfDiagnosisInput',
				meta: {
					title: '자가진단 맞춤정보 정보 입력',
				},
				component: () => import('../views/pages/selfdx/SelfDiagnosisInput'),
			},
			{
				path: 'self-diagnosis/result',
				name: 'SelfDiagnosisResult',
				meta: {
					title: '자가진단 맞춤정보 결과',
				},
				component: () => import('../views/pages/selfdx/SelfDiagnosisResult'),
			},
		],
	},
	{
		path: '/',
		component: () => import('../views/layout/LoginLayout'),
		children: [
			{
				path: 'login',
				name: 'Login',
				component: () => import('../views/pages/auth/Login'),
			},
			{
				path: 'signup',
				name: 'Signup',
				component: () => import('../views/pages/auth/Signup'),
				// beforeEnter: (to, from, next) => {
				// 	const sectionId = to.query.section;

				// 	if (from.name == 'Main' && sectionId == 'sectionMyPageId') {
				// 		console.log('메인페이지 > 마이페이지 Section을 통해서 접속');
				// 		next();
				// 	} else {
				// 		next();
				// 	}
				// }
			},
			{
				path: 'signup/complete',
				name: 'SignupComplete',
				component: () => import('../views/pages/auth/SignupComplete'),
			},
			{
				path: 'findInfo/:division',
				name: 'FindInfo',
				component: () => import('../views/pages/auth/FindInfo'),
				beforeEnter: (to, from, next) => {
					if (to.params.division !== 'id' && to.params.division !== 'password') {
						next({ name: 'Main' });
					}
					next();
				},
			},
			{
				path: 'findChoice/:division',
				name: 'FindChoice',
				component: () => import('../views/pages/auth/ChoiceFindInfo'),
				// beforeEnter: (to, from, next) => {
				// 	if (to.params.division !== 'id' && to.params.division !== 'password') {
				// 		next({ name: 'Main' });
				// 	}
				// 	next();
				// },
			},
			{
				path: 'dhfindInfo/:division',
				name: 'DhFindInfo',
				component: () => import('../views/pages/auth/DhFindInfo'),
				beforeEnter: (to, from, next) => {
					if (to.params.division !== 'id' && to.params.division !== 'password') {
						next({ name: 'Main' });
					}
					next();
				},
			},
			{
				path: 'join/dhfesta',
				name: 'JoinDhFesta',
				meta: {
					title: '동행축제 일반회원 약관 동의',
				},
				component: () => import('../views/pages/user/JoinDhFesta'),
			},
			{
				path: 'join/dhfesta/form',
				name: 'JoinDhFestaForm',
				meta: {
					title: '동행축제 일반회원 회원정보 입력',
				},
				component: () => import('../views/pages/user/JoinDhFestaForm'),
			},
			{
				path: 'join/dhfesta/complete',
				name: 'JoinDhFestaComplete',
				meta: {
					title: '동행축제 일반회원 회원가입 완료',
				},
				component: () => import('../views/pages/user/JoinDhFestaComplete'),
			},
		],
	},
	// 라이브홍보 라이브 뷰용 별도 레이아웃
	{
		path: '/',
		component: LiveViewLayout,
		children: [
			{
				path: 'live/:brcInfoNo/view',
				name: 'LiveView',
				meta: {
					title: '라이브홍보 상세',
				},
				component: () => import('../views/pages/live/LiveView'),
			},
		],
	},
	// Fullpage 용 별도 레이아웃
	{
		path: '/',
		component: fullpageLayout,
		children: [
			{
				path: 'renew',
				name: 'renew',
				component: () => import('../views/pages/Renew'),
			},
		],
	},
	// HTML 챗봇 팝업용 별도 레이아웃
	{
		path: '/',
		component: PopupLayout,
		children: [
			{
				path: 'chatbot',
				name: 'ChatBot',
				component: () => import('../views/pages/chatbot/ChatBot'),
			},
		],
	},
];

function acecount() {
	var _AceGID = (function () {
		var Inf = ['gtp12.acecounter.com', '8080', 'AH6A45756391491', 'AW', '0', 'NaPm,Ncisy', 'ALL', '0'];
		var _CI = !_AceGID ? [] : _AceGID.val;
		var _N = 0;
		var _T = new Image(0, 0);
		if (_CI.join('.').indexOf(Inf[3]) < 0) {
			_T.src = 'https://' + Inf[0] + '/?cookie';
			_CI.push(Inf);
			_N = _CI.length;
		}
		return { o: _N, val: _CI };
	})();
	var _AceCounter = (function () {
		var G = _AceGID;
		var _sc = document.createElement('script');
		var _sm = document.getElementsByTagName('script')[0];
		if (G.o != 0) {
			var _A = G.val[G.o - 1];
			var _G = _A[0].substr(0, _A[0].indexOf('.'));
			var _C = _A[7] != '0' ? _A[2] : _A[3];
			var _U = _A[5].replace(/\,/g, '_');
			_sc.src = 'https:' + '//cr.acecounter.com/Web/AceCounter_' + _C + '.js?gc=' + _A[2] + '&py=' + _A[4] + '&gd=' + _G + '&gp=' + _A[1] + '&up=' + _U + '&rd=' + new Date().getTime();
			_sm.parentNode.insertBefore(_sc, _sm);
			return _sc.src;
		}
	})();
}

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		//페이지 뒤로가기, 앞으로가기시 스크롤 위치유지
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve({ left: 0, top: 0 });
			}, 500);
		});

		// if (savedPosition) {
		// 	return savedPosition
		// } else {
		// 	return { x: 0, y: 0 }
		// }
	},
});

let tryCount = 0;

const getAuthRes = (next) => {
	if (tryCount > 10) {
		next();
		return;
	}
	store.dispatch(`auth/${ACT_CHECK_AUTH_RES}`).then((res) => {
		if (res === undefined) {
			tryCount++;
			setTimeout(() => {
				getAuthRes(next);
			}, 300);
		} else {
			store.commit(`auth/${MUT_SET_AUTH_RES}`);
			next();
		}
	});
};

router.beforeEach((to, from, next) => {
	setTimeout(() => {
		window.scrollTo(0, 0);
		acecount();
	}, 100);
	if (store.state.auth.resValidate) {
		next();
	} else {
		getAuthRes(next);
	}
});

const makeTitle = (title) => (title ? `${title} | 가치삽시다` : `가치삽시다`);

router.afterEach((to, from) => {
	Vue.nextTick(() => {
		document.title = makeTitle(to.meta.title);
	});

	// GTM 데이터레이어에 페이지뷰 이벤트 푸시
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: 'pageview',
		page: to.path,
	});
});

export default router;
