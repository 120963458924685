<template>
  <div class="layer-popup-container gachi-layer-popup-container">
    <div class="layer-popup" id="popup-mypage-review">
      <div>
        <div class="popup-wrapper">
          <header class="popup-header">
            <h3 class="header-title">후기쓰기</h3>
            <button type="button" class="btn-close" @click="closeModal">
              <i class="icon-close">팝업닫기</i>
            </button>
          </header>
          <div class="popup-body">
            <div class="mypage-review-rating">
              <div class="top-rating rating-wrapper rating-lg">
                <div class="rating-title">
                  <span class="text">만족도</span>
                </div>
                <div class="rating">
                  <button class="start-btn" type="button" @click="setStarRateScore(1)">
                    <span class="star" :class="{'is-active': starrtScore > 0}"></span>
                  </button>
                  <button class="start-btn" type="button" @click="setStarRateScore(2)">
                    <span class="star" :class="{'is-active': starrtScore > 1}"></span>
                  </button>
                  <button class="start-btn" type="button" @click="setStarRateScore(3)">
                    <span class="star" :class="{'is-active': starrtScore > 2}"></span>
                  </button>
                  <button class="start-btn" type="button" @click="setStarRateScore(4)">
                    <span class="star" :class="{'is-active': starrtScore > 3}"></span>
                  </button>
                  <button class="start-btn" type="button" @click="setStarRateScore(5)">
                    <span class="star" :class="{'is-active': starrtScore > 4}"></span>
                  </button>
                </div>
              </div>
            </div>
            <div class="mypage-review-form">
              <textarea v-model="revwCmnt" cols="30" rows="10" class="form-control" maxlength="150" placeholder="스튜디오 잘 활용하셨나요? 후기로 다른 소상공인분들께 도움을 주세요."></textarea>
            </div>
          </div>
          <div class="popup-buttons">
            <button class="btn btn-light btn-lg" @click="closeModal">
              <span class="text">취소</span>
            </button>
            <button class="btn btn-primary btn-lg" :disabled="revwCmnt.trim().length === 0" @click="clickSave">
              <span class="text">등록</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {MUT_CLOSE_REVIEW_MODAL, MUT_SHOW_ALERT, MUT_SHOW_CONFIRM} from '../../store/_mut_consts';
import {mapGetters} from 'vuex';
import {ACT_INSERT_STUDIO_REVIEW} from '../../store/_act_consts';
import {getResult, isSuccess} from '../../assets/js/utils';

export default {
  name: 'Review',
  data: () => ({
    revwCmnt: '',
    starrtScore: 0,
    isLoading: false
  }),
  computed:{
    ...mapGetters('studio', ['review']),
  },
  methods: {
    closeModal(){
      this.$store.commit(`studio/${MUT_CLOSE_REVIEW_MODAL}`);
    },
    setStarRateScore(score){
      this.starrtScore = score;
    },
    clickSave(){
      if(this.isLoading) return;
      this.isLoading = true;

      this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
        title: `후기를 등록하시겠습니까?`,
        showCancelButton: true,
        yesfunc: this.insertReview,
        nofunc: () => {this.isLoading = false;},
      });
    },
    insertReview(){
      this.$store.dispatch(`studio/${ACT_INSERT_STUDIO_REVIEW}`, {
        roomId: this.review.roomId,
        params: {
          rsvtId: this.review.rsvtId,
          revwCmnt: this.revwCmnt,
          starrtScore: this.starrtScore
        }
      }).then(res => {
        this.isLoading = false;
        if(isSuccess(res)){
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
            title: `후기가 등록되었습니다.`,
            yesfunc: () => {
              if(this.review.afterFunc instanceof Function) this.review.afterFunc();
              this.closeModal();
            },
            rightNow: true
          });
        }else{
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: getResult(res).message});
        }
      }).catch(e => {
        console.error(e);
        this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '오류가 발생했습니다. 다시 시도해주세요.'});
        this.isLoading = false;
      });
    }
  }
};
</script>
