<template>
  <div class="review-wrapper">
    <ul class="review-list">
      <li v-for="(item, idx) in items" class="review-item" :key="idx">
        <article class="review-card">
          <div class="review-row">
            <div class="review-inner">
              <div class="review-top">
                <div class="top-rating rating-wrapper rating-sm">
                  <div class="rating">
                    <span class="star" :class="{'is-active': item.starrtScore > 0}"></span>
                    <span class="star" :class="{'is-active': item.starrtScore > 1}"></span>
                    <span class="star" :class="{'is-active': item.starrtScore > 2}"></span>
                    <span class="star" :class="{'is-active': item.starrtScore > 3}"></span>
                    <span class="star" :class="{'is-active': item.starrtScore > 4}"></span>
                  </div>
                  <div class="rating-text">
                    <span class="text">{{ item.starrtScore.toFixed(1) }}</span>
                  </div>
                </div>
                <div class="top-option">
                  <span class="text">{{ studioNm }} — {{ item.roomNm }}</span>
                </div>
              </div>
              <div class="review-body">
                <p class="text">
                  {{ item.revwCmnt }}
                </p>
              </div>
              <div class="review-bottom">
                <div class="bottom-reviewer">
                  <span class="text">{{ item.lgnId }}</span>
                </div>
                <div class="bottom-date">
                  <span class="text">{{ getTimestampToFormat(item.regDt) }}</span>
                </div>
              </div>
            </div>
          </div>
        </article>
      </li>
    </ul>
  </div>
</template>

<script>
import {timestampToDateFormat} from '../../assets/js/utils';

export default {
  name: 'ReviewComment',
  props: {
    studioNm: String,
    items: Array
  },
  methods:{
    getTimestampToFormat(timestamp){
      return timestampToDateFormat(timestamp, 'yyyy.MM.dd')

    }
  }

};
</script>

