import {ACT_GET_NOTICE} from '../_act_consts';
import ApiService from '../../assets/js/api.service';
import {
  MUT_CLOSE_COMMON_NOTICE_MODAL,
  MUT_SHOW_COMMON_NOTICE_MODAL,
  MUT_CLOSE_EDU_NOTICE_MODAL,
  MUT_SHOW_EDU_NOTICE_MODAL,
  MUT_SHOW_SUPPORT_NOTICE_MODAL,
  MUT_CLOSE_SUPPORT_NOTICE_MODAL
} from '../_mut_consts';

const state = {
  notice: {
    show: false,
    //ntcId: 0,
    //ntcTit:'',
    //ntcUrl:'',
    //ntcImgFilePtUrl:'',
  },
  eduNotice: {
    show: false,
  },
  supportNotice: {
    show: false,
  }
}

const getters = {
  commonNotice(state) {
    return state.notice;
  },
  eduNotice(state){
    return state.eduNotice;
  },
  supportNotice(state){
    return state.supportNotice;
  }
}

const mutations = {
  [MUT_SHOW_COMMON_NOTICE_MODAL](state, notice){
    state.notice.show = true;
    //state.notice.ntcId = notice.ntcId;
    //state.notice.ntcTit = notice.ntcTit;
    //state.notice.ntcUrl = notice.ntcUrl;
    //state.notice.ntcImgFilePtUrl = notice.ntcImgFilePtUrl;
  },
  [MUT_CLOSE_COMMON_NOTICE_MODAL](state, notice){
    state.notice.show = false;
  },
  [MUT_SHOW_EDU_NOTICE_MODAL](state, eduNotice){
    state.eduNotice.show = true;
  },
  [MUT_CLOSE_EDU_NOTICE_MODAL](state, eduNotice){
    state.eduNotice.show = false;
  },
  [MUT_SHOW_SUPPORT_NOTICE_MODAL](state, supportNotice){
    state.supportNotice.show = true;
  },
  [MUT_CLOSE_SUPPORT_NOTICE_MODAL](state, supportNotice){
    state.supportNotice.show = false;
  }
}

const actions = {
  [ACT_GET_NOTICE](){
    return new Promise(resolve => {
      ApiService.query('/v1/app/notice/ntcList').then(response => resolve(response));
    });
  }
}

export const notice = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
