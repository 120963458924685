export default function(CKEDITOR) {
  CKEDITOR.addCss(`iframe.gachi-nas-embed{width: 100%;}`);

  CKEDITOR.plugins.add( 'gachi-nas-embed', {
    icons: 'GachiNasEmbed',
    init: function( editor ) {
      editor.addCommand( 'nasInsertEmbed', new CKEDITOR.dialogCommand( 'nasInsertEmbed'));
      CKEDITOR.dialog.add( 'nasInsertEmbed', function( editor ) {
        return {
          title: 'NAS 링크 입력',
          minWidth: 300,
          minHeight: 50,
          onOk: function() {
            const link = CKEDITOR.tools.trim( this.getValueOf( 'info', 'nasLink' ) );
            if (link) {
              const sliders = CKEDITOR.dom.element.createFromHtml( `<video controls height="250px" width="100%"><source src="${link}" type="video/mp4"></video>`);
              editor.insertElement(sliders);
              editor.insertElement(CKEDITOR.dom.element.createFromHtml('<p></p>'));
            }
          },
          onShow: function() {
            var linkTextField = this.getContentElement( 'info', 'nasLink' );
            linkTextField.focus();
          },
          contents: [{
            id: 'info',
            accessKey: 'Y',
            elements: [{
              type: 'text',
              id: 'nasLink',
              label: 'NAS 영상 링크'
            }]
          }]
        };
      });

      editor.ui.addButton( 'GachiNasEmbed', {
        label: 'NAS 영상',
        command: 'nasInsertEmbed',
        icon: `${process.env.VUE_APP_OBJECT_STORAGE}/common/img/video-icon.png`
      });
    }
  });
}

