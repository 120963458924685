export const holyDay = {
  '20210101': '새해',
  '20210211': '설날',
  '20210212': '설날',
  '20210213': '설날',
  '20210301': '삼일절',
  '20210505': '어린이날',
  '20210519': '석가탄신일',
  '20210606': '현충일',
  '20210815': '광복절',
  '20210920': '추석',
  '20210921': '추석',
  '20210922': '추석',
  '20211003': '개천절',
  '20211009': '한글날',
  '20211225': '크리스마스',

  '20220101': '새해',
  '20220201': '설날',
  '20220202': '설날',
  '20220203': '설날',
  '20220301': '삼일절',
  '20220505': '어린이날',
  '20220508': '석가탄신일',
  '20220606': '현충일',
  '20220815': '광복절',
  '20220909': '추석',
  '20220910': '추석',
  '20220911': '추석',
  '20221003': '개천절',
  '20221009': '한글날',
  
  '20221212': ' ',
  '20221216': ' ',
  '20221219': ' ',
  '20221220': ' ',
  '20221221': ' ',
  '20221223': ' ',

  '20221225': '크리스마스',

  '20230101': '새해',
  '20230121': '설날',
  '20230122': '설날',
  '20230123': '설날',
  '20230124': '대체공휴일',
  '20230301': '삼일절',
  '20230505': '어린이날',
  '20230527': '석가탄신일',
  '20230606': '현충일',
  '20230815': '광복절',
  '20230928': '추석',
  '20230929': '추석',
  '20230930': '추석',
  '20231003': '개천절',
  '20231009': '한글날',
  '20231225': '크리스마스',

  '20240101': '새해',
  '20240209': '설날',
  '20240210': '설날',
  '20240211': '설날',
  '20240301': '삼일절',
  '20240505': '어린이날',
  '20240515': '석가탄신일',
  '20240606': '현충일',
  '20240815': '광복절',
  '20240916': '추석',
  '20240917': '추석',
  '20240918': '추석',
  '20241003': '개천절',
  '20241009': '한글날',
  '20241225': '크리스마스'
}