<template>
  <main class="page-container" id="sme-campaign-view">
    <div class="page-component">

      <div class="page-header screen-out">
        <div class="page-component">
          <div class="page-header-title">
            <h2 class="title">{{ params.matchCampnNm }}</h2>
          </div>
          <div class="page-header-hashtags">
            <p class="text">{{ deadline }}</p>
          </div>
        </div>
      </div>


      <div class="page-body">
        <div class="post-view-container">
          <section class="post-view-wrapper">
            <header class="post-view-header">
              <div class="header-category">
                <div class="badge">
                  <span class="badge-text">{{ categoryNm }}</span>
                </div>
              </div>
              <h3 class="header-title">{{ params.matchCampnNm }}</h3>
              <p class="header-tags">{{ deadline }}</p>
            </header>
            <div class="post-view-body">
              <aside class="post-aside">
                <div class="post-aside-wrapper">
                  <h4 class="post-aside-title">캠페인 안내</h4>
                  <div class="post-aside-content">
                    <p class="content-row">
                      <span class="content-title">응모기간</span>
                      <span class="content-text">{{ params.entryBgngYmd.replaceAll('-', '.') }} ~ {{ params.entryEndYmd.replaceAll('-', '.') }}</span>
                    </p>
                    <p class="content-row">
                      <span class="content-title">캠페인기간</span>
                      <span class="content-text">{{ params.matchCampnBgngYmd.replaceAll('-', '.') }} ~ {{ params.matchCampnEndYmd.replaceAll('-', '.') }}</span>
                    </p>
                  </div>
                  <div class="post-aside-buttons">
<!--                    <button class="btn btn-lg w-100" :class="{'btn-secondary': !isDeadline, 'btn-light': isDeadline, 'gachi-cursor-none': isDeadline}" :disabled="isDeadline || isLoading" @click="clickApply">-->
                    <login-check-button
                        :classes="['btn','btn-lg','w-100',{'btn-secondary': !isDeadline, 'btn-light': isDeadline, 'gachi-cursor-none': isDeadline}]"
                        :title="'팝업 열림'"
                        :disabled="isDeadline || isLoading"
                        :is-auth="isAuth"
                        :click-func="clickApply"
                    >
                      <span class="text">지원하기</span>
                      <span class="gachi-loader'" v-if="isLoading"></span>
                    </login-check-button>
<!--                    </button>-->
                  </div>
                </div>
              </aside>

              <div class="post-editor">
                <div class="gachi-view" v-html="params.matchCampnCn"></div>
              </div>

            </div>
          </section>
        </div>

        <div class="page-buttons">
          <router-link
              custom
              to="/campaign"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <button class="btn btn-lg btn-primary" @click="goList">
              <span class="text">목록</span>
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {mapGetters} from 'vuex';
import {ACT_GET_CAMPAIGN, ACT_GET_CATEGORY_LIST, ACT_INSERT_CAMPAIGN_APPLY} from '../../../store/_act_consts';
import {
  getCheckItems,
  getItem,
  getRegisterTimeFormat, getResult,
  isSuccess,
  lengthCheck,
  setParams,
} from '../../../assets/js/utils';
import {MUT_SET_CATEGORY_LIST, MUT_SHOW_ALERT, MUT_SHOW_CONFIRM} from '../../../store/_mut_consts';
import LoginCheckButton from '../../../components/common/LoginCheckButton';
export default {
  name: 'CampaignView',
  components: {
    LoginCheckButton
  },
  data: () => ({
    matchCampnId: 0,
    params: {
      matchCampnNm: '',
      matchCampnCn: '',
      entryEndDt: 0,
      entryBgngYmd: '',
      entryEndYmd: '',
      matchCampnBgngYmd: '',
      matchCampnEndYmd: '',
      gdsClsId: 0
    },
    nowTimestamp: 0,
    isLoading: false,
  }),
  computed:{
    ...mapGetters('promotion', ['categories']),
    ...mapGetters('auth', ['isAuth', 'session']),
    deadline(){
      return this.getEntryTimeFormat(this.params.entryEndDt)
    },
    categoryNm(){
      const filtered = this.categories.filter(f => this.params.gdsClsId === f.gdsClsId);
      if(filtered.length > 0) return filtered[0].clsNm;
      return '기타';
    },
    isDeadline(){
      return (this.params.entryEndDt < this.nowTimestamp);
    }

  },
  created() {
    this.nowTimestamp = new Date().getTime();
    if(this.$route.params.matchCampnId) this.matchCampnId = this.$route.params.matchCampnId;
    if(this.categories.length === 0) this.getCategoryList();
    if(this.matchCampnId > 0) this.getCampaign();
  },
  methods: {
    getCategoryList(){
      this.$store.dispatch(`promotion/${ACT_GET_CATEGORY_LIST}`, {clsLv: 1}).then(res => {
        this.$store.commit(`promotion/${MUT_SET_CATEGORY_LIST}`, getCheckItems(res));
      }).catch(e => {
        console.error(e);
      });
    },
    getCampaign() {
      this.$store.dispatch(`promotion/${ACT_GET_CAMPAIGN}`, this.matchCampnId).then(res=>{
        if(lengthCheck(res)){
          setParams(this.params, getItem(res));
        }
      }).catch(e=>{
        console.error(e);
      })
    },
    getEntryTimeFormat(entryEndDt){
      if(entryEndDt < this.nowTimestamp){
        return '신청 마감';
      }else{
        return `마감 ${getRegisterTimeFormat(this.nowTimestamp, entryEndDt)}`;
      }
    },
    clickApply(){
      if(this.isLoading) return;
      this.isLoading = true;

      this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
        title: `지원사업에 지원하시겠습니까?`,
        showCancelButton: true,
        yesfunc: this.applyCampaign,
        nofunc: () => {this.isLoading = false;},
      });

    },
    applyCampaign(){
      this.$store.dispatch(`promotion/${ACT_INSERT_CAMPAIGN_APPLY}`, {matchCampnId: this.matchCampnId}).then(res => {
        if(isSuccess(res)){
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '지원사업에 신청이 완료되었습니다.'});
        }else{
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: getResult(res).message});
        }
        this.isLoading = false;
      }).catch(e => {
        console.error(e);
        this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '오류가 발생했습니다. 다시 시도해주세요.'});
        this.isLoading = false;
      });

    },
    goList(){
      this.$router.push({name:'Campaign', query:{clsId: this.$route.query.clsId}});
    }
  }
};
</script>
