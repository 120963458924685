<template>
    <div class="search-top container">
        <div class="page-component">
            <h2 class="search-header">통합검색</h2>
            <div class="search-field">
                <input v-model="searchTerm" type="text" class="form-control" placeholder="무엇이든 검색해보세요!" @keyup.enter="search" />
                <button v-if="!isMobile" class="search-button" type="button" @click="search">
                    <i class="icon-search-total"></i>
                    <span class="sr-only">검색</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { MUT_CLOSE_SEARCH_MODAL, MUT_SHOW_ALERT } from '../../store/_mut_consts';
import { mapGetters } from 'vuex';
import store from '../../store';

export default {
    name: 'Search',
    props: {
        searchData: Function,
    },
    data: () => ({
        searchTerm: '',
    }),
    computed: {
        ...mapGetters('common', ['isMobile']),
        isSearchPage() {
            return this.$route.name !== 'Search';
        },
    },
    created() {
        this.searchTerm = this.$route.query.searchTerm;
        if (this.$route.name === 'Search') {
            this.closeModal();
        }
    },
    methods: {
        search() {
            if (!this.searchTerm) {
                store.commit(`common/${MUT_SHOW_ALERT}`, {
                    title: '검색어를 입력해주세요.',
                });
            } else {
                this.$router.push({ name: 'Search', query: { searchTerm: this.searchTerm } }).catch(() => {});
                this.searchData(this.searchTerm);
            }
        },
        closeModal() {
            this.$store.commit(`common/${MUT_CLOSE_SEARCH_MODAL}`);
        },
    },
};
</script>
