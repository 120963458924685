<template>
  <ul class="gachi-skipnav">
    <li class="skipnav-item"><a href="#gachi-header">메뉴 바로가기</a></li>
    <li class="skipnav-item"><a href="#gachi-main">메인 바로가기</a></li>
  </ul>
</template>

<script>
export default {
  name: 'SkipNav',
}
</script>