import ApiService from '../../assets/js/api.service';
import {
  ACT_GET_CAMPAIGN,
  ACT_GET_CAMPAIGN_APPLY_LIST,
  ACT_GET_CAMPAIGN_LIST,
  ACT_GET_CATEGORY_LIST,
  ACT_INSERT_CAMPAIGN_APPLY,
} from '../_act_consts';
import {MUT_SET_CATEGORY_LIST} from '../_mut_consts';

const state = {
  categories: []
}

const getters =  {
  categories (state) {
    return state.categories;
  },
}

const mutations = {
  [MUT_SET_CATEGORY_LIST] (state, categories) {
    state.categories = categories;
  },
}

const actions = {
  [ACT_GET_CATEGORY_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/promotion/clss', params).then(response => resolve(response));
    });
  },
  [ACT_GET_CAMPAIGN_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/promotion/campns', params).then(response => resolve(response));
    });
  },
  [ACT_GET_CAMPAIGN]({}, matchCampnId){
    return new Promise(resolve => {
      ApiService.get('/v1/app/promotion/campn', matchCampnId).then(response => resolve(response));
    });
  },
  [ACT_GET_CAMPAIGN_APPLY_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/promotion/campn/aplys', params, true).then(response => resolve(response));
    });
  },
  [ACT_INSERT_CAMPAIGN_APPLY]({}, params){
    return new Promise(resolve => {
      ApiService.post('/v1/app/promotion/campn/aply', params, true).then(response => resolve(response));
    });
  }
}

export const promotion = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
