<template>
	<router-view />
</template>
<script>
import navigationUtils from './assets/js/navigationUtils';
const mobileCheck = !!navigationUtils.any();

// import('./assets/scss/custom.css');
import('./assets/scss/ckeditor4/image.scss');
import('./assets/scss/ckeditor4/easyimage.scss');
import('./assets/scss/ckeditor4/slider.scss');

if (mobileCheck) {
	import('./assets/mobile/scss/style.scss');
	// import('./assets/mobile/css/common.css');
	// import('./assets/mobile/css/page.apply.css');
	// import('./assets/mobile/css/page.auth.css');
	// import('./assets/mobile/css/page.campaign.css');
	// import('./assets/mobile/css/page.community.css');
	// import('./assets/mobile/css/page.connect.css');
	// import('./assets/mobile/css/page.livestudio.css');
	// import('./assets/mobile/css/page.main.css');
	// import('./assets/mobile/css/page.mypage.css');
	// import('./assets/mobile/css/page.noticeinfo.css');
	// import('./assets/mobile/css/page.popup.css');
	// import('./assets/mobile/css/page.wesme.css');
	// import('./assets/mobile/css/page.edu.css');
	// import('./assets/mobile/css/page.sodam.css');
	// import('./assets/mobile/css/page.self.css');
	// import('./assets/mobile/css/page.guide.css');
	// import('./assets/mobile/css/page.broadcast.css');
} else {
	import('./assets/scss/style.scss');
	// import('./assets/css/common.css');
	// import('./assets/css/page.main.css');
	// import('./assets/css/page.community.css');
	// import('./assets/css/page.apply.css');
	// import('./assets/css/page.connect.css');
	// import('./assets/css/page.noticeinfo.css');
	// import('./assets/css/page.mypage.css');
	// import('./assets/css/page.popup.css');
	// import('./assets/css/page.wesme.css');
	// import('./assets/css/page.campaign.css');
	// import('./assets/css/page.livestudio.css');
	// import('./assets/css/page.auth.css');
	// import('./assets/css/page.edu.css');
	// import('./assets/css/page.sodam.css');
	// import('./assets/css/page.self.css');
	// import('./assets/css/page.guide.css');
	// import('./assets/css/page.broadcast.css');
}

import('./assets/scss/shopmall.apply.scss');

export default {
	name: 'App',
	beforeCreate() {
		const isChrome = !!window.chrome && !!window.chrome.webstore,
			isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0,
			isFirefox = typeof InstallTrigger !== 'undefined',
			// isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification)),
			// isIE = /*@cc_on!@*/false || !!document.documentMode,
			isIE = /*@cc_on!@*/ !!document.documentMode,
			isEdge = !isIE && !!window.StyleMedia,
			isBlink = (isChrome || isOpera) && !!window.CSS;

		if (isChrome) {
			document.querySelector('html').classList.add('chrome');
		} else if (isOpera) {
			document.querySelector('opera').classList.add('opera');
		} else if (isIE) {
			document.querySelector('html').classList.add('ie');
		} else if (isEdge) {
			document.querySelector('html').classList.add('edge');
		} else if (isFirefox) {
			document.querySelector('html').classList.add('firefox');
		} else if (isBlink) {
			document.querySelector('html').classList.add('blink');
		}
	},
};
</script>
