import {ACT_UPDATE_COMMON_INQ} from '../../store/_act_consts';
import store from '../../store'
import {getTokenByKey, saveTokenByKey} from './jwt.service';

const GACHI_VIEW_COUNT = 'gachi-contents-view'

export const viewCount = async (contentsId, contentsType) => {
  const viewStr = getTokenByKey(GACHI_VIEW_COUNT);
  const currentTime = new Date().getTime();
  let doFunc = true;
  let filtered = [];
  if(viewStr){
    const viewArr = JSON.parse(viewStr);
    if(viewArr instanceof Array){
      filtered = viewArr.filter(x => x.expiredTime > currentTime);
      if(filtered.length > 0){
        if(filtered.filter(x => x.contentsId === contentsId && x.contentsType === contentsType).length > 0) return;
      }
    }
  }

  filtered.push({
    contentsId,
    contentsType,
    expiredTime: (currentTime + (1000 * 60 * 10))
  });

  saveTokenByKey(GACHI_VIEW_COUNT, JSON.stringify(filtered));
  if(doFunc) await store.dispatch(`common/${ACT_UPDATE_COMMON_INQ}`, {contentsId, contentsType});
}