<template>
  <div :class="divClass">
    <slot></slot>
    <div v-if="isLoading" style="text-align: center; vertical-align: middle">
      <span class="gachi-modal-loader"></span>
    </div>
    <div v-if="!isLoading && isNoResult" class="no-results-found">
      <p class="text">{{ noResultText }}</p>
    </div>
  </div>
</template>

<script>
import NoResult from './NoResult';

export default {
  name: 'CommonList',
  props:{
    divClass: Array,
    isLoading: Boolean,
    isNoResult: Boolean,
    noResultText: {
      type: String,
      default: '해당내용이 없습니다.'
    }
  },
  components: {
    NoResult
  }
};
</script>