<template>
  <section class="livestudio-section section-rating">
    <common-list  :div-class="['page-component']"
                  :is-loading="isLoading"
                  :is-no-result="isNoResult"
                  no-result-text="등록된 리뷰가 없습니다."
    >
      <div class="livestudio-rating-wrap">
        <h3 class="livestudio-rating-title">예약자 리뷰</h3>
        <div class="livestudio-review">
          <div class="rating-status">
            <div class="rating-wrapper rating-lg">
              <div class="rating">
                <span class="star is-active"></span>
              </div>
              <div class="rating-text">
                <span class="text">{{ averageRateScore.toFixed(1) }}</span>
              </div>
            </div>

            <ul class="status-list">
              <li v-for="(item, idx) in refineItems" class="status-item" :key="idx">
                <div class="status-bar">
                  <span class="value" :style="item.styleObj"></span>
                </div>
                <div class="status-text">
                  <span class="text">{{ item.revwCnt }}</span>
                </div>
              </li>
            </ul>
          </div>

          <div class="review-list-top">
            <div class="top-column">
              <div class="top-header">
                <h3 class="title">전체</h3>
                <span class="count">{{ totalCount }}</span>
              </div>
            </div>
          </div>
          <review-comment :studio-nm="studioNm" :items="items"/>
        </div>

        <div v-if="hasMore" class="page-buttons" data-align="center">
          <button class="btn btn-lg btn-outline-white btn-more">
            <span class="text">더보기</span>
          </button>
        </div>
      </div>
    </common-list>
  </section>
</template>

<script>
import ReviewComment from './ReviewComment';
import {numberComma} from '../../assets/js/utils';
import CommonList from '../common/CommonList';
export default {
  name: 'Review',
  components: {
    ReviewComment,
    CommonList
  },
  props:{
    studioNm: String,
    items: Array,
    hasMore: Boolean,
    countItems: Array,
    isLoading: Boolean
  },
  computed: {
    totalCount() {
      if(this.countItems.length > 0){
        const counts = this.countItems.map(x => x.revwCnt);
        let count = 0;
        counts.forEach(x => {count += x;});
        return count;
      }
      return 0;
    },
    averageRateScore(){
      if(this.totalCount > 0){
        const averages = this.countItems.map(x => (x.revwCnt * x.starrtScore));
        let average = 0;
        averages.forEach(x => {average += x;});
        return average/this.totalCount;
      }
      return 0;
    },
    refineItems(){
      const result = [];
      let filtered;
      let target;
      for(let i = 5; i > 0; i-- ){
        filtered = this.countItems.filter(x => x.starrtScore === i);
        if(filtered.length === 0){
          result.push({
            starrtScore: i,
            revwCnt: 0,
            styleObj: {'width': '0%'}
          });
        }else{
          target = filtered[0];
          result.push({
            starrtScore: i,
            revwCnt: numberComma(target.revwCnt),
            styleObj: {'width': `${this.totalCount === 0 ? 0 : (target.revwCnt/this.totalCount * 100)}%`}
          });
        }
      }
      return result;
    },
    isNoResult(){
      return this.countItems.length === 0;
    }
  }

};
</script>

