<template>
  <main class="page-container" id="sme-connect-sub">
    <div class="connect-sub-header">
      <div class="page-component">
        <div class="creator-avatar">
          <img v-if="params.rprsImgPtUrl" class="avatar" :src="params.rprsImgPtUrl" alt="">
          <img v-else class="avatar" src="../../../assets/img/community/reply_unknown_icon.svg" alt="">
          <i v-if="isCertCtr" class="icon-authentication">인증</i>
        </div>
        <div class="creator-content">
          <div class="creator">
            <span class="creator-name">{{ params.nickNm ? params.nickNm : params.userNm }}</span>
            <div v-if="isCertCtr" class="badge badge-white badge-authentication"><span class="badge-text">인증인플루언서</span></div>
          </div>
          <h2 class="creator-title">{{ params.fthldNm }}</h2>
          <!-- <p class="creator-tag">{{ viewTags }}</p> -->
          <p class="creator-tag">
          <template v-if="params.ytbChnlUrl">
            <span class="chnlUrl"><img src="../../../assets/img/common/Youtube.png" style="padding-right:10px;"> {{params.ytbChnlUrl}}</span><br>
          </template>
          <template v-if="params.instgrmUrl">
            <span class="chnlUrl"><img src="../../../assets/img/common/Instagram.png" style="padding-right:10px;"/> {{params.instgrmUrl}}</span><br>
          </template>
          <template v-if="params.fcbkUrl">
            <span class="chnlUrl"><img src="../../../assets/img/common/Facebook.png" style="padding-right:10px;"/> {{params.fcbkUrl}}</span><br>
          </template>
          <template v-if="params.navrUrl">
            <span class="chnlUrl"><img src="../../../assets/img/common/Naver.png" style="padding-right:10px;"/> {{params.navrUrl}}</span><br>
          </template>
           <!-- <span v-html="viewChannel"></span> -->
           <!-- <span v-if="">{{ params.ytbChnlUrl }}<br></span>
           <span v-if="">{{ params.ytbChnlUrl }}<br></span>
           <span v-if="">{{ params.ytbChnlUrl }}<br></span> -->
          </p>
        </div>
      </div>
    </div>
    <div class="connect-sub-body page-component">
      <div class="editor-area">
        <div class="gachi-view" v-html="params.fthldCn"></div>
      </div>
      <!-- <div class="sns-buttons">
        <button v-if="params.ytbChnlUrl" class="btn-youtube" @click="goLink(params.ytbChnlUrl)">
          <i class="icon-youtube"></i>
          <small class="text">유튜브</small>
        </button>
        <button v-if="params.instgrmUrl" class="btn-insta" @click="goLink(params.instgrmUrl)">
          <i class="icon-insta"></i>
          <small class="text">인스타그램</small>
        </button>
        <button v-if="params.fcbkUrl" class="btn-fb" @click="goLink(params.fcbkUrl)">
          <i class="icon-facebook"></i>
          <small class="text">페이스북</small>
        </button>
      </div> -->
      <div class="page-buttons" data-align="center" style="display: flex;">
        <login-check-button
          :classes="['btn', 'btn-lg', 'btn-secondary']"
          :title="'팝업 열림'"
          :is-auth="isAuth"
          :click-func="showConnect"
        >
        <span class="text">연락하기</span>
        </login-check-button>
            
        <button class="btn btn-lg btn-primary" @click="goList">
          <span class="text">목록</span>
        </button>
      </div>
    </div>
  </main>
</template>
<script>
import {ACT_GET_CREATOR_FOOTHOLD} from '../../../store/_act_consts';
import {getItem, lengthCheck, setParams} from '../../../assets/js/utils';
import {mapGetters} from 'vuex';
import {slideImageInit} from '../../../assets/js/ckeditor4/ckeditor-slide-image';
import {MUT_SHOW_CONNECT} from '../../../store/_mut_consts';
import LoginCheckButton from '../../../components/common/LoginCheckButton';

export default {
  name: 'FootholdView',
  components:{
    LoginCheckButton
  },
  data: () => ({
    ctrFthldId: 0,
    params: {
      fthldNm: '',
      fthldCn: '',
      rprsImgPtUrl: '',
      rprsImgFileNm: '',
      fthldTag1: '',
      fthldTag2: '',
      fthldTag3: '',
      ytbChnlUrl: '',
      instgrmUrl: '',
      fcbkUrl: '',
      navrUrl: '',
      certCtrYn: 'N',
      userId: 0,
      userNm: '',
      nickNm: '',
    }
  }),
  computed: {
    ...mapGetters('auth', ['isAuth', 'session']),
    viewTags(){
      const result = [];
      for(let i = 1; i < 4; i++){
        if(this.params[`fthldTag${i}`]) result.push(`#${this.params[`fthldTag${i}`]}`);
      }
      return result.join(',');
    },
    /* 2022-06-13 황인배 추가 주요활동채널 데이터 생성 */
    viewChannel(){
      const result = [];
      if(this.params.ytbChnlUrl){
        result.push(`<span class="chnlUrl"><img src="../../../assets/img/common/Youtube.png" style="padding-right:10px;"> ${this.params.ytbChnlUrl}</span>`);
      } 
      if(this.params.instgrmUrl){ 
        result.push(`<span class="chnlUrl"><img src="/img/icon_insta.69f073b9.svg" style="padding-right:10px;"/> ${this.params.instgrmUrl}</span>`);
      } 
      if(this.params.fcbkUrl){
        result.push(`<span class="chnlUrl"><img src="/img/icon_facebook.4d799b24.svg" style="padding-right:10px;"/> ${this.params.fcbkUrl}</span>`);
      }
      if(this.params.navrUrl){
        result.push(`<span class="chnlUrl"><img src="/img/icon_facebook.4d799b24.svg" style="padding-right:10px;"/> ${this.params.navrUrl}@@</span>`);
      }
      return result.join('<br>');
    },
    isCertCtr(){
      return this.params.certCtrYn === 'Y';
    }
  },
  created() {
    if(this.$route.params.ctrFthldId) this.ctrFthldId = this.$route.params.ctrFthldId;
    this.getCreatorFoothold();
  },
  methods: {
    getCreatorFoothold(){
      this.$store.dispatch(`community/${ACT_GET_CREATOR_FOOTHOLD}`, this.ctrFthldId).then(res => {
        if(lengthCheck(res)){
          setParams(this.params, getItem(res));
          setTimeout(() => {
            slideImageInit(document.getElementById('gachi-editor-container'));
          }, 500);

        }
      }).catch(e => {
        console.error(e);
      });
    },
    goLink(link){
      window.open(link);
    },
    goList(){
      this.$router.push({name: 'Foothold', query:{tag:this.$route.query.tag}});
    },
    showConnect(){
      this.$store.commit(`common/${MUT_SHOW_CONNECT}`, {
        rcvrUserId: this.params.userId,
        rltdTblNo: 3,
        rltdTblKey: this.ctrFthldId
      });
    }

  }
}
</script>
