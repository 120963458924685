<template>
  <div class="layer-popup-container gachi-layer-popup-container">
    <!--    <div class="layer-popup" id="popup-mypage-creator-foothold">-->
    <div class="layer-popup" :id="footholdId">
      <div>
        <div class="popup-wrapper popup_influencer">
          <header class="popup-header">
            <h3 class="header-title">맞춤 인플루언서 찾기</h3>
            <button type="button" class="btn-close" @click="closeModal">
              <i class="icon-close">팝업닫기</i>
            </button>
          </header>
          <div class="popup-body2">
            <div class="left">
              <div class="profile-image-wrapper">
                <input ref="rprs" type="file" accept="image/*" style="display:none;" @change="setImageInfo">
                <div class="profile-image gachi-cursor-pointer"  @click="changeImage">
                  <img v-if="params.rprsImgPtUrl" :src="params.rprsImgPtUrl" alt="">
                  <img v-else src="../../assets/img/community/reply_unknown_icon.svg" alt="">
                </div>
                <button class="btn btn-sm btn-primary" :style="`width:280px; height:${buttonHeight}px;`" @click="changeImage"><span class="text" style="font-size: 16px;">수정하기</span></button>
                <!-- <input v-model="params.fthldNm" type="text" class="form-control" placeholder="크리에이터명"> -->
                <div class="form-cellth text-center">
                  <label>{{params.fthldNm}}</label>
                </div>
              </div>
            </div>

            <div class="right">
              <span class="visually-hidden">크리에이터명</span>
              <div class="form-row">
                <div class="form-cellth text-left">
                  <label>크리에이터명 <!--<span style="color: red">*</span>--></label>
                </div>
                <div class="form-celltd">
                  <div class="form-row">
                    <input v-model="params.fthldNm" type="textbox" class="form-control" placeholder="크리에이터명">
                  </div>
                </div>
              </div>

              <span class="visually-hidden">주요활동 카테고리</span>
              <div class="form-row">
                <div class="form-cellth text-left">
                  <label>주요활동 카테고리</label>
                </div>
                <div class="form-celltd">
                  <div class="form-row">
                    <select v-model="params.primActvtCtgr" class="form-select">
                      <option value="">선택</option>
                      <option v-for="(option, idx) in ytbChnlCtgrTypCdOptions" :value="option.cmmnCdVal" :key="idx">{{ option.cmmnCdValNm }}</option>
                    </select>
                    <!-- <select name="" id="" class="form-select">
                      <option value="식품">식품</option>
                      <option value="패션">패션</option>
                      <option value="뷰티">뷰티</option>
                      <option value="가전">가전</option>
                      <option value="생활">생활</option>
                    </select> -->
                  </div>
                </div>
              </div>

              <span class="visually-hidden">주요활동 채널</span>
              <div class="form-row">
                <div class="form-cellth text-left">
                  <label class="form-label">
                    <span class="text">주요활동 채널</span>
                  </label>
                </div>
                <div class="form-celltd">
                  <div class="form-row">
                    <input v-model="params.ytbChnlUrl" type="text" class="form-control" placeholder="유튜브 채널명을 입력해주세요.">
                  </div>
                  <div class="form-row">
                    <input v-model="params.instgrmUrl" type="text" class="form-control" placeholder="인스타그램 채널명을 입력해주세요.">
                  </div>
                  <div class="form-row">
                    <input v-model="params.fcbkUrl" type="text" class="form-control" placeholder="페이스북 채널명을 입력해주세요.">
                  </div>
                  <div class="form-row">
                    <input v-model="params.navrUrl" type="text" class="form-control" placeholder="네이버 블로그명을 입력해주세요.">
                  </div>
                </div>
              </div>
              <span class="visually-hidden">인플루언서 소개</span>
              <div class="form-row">
                <div class="form-cellth text-left" style="width:100%">
                  <label>인플루언서 소개</label>
                </div>
                <div class="form-celltd">
                  <div class="form-row">
                    <div class="form-control">
                      <ckeditor v-model="params.fthldCn" :config="editorConfig" @namespaceloaded="onNamespaceLoaded" @ready="onReady" />
                    </div>
                  </div>
                </div>
              </div>

              <!-- <span class="visually-hidden">대표영상 소개</span>
              <div class="form-row">
                <div class="form-cellth text-left">
                  <label>대표영상 소개</label>
                </div>
                <div class="form-celltd">
                  <div class="form-row">
                    <div class="form-control">
                      <ckeditor v-model="params.fthldCn" :config="editorConfig" @namespaceloaded="onNamespaceLoaded" @ready="onReady" />
                    </div>
                  </div>
                </div>
              </div> -->

            </div>

          </div>

          <div class="popup-buttons">
            <button class="btn btn-light btn-lg" @click="closeModal" style="margin-right: 5px">
              <span class="text">취소</span>
            </button>

            <loading-button
                :button-text="action"
                :is-loading="isLoading"
                :click-func="clickSave"
                :button-classes="['btn-lg', 'btn-primary']"
                :text-classes="[]"
                loading-size="sm"
            ></loading-button>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {gachiConfig} from '../../assets/js/ckeditor4/ckeditor-config';
import {slideImage, slideImageInit} from '../../assets/js/ckeditor4/ckeditor-slide-image';
import {MUT_CLOSE_FOOTHOLD, MUT_SHOW_ALERT, MUT_SHOW_CONFIRM, MUT_SET_COMMON_CODE_ITEMS} from '../../store/_mut_consts';
import {
  ACT_GET_CREATOR_MY_FOOTHOLD,
  ACT_INSERT_CREATOR_FOOTHOLD,
  ACT_UPDATE_CREATOR_FOOTHOLD, ACT_UPLOAD_COMMON,
  ACT_GET_SHOP_MALL_COMMON_CODE_LIST,
} from '../../store/_act_consts';
import {getItem, getResult, isSuccess, lengthCheck, setParams, validate, getCheckItems} from '../../assets/js/utils';
import LoadingButton from '../common/LoadingButton';
import {mapGetters} from 'vuex';
import cateEmbed from '../../assets/js/ckeditor4/cate-embed';
import nasEmbed from '../../assets/js/ckeditor4/nas-embed';
import imagebase from "@/assets/js/ckeditor4/imagebase";
import embed from '../../assets/js/ckeditor4/embed';
import cloudservice from '../../assets/js/ckeditor4/cloudservice-full';
import easyimage from '../../assets/js/ckeditor4/easyimage-full';

export default {
  name: 'FootholdEdit',
  components:{
    LoadingButton
  },
  data: () => ({
    ckeditor: null,
    editorConfig: gachiConfig,
    ctrFthldId: 0,
    params: {
      fthldNm: '',
      fthldIntro: '',
      fthldCn: '',
      rprsImgPtUrl: '',
      rprsImgFileNm: '',
      fthldTag1: '',
      fthldTag2: '',
      fthldTag3: '',
      ytbChnlUrl: '',
      instgrmUrl: '',
      fcbkUrl: '',
      navrUrl: '',
      primActvtCtgr: '',
    },
    rprsReader : null,
    isLoading: false,
  }),
  computed: {
    ...mapGetters('common', ['isMobile', 'commonCode']),
    action(){
      return this.ctrFthldId > 0 ? '수정' : '등록';
    },
    footholdId(){
      return this.isMobile ? 'popup-mypage-creator' : 'popup-mypage-creator-foothold'
    },
    ytbChnlCtgrTypCdOptions(){
      return this.commonCode.filter(x => x.cmmnCd === 'YTB_CHNL_CTGR_TYP_CD');
    },
    //20220622 버튼 모바일/PC버튼 사이즈 조절 - hib
    buttonHeight(){
      return this.isMobile ? '60' : '40'
    },
  },
  created() {
    this.setImageReader();
    if(this.commonCode.length === 0) this.getCommonCdList();
  },
  beforeDestroy() {
    if(this.instance != null) this.instance.destroy();
    this.rprsReader = null;
  },
  methods: {
    onNamespaceLoaded( CKEDITOR ) {
      embed(CKEDITOR);
      cloudservice(CKEDITOR);
      easyimage(CKEDITOR); // import 확인하기 easyimage-full
      imagebase(CKEDITOR); // easyimage-full 사용할때 같이 사용
      slideImage(CKEDITOR);
      cateEmbed(CKEDITOR);
      nasEmbed(CKEDITOR);
    },
    onReady(instance){
      if(this.instance === null) this.instance = instance;
      this.$store.dispatch(`community/${ACT_GET_CREATOR_MY_FOOTHOLD}`).then(res => {
        if(lengthCheck(res)){
          const item = getItem(res);
          this.ctrFthldId = item.ctrFthldId;
          setParams(this.params, item);
          //setTimeout(() => {slideImageInit(instance.document.$);}, 500);
        }
      });
    },
    closeModal(){
      this.$store.commit(`community/${MUT_CLOSE_FOOTHOLD}`);
    },
    changeImage(){
      this.$refs.rprs.click();
    },
    setImageReader(){
      this.rprsReader = new FileReader();
      this.rprsReader.onload = (event) => {
        this.params.rprsImgPtUrl = event.target.result;
      };
    },
    setImageInfo() {
      if (this.$refs.rprs.files && this.$refs.rprs.files.length > 0) {
        const file = this.$refs.rprs.files[0];
        this.params.rprsImgFileNm = file.name;
        this.rprsReader.readAsDataURL(file);
      }
    },
    minifyCn(){
      if(this.instances != null){
        const editorContents = this.instances.getData();
        if(editorContents !== null && editorContents !== undefined){
          this.params.fthldCn = editorContents.replace(/^\s+|\r\n|\n|\r|(>)\s+(<)|\s+$/gm, '$1$2');
        }
      }
    },
    refineTag(){
      for(let i = 1; i < 4; i++){
        if(this.params[`fthldTag${i}`] && this.params[`fthldTag${i}`].trim()){
          // 양쪽 빈공간 제거
          this.params[`fthldTag${i}`] = this.params[`fthldTag${i}`].trim();
          // 첫 글자에 #이 있을 경우 강제로 제거한다.
          if(this.params[`fthldTag${i}`].indexOf('#') === 0){
            this.params[`fthldTag${i}`] = this.params[`fthldTag${i}`].substr(1);
          }
        }else{
          this.params[`fthldTag${i}`] = '';
        }
      }
    },
    getCommonCdList(){
      this.$store.dispatch(`common/${ACT_GET_SHOP_MALL_COMMON_CODE_LIST}`, {}).then(res => {
        this.$store.commit(`common/${MUT_SET_COMMON_CODE_ITEMS}`, getCheckItems(res));
      });
    },
    async clickSave(){
      if(this.isLoading) return;
      this.isLoading = true;
      if(
          validate(this.$store, this.params, [{key: 'fthldNm', msg: '크리에이터명을 입력해주세요.'}])
      ){
        await this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
          title: `맞춤 인플루언서 찾기를 ${this.action}하시겠습니까?`,
          showCancelButton: true,
          yesfunc: this.saveCreatorFoothold,
          nofunc: () => {this.isLoading = false;},
        });
      }else{
        this.isLoading = false;
      }
    },
    async saveCreatorFoothold(){
      try{
        // 이미지가 존재할 경우 이미지 업로드
        if(this.$refs.rprs && this.$refs.rprs.files && this.$refs.rprs.files.length > 0){
          const imgRes = await this.$store.dispatch(`common/${ACT_UPLOAD_COMMON}`, {menuType: 'foothold', contentsType: 'rprs', fileList: this.$refs.rprs.files});
          if(imgRes && imgRes.data && imgRes.data.result && imgRes.data.result.number === 200){
            this.params.rprsImgPtUrl = imgRes.data.filePath;
          }
        }
        this.refineTag();
        this.minifyCn();
        const res = this.ctrFthldId > 0 ?
            await this.$store.dispatch(`community/${ACT_UPDATE_CREATOR_FOOTHOLD}`, {ctrFthldId: this.ctrFthldId, params: this.params}) :
            await this.$store.dispatch(`community/${ACT_INSERT_CREATOR_FOOTHOLD}`, this.params);
        this.isLoading = false;
        if(isSuccess(res)){
          await this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
            title: `맞춤 인플루언서 찾기가 ${this.action}되었습니다.`,
            yesfunc: this.closeModal,
            rightNow: true
          });
        }else{
          await this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: getResult(res).message});
        }

      } catch (e){
        console.error(e);
        await this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '오류가 발생했습니다. 다시 시도해주세요.'});
        this.isLoading = false;
      }
    },
  }
}
</script>
