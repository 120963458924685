import ApiService from '../../assets/js/api.service';
import {
    ACT_DELETE_SB_COMMUNITY,
    ACT_GET_CREATOR_FOOTHOLD,
    ACT_GET_CREATOR_FOOTHOLD_LIST,
    ACT_GET_CREATOR_FOOTHOLD_TAG_LIST,
    ACT_GET_CREATOR_MY_FOOTHOLD,
    ACT_GET_SB_COMMUNITY,
    ACT_GET_SB_COMMUNITY_COMMENT_LIST,
    ACT_GET_SB_COMMUNITY_LIST,
    ACT_INSERT_CREATOR_FOOTHOLD,
    ACT_INSERT_SB_COMMUNITY,
    ACT_INSERT_SB_COMMUNITY_COMMENT,
    ACT_DELETE_SB_COMMUNITY_COMMENT,
    ACT_UPDATE_SB_COMMUNITY_COMMENT,
    ACT_UPDATE_CREATOR_FOOTHOLD,
    ACT_UPDATE_SB_COMMUNITY,
    ACT_GET_PLAYGROUND3_LIST,
    ACT_GET_PLAYGROUND3,
    ACT_DOWNLOAD_PLAYGROUND3,
    ACT_DOWN_ATTACH_PLAYGROUND3, ACT_GET_CTR_APRV_STATUS,
    ACT_DOWN_ATTACH_PLAYGROUND2,ACT_DOWNLOAD_PLAYGROUND2
} from '../_act_consts';
import {
    MUT_CLOSE_COMMUNITY,
    MUT_CLOSE_FOOTHOLD,
    MUT_SHOW_COMMUNITY,
    MUT_SHOW_FOOTHOLD,
} from '../_mut_consts';

import axios from 'axios'

const state = {
    foothold: {
        show: false
    },
    communityModal:{
        show: false
    }
}

const getters =  {
    foothold(state){
        return state.foothold;
    },
    communityModal(state){
        return state.communityModal;
    }
}

const mutations = {
    [MUT_SHOW_FOOTHOLD](state){
        state.foothold.show = true;
    },
    [MUT_CLOSE_FOOTHOLD](state){
        state.foothold.show = false;
    },
    [MUT_SHOW_COMMUNITY](state){
        state.communityModal.show = true;
    },
    [MUT_CLOSE_COMMUNITY](state){
        state.communityModal.show = false;
    }
}

const actions = {
    [ACT_GET_SB_COMMUNITY_LIST]({}, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/community/sbs', params).then(response => resolve(response));
        });
    },
    [ACT_GET_SB_COMMUNITY]({}, {comunBltartclId, params}){
        return new Promise(resolve => {
            ApiService.query(`/v1/app/community/sb/${comunBltartclId}`, params).then(response => resolve(response));
        });
    },
    [ACT_INSERT_SB_COMMUNITY]({}, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/community/sb', params, true).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_SB_COMMUNITY]({}, {comunBltartclId, params}){
        return new Promise(resolve => {
            ApiService.update('/v1/app/community/sb', comunBltartclId, params, true).then(response => resolve(response));
        });
    },
    [ACT_DELETE_SB_COMMUNITY]({}, comunBltartclId){
        return new Promise(resolve => {
            ApiService.delete('/v1/app/community/sb', comunBltartclId, true).then(response => resolve(response));
        });
    },
    [ACT_GET_SB_COMMUNITY_COMMENT_LIST]({}, {comunBltartclId, params}){
        return new Promise(resolve => {
            ApiService.query(`/v1/app/community/sb/${comunBltartclId}/comments`, params).then(response => resolve(response));
        });
    },
    [ACT_INSERT_SB_COMMUNITY_COMMENT]({}, {comunBltartclId, params}){
        return new Promise(resolve => {
            ApiService.post(`/v1/app/community/sb/${comunBltartclId}/comment`, params, true).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_SB_COMMUNITY_COMMENT]({}, {comunBltartclId, cmntId, params}){
        return new Promise(resolve => {
            ApiService.update(`/v1/app/community/sb/${comunBltartclId}/comment`,  cmntId, params, true).then(response => resolve(response));
        });
    },
    [ACT_DELETE_SB_COMMUNITY_COMMENT]({}, {comunBltartclId, cmntId}){
        return new Promise(resolve => {
            ApiService.delete(`/v1/app/community/sb/${comunBltartclId}/comment`,  cmntId, true).then(response => resolve(response));
        });
    },
    [ACT_GET_CREATOR_MY_FOOTHOLD]({}){
        return new Promise(resolve => {
            ApiService.query('/v1/app/community/ctr/my/fthld', {}, true).then(response => resolve(response));
        });
    },
    [ACT_GET_CREATOR_FOOTHOLD_LIST]({}, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/community/ctr/fthlds', params).then(response => resolve(response));
        });
    },
    [ACT_GET_CREATOR_FOOTHOLD]({}, ctrFthldId){
        return new Promise(resolve => {
            ApiService.get('/v1/app/community/ctr/fthld', ctrFthldId).then(response => resolve(response));
        });
    },
    [ACT_INSERT_CREATOR_FOOTHOLD]({}, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/community/ctr/fthld', params, true).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_CREATOR_FOOTHOLD]({}, {ctrFthldId, params}){
        return new Promise(resolve => {
            ApiService.update('/v1/app/community/ctr/fthld', ctrFthldId, params, true).then(response => resolve(response));
        });
    },
    [ACT_GET_CREATOR_FOOTHOLD_TAG_LIST](){
        return new Promise(resolve => {
            ApiService.query(`/v1/app/community/ctr/fthld/tags`, {}).then(response => resolve(response));
        });
    },
    [ACT_GET_PLAYGROUND3_LIST]({}, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/community/plygrnds3', params).then(response => resolve(response));
        });
    },
    [ACT_GET_PLAYGROUND3]({}, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/community/plygrnd3', params).then(response => resolve(response));
        });
    },
    [ACT_DOWNLOAD_PLAYGROUND3]({}, {plygrndId, fileName}){
        // console.log(plygrndId)
        return new Promise(resolve => {
            ApiService.download(`/v1/app/community/plygrnd3`, 'atch',
                {plygrndId}, fileName);
        });
    },
    [ACT_GET_CTR_APRV_STATUS]({}){
        return new Promise(resolve => {
            ApiService.query('/v1/app/community/ctr/ctrSts', {}, true).then(response => resolve(response));
        });
    },

    [ACT_DOWN_ATTACH_PLAYGROUND3]({}, {plygrndId, fileName}){
        let url = '/v1/app/community/plygrnd3/dnatch';
        const params = { plygrndId }
        if(params !== null && params !== undefined){
            const keys = Object.keys(params);
            if(keys.length > 0){
                url += ('?'+ keys.map(key => `${key}=${params[key]}`).join('&'));
            }
        }
        return new Promise((resolve, reject) => {
            axios({
                url: url,
                method: 'GET',
                responseType: 'blob',
            }).then(res => { resolve(res) } , error => { reject(error) })
        })

        // return new Promise(resolve => {
        //     ApiService.download(`/v1/app/community/plygrnd3`, 'dnatch',
        //         {plygrndId}, fileName);
        // });
    },
    [ACT_DOWNLOAD_PLAYGROUND2]({}, {plygrndId, fileName}){
        // console.log(plygrndId)
        return new Promise(resolve => {
            ApiService.download(`/v1/app/community/plygrnd2`, 'atch',
                {plygrndId}, fileName);
        });
    },
    [ACT_DOWN_ATTACH_PLAYGROUND2]({}, {plygrndId, fileName}){
        let url = '/v1/app/community/plygrnd2/dnatch';
        const params = { plygrndId }
        if(params !== null && params !== undefined){
            const keys = Object.keys(params);
            if(keys.length > 0){
                url += ('?'+ keys.map(key => `${key}=${params[key]}`).join('&'));
            }
        }
        return new Promise((resolve, reject) => {
            axios({
                url: url,
                method: 'GET',
                responseType: 'blob',
            }).then(res => { resolve(res) } , error => { reject(error) })
        })

        // return new Promise(resolve => {
        //     ApiService.download(`/v1/app/community/plygrnd3`, 'dnatch',
        //         {plygrndId}, fileName);
        // });
    }
}

export const community = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
