<template>
  <main class="page-container" id="sme-livestudio-sub">

<!--      <div class="livestudio-sub-title">-->
<!--        <div class="badge">-->
<!--          <span class="badge-text">라이브 스튜디오 예약</span>-->
<!--        </div>-->
<!--        <h1 class="title">{{ roomName }}</h1>-->
<!--      </div>-->


      <div class="livestudio-sub-body">
        <div class="livestudio-sub-content">
          <div class="livestudio-gallery">
            <div class="gallery-images swiper-container">
              <div class="gallery-image-wrapper swiper-wrapper" :style="slideStyle">
                <div v-for="(img, idx) in roomImgs" class="gallery-image swiper-slide" :key="idx">
                  <img :src="img.imgPtUrl" alt="" />
                </div>
              </div>
              <div class="swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal">
                <a v-for="(img, idx) in roomImgs" href="javascript:" class="swiper-pagination-bullet" :class="{'swiper-pagination-bullet-active': currentIdx === idx}">{{idx+1}}</a>
              </div>
            </div>

            <button v-if="roomImgs.length > 1" class="gallery-button-next" @click="slideButton(true)">
              <i class="icon-arrow">다음 이미지</i>
            </button>
            <button v-if="roomImgs.length > 1"  class="gallery-button-prev" @click="slideButton(false)">
              <i class="icon-arrow">이전 이미지</i>
            </button>
          </div>

          <div class="livestudio-description">
            <h5 class="livestudio-description-title">{{ room.roomOnelineIntro }}<br/> {{ roomName }}</h5>
            <div :class="{'livestudio-description-contents': isFold}">
              <pre class="livestudio-description-text gachi-pre">{{ room.roomDtlIntro }}</pre>
            </div>

            <div v-if="room.roomDtlIntro" class="livestudio-description-morebutton">
              <button class="btn btn-more btn-lg" @click="toggleFold">
                <span class="text">{{ isFold ? '펼쳐보기' : '접기'}}</span>
                <i :class="{'gachi-up-arrow-icon': !isFold, 'icon-arrow': isFold}"></i>
              </button>
            </div>

          </div>
        </div>


        <div class="livestudio-reservator">
          <!--    달력      -->

          <div class="livestudio-calendar">
            <div class="month-selector">
              <button class="btn-selector btn-selector-prev" @click="changeMonth(false)">
                <i class="icon-arrow">이전 달</i>
              </button>
<!--              <h5 class="calendar-title">{{ year }}.{{ month < 10 ? `0${month}` : month }}</h5>-->
              <h5 class="calendar-title">{{ year }}.{{ month }}</h5>
              <button class="btn-selector btn-selector-next" @click="changeMonth(true)">
                <i class="icon-arrow">다음 달</i>
              </button>
            </div>
            <calendar
                ref="revCalendar"
                :select-year="year"
                :select-month="month"
                :select-day="day"
                :weekend-yn="room.wekendOperYn"
                :holy-day-yn="room.holdyOperYn"
                :select-func="selectCalendarDay"
            />
          </div>

          <div v-if="!isReservedNot" class="livestudio-time-selector">
            <ol class="time-selector-list">
              <li v-for="(schedule, idx) in scheduleItems" class="time-selector-item" :key="`schedule-${idx}`">
                <div class="time-selector-check">
                  <input v-model="checked" type="checkbox" :id="`time-selector-${idx}`" class="time-selector-input" :value="schedule" :disabled="schedule.status !== 'E'" />
                  <label :for="`time-selector-${idx}`" class="time-selector-label">
                    <span class="time">{{ schedule.startHour }}:00 - {{ schedule.endHour }}:00</span>
                    <small v-if="schedule.status !== 'E'" class="time-status">예약{{ schedule.status === 'M' ? '완료' : '불가' }}</small>
                    <small v-else class="time-status">예약선택</small>
                  </label>
                </div>
              </li>
            </ol>
          </div>

          <div v-if="(!isReservedNot) && room.roomEsntlNtc"  class="livestudio-warning">
            <h5 class="livestudio-warning-title">
              <i class="icon-warning"></i> 꼭 확인하세요
            </h5>
            <pre class="livestudio-warning-description gachi-pre">{{room.roomEsntlNtc}}</pre>
          </div>

          <div v-if="!isReservedNot" class="livestudio-buttons">
            <login-check-button
                :classes="['btn', 'btn-xl', 'btn-secondary']"
                :disabled="isLoading"
                :click-func="clickSave"
                :is-auth="isAuth"
            >
              <span class="text">다음단계</span>
            </login-check-button>
            <button style="margin-top: 5%" class="btn btn-lg btn-primary" @click="goList">
                <span class="text">목록</span>
            </button>

          </div>
          <div v-else class="livestudio-buttons">
            <h5 class="livestudio-warning-title">
              <i class="icon-warning"></i> 오늘 이후 예약 가능 날짜 선택
            </h5>
          </div>
        </div>
      </div>
  </main>
</template>

<script>
import Calendar from '../../../../components/studio/Calendar';
import {ACT_GET_STUDIO_RESERVATION_SCHEDULE_LIST, ACT_GET_STUDIO_ROOM} from '../../../../store/_act_consts';
import {collectionCheck, getItem, getItems, lengthCheck, setParams} from '../../../../assets/js/utils';
import {MUT_SHOW_ALERT, MUT_SHOW_RESERVATION_MODAL} from '../../../../store/_mut_consts';
import LoginCheckButton from '../../../../components/common/LoginCheckButton';
import {mapGetters} from 'vuex';

export default {
  name: 'MobileRoomView',
  components:{
    Calendar,
    LoginCheckButton
  },
  data: () => ({
    year: 0,
    month: 0,
    day: 0,

    studoId: 0,
    roomId: 0,
    room: {
      studoNm: '',
      roomNm: '',
      roomOnelineIntro: '',
      roomDtlIntro: '',
      roomEsntlNtc: '',
      operBgngHhmm: '',
      operEndHhmm: '',
      wekendOperYn: '',
      holdyOperYn: '',
    },
    roomImgs: [],
    reservedTms: [],
    isReservedNot: false,

    isFold: true,
    checked: [],

    isLoading: false,
    currentIdx: 0,
    todayYmd: '',
    todayY: 0,
    todayM: 0,
    todayD: 0,
    // setTime: 0
    rsvtImpssBgngHhmm : '',
    rsvtImpssEndHhmm : '',
    maxRsvtUsrCnt : 4
  }),
  computed: {
    ...mapGetters('auth', ['isAuth']),
    slideStyle(){
      return {transform: `translate3d(${this.currentIdx*-660}px, 0px, 0px)`, 'transition-duration': `300ms`};
    },
    roomName(){
      const result = [];
      if(this.room.studoNm) result.push(this.room.studoNm);
      if(this.room.roomNm) result.push(this.room.roomNm);
      return result.join(' — ');
    },
    scheduleItems(){
      const result = [];
      const reservedItems = [];
      const target = this.reservedTms;
      if(this.rsvtImpssBgngHhmm !== '' && this.rsvtImpssEndHhmm !== null)
      target.push({mineYn:"E", rsvtBgngHhmm: this.rsvtImpssBgngHhmm, rsvtEndHhmm: this.rsvtImpssEndHhmm, rsvtYmd: this.currentYmd })
      let beginHour;
      let endHour;
      for(let i =0; i < target.length; i++){
        beginHour = parseInt(target[i].rsvtBgngHhmm.substr(0, 2));
        endHour = parseInt(target[i].rsvtEndHhmm.substr(0, 2));
        for(let j = beginHour; j < endHour; j++){
          reservedItems.push({hour: j, mineYn: target[i].mineYn});
        }
      }

      if(this.room.operBgngHhmm && this.room.operEndHhmm){
        const beginHour = parseInt(this.room.operBgngHhmm.substr(0, 2));
        const endHour = parseInt(this.room.operEndHhmm.substr(0, 2));
        for(let i = beginHour; i < endHour; i++){
          result.push({
            startHour: i,
            endHour: (i+1),
            status: this.checkSchedule(i, reservedItems)
          });
        }
      }
      return result;
    },
    currentYmd(){
      return `${this.year}${this.month < 10 ? `0${this.month}`: this.month}${this.day < 10 ? `0${this.day}`: this.day}`;
    },
  },
  created() {
    const todayDate = new Date();
    this.todayY = todayDate.getFullYear();
    this.todayM = todayDate.getMonth() + 1;
    this.todayD = todayDate.getDate() ;
    this.setCurrentDate();
    if(this.$route.params.roomId) this.roomId = this.$route.params.roomId;
    if (this.$route.params.studoId) this.studoId = this.$route.params.studoId;
    if(this.roomId > 0) {
      this.getRoom();
      this.getReservationScheduleList();
    }
  },
  methods: {
    checkSchedule(hour, reservedItems){
      const filtered = reservedItems.filter(x => x.hour === hour);
      if(filtered.length > 0){
        if(filtered[0].mineYn === 'Y'){
          return 'M'; // my schedule
        }else{
          return 'R'; // reserved
        }
      }else{
        return 'E'; // enable
      }
    },
    getReservationScheduleList(){
      if( this.currentYmd  <= this.todayYmd ) {
        this.isReservedNot = true
      } else {
        this.isReservedNot = false
        this.$store.dispatch(`studio/${ACT_GET_STUDIO_RESERVATION_SCHEDULE_LIST}`, {
          roomId: this.roomId,
          params: {
            rsvtYmd: this.currentYmd
          }
        }).then(res => {
          if(lengthCheck(res)){
            this.reservedTms = getItems(res);
          }else{
            this.reservedTms = [];
          }
        }).catch(e => {
          console.error(e);
          this.reservedTms = [];
        });
      }
    },
    getRoom(){
      this.$store.dispatch(`studio/${ACT_GET_STUDIO_ROOM}`, this.roomId)
      .then(res => {
        if(lengthCheck(res)){
          const item = getItem(res);
          this.maxRsvtUsrCnt = item.maxRsvtUsrCnt;
          this.rsvtImpssBgngHhmm = item.rsvtImpssBgngHhmm;
          this.rsvtImpssEndHhmm = item.rsvtImpssEndHhmm;
          setParams(this.room, item);
          if(collectionCheck(item.roomImgs, 'roomImgId')){
            this.roomImgs = item.roomImgs;
          }
        }
      })
      .catch(e => {
        console.error(e);
      });
    },
    setCurrentDate(){
      let nowDate = new Date();
      nowDate.setDate(nowDate.getDate() + 1);
      this.year = nowDate.getFullYear();
      this.month = nowDate.getMonth() + 1;
      this.day = nowDate.getDate();
    },
    toggleFold(){
      this.isFold = !this.isFold;
    },
    clearChecked(){
      this.checked = [];
    },
    selectCalendarDay(day, isDisabled){
      // if(this.month === day.month){
      if(!isDisabled){
        this.day = day.day;
        this.clearChecked();
        this.getReservationScheduleList()
      }
    },
    changeMonth(isNext){
      this.day = 1;
      if(isNext){
        if(this.month === 12){
          this.year++;
          this.month = 1;
        }else{
          this.month++;
        }
      }else{
        if(this.month === 1){
          this.year--;
          this.month = 12;
        }else{
          this.month--;
        }
      }
      const vm = this
      setTimeout(() => {
        const nextMonthDay = new Date(vm.year, vm.month -1, vm.day);
        const isdis = vm.$refs.revCalendar.checkDisabled(nextMonthDay.getDay(), {year: vm.year, month: vm.month, day: vm.day});
        if(!isdis) {
          vm.clearChecked();
          vm.getReservationScheduleList();
        } else {
          vm.isReservedNot = true
        }
      }, 500);
    },
    clickSave(){
      if(this.isLoading) return;
      this.isLoading = true;

      const date = this.validateSchedule();
      if(date !== null){
        if(this.maxRsvtUsrCnt ===0){
            this.maxRsvtUsrCnt = 4; 
        }
        this.$store.commit(`studio/${MUT_SHOW_RESERVATION_MODAL}`, {
          roomId: this.roomId,
          roomName: this.roomName,
          maxRsvtUsrCnt: this.maxRsvtUsrCnt,
          rsvtYmd: `${this.year}${this.month < 10 ? `0${this.month}`: this.month}${this.day < 10 ?`0${this.day}` : this.day}`,
          applyDate: date,
          imgPtUrl: (this.roomImgs && this.roomImgs.length > 0 ? this.roomImgs[0].imgPtUrl : null),
          afterFunc: this.applyAfterFunc
        });
      }
      this.isLoading = false;
    },
    validateSchedule(){
      if(this.checked.length === 0){
        this.errorAlert('예약일시를 선택해주세요.');
      }else{
        this.checked = this.checked.sort((a, b) => {
          return a.startHour - b.startHour
        });

        const len = this.checked.length;
        const hours = [];
        for(let i = 0; i < len; i++){
          hours.push(this.checked[i].startHour);
          if(len === (i + 1)) hours.push(this.checked[i].endHour);
        }
        // 3시간
        if(hours.length > 5){
          this.errorAlert('최대 4시간까지만 예약이 가능합니다.');
        }else{
          let result = true;
          for(let i = 1; i < hours.length; i++){
            if(hours[i] - hours[(i - 1)] > 1){
              result = false;
              this.errorAlert('연속된 시간만 예약 가능합니다.');
              break;
            }
          }
          if(result) return {startHour: hours[0], endHour: hours[hours.length - 1]};
        }
      }
      return null;
    },
    errorAlert(msg){
      this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: msg});
    },
    applyAfterFunc(){
      this.checked = [];
      this.getReservationScheduleList();
    },
    slideButton(isNext){
      if(isNext){
        const changeImg = this.currentIdx + 1;
        if(changeImg > (this.roomImgs.length - 1)){
          this.currentIdx = 0;
        }else{
          this.currentIdx = changeImg;
        }
      }else{
        const changeImg = this.currentIdx -1;
        if(changeImg < 0){
          this.currentIdx = (this.roomImgs.length - 1);
        }else{
          this.currentIdx = changeImg;
        }
      }
    },goList() {
      this.$router.push("/studio?platid=" + this.studoId.toString());
    }
  }
};
</script>
