const menus = [
  {
    title: '소상공인뉴스',
    code: 'sentcont',
    path: '/sentcont/home',
    isActive: false,
    isSubActive: false,
  },
  {
    title: '소담스퀘어',
    code: 'studio',
    path: '/playground2?ctgrCode=1012001',
    isActive: false,
    isSubActive: false,
    children: [
      {
        title: '소담스퀘어',
        path: '/playground2?ctgrCode=1012001',
        isTargetBlank: false
      },
      {
        title: '라이브 스튜디오',
        path: '/studio',
        isTargetBlank: false
      },
      {
        title: '소담상회',
        path: '/sodam',
        isTargetBlank: false
      },
    ]
  },
  {
		title: '<span class="ai-anim-1"><i></i><i></i><i></i></span>자가진단<i class="icon-ai-beta"></i><span class="ai-anim-2"></span>',
		code: 'self-diagnosis',
		path: '/self-diagnosis',
		isActive: false,
		isSubActive: false,
	},
  {
		title: '지원사업',
		code: 'support',
		//path: '/supportbiz',
		path: '/supportbiz/intrd',
		isActive: false,
		isSubActive: false,
		children: [
			{
				title: '지원사업 소개',
				path: '/supportbiz/intrd',
				isTargetBlank: false,
			},
			{
				title: '지원사업 모집현황',
				path: '/supportbiz',
				isTargetBlank: false,
			},
		],
	},
  {
    title: '판로TV',
    code: 'broadcast',
    path: '/broadcast',
    isActive: false,
    isSubActive: false,
    isTargetBlank: false
  },
	{
		title: 'e러닝',
		code: 'education',
		path: '/education',
		isActive: false,
		isSubActive: false,
	},
];

export default menus;
