<template>
  <div class="no-results-found" v-if="!isLoading">
    <p class="text">{{ !text ? '해당내용이 없습니다.' : text}}</p>
  </div>
</template>

<script>
export default {
  name: 'NoResult',
  props: {
    text: String,
    isLoading: Boolean,
    items: Array
  },
};
</script>