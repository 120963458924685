<template>
  <main class="page-container" id="sme-campaign-view">
    <div class="page-component">

      <div class="page-header screen-out">
        <div class="page-component">
          <div class="page-header-title">
            <h2 class="title">{{ params.sprtBizNm }}</h2>
          </div>
        </div>
      </div>
      <div class="page-body">
        <div class="post-view-container">
          <section class="post-view-wrapper">
            <header class="post-view-header">
              <div class="header-category">
                <div class="badge_wrap">
                  <span v-if="params.instNm !==''" class="badge-text">{{params.instNm}}</span>
                </div>
              </div>
              <h3 class="header-title">{{ params.sprtBizNm }}</h3>
              <p class="header-tags"></p>
            </header>
            <div class="post-view-body">
              <aside class="post-aside">
                <div class="post-aside-wrapper">
                  <div class="post-aside-title post-aside-content">
                    <p class="content-title">지원사업 안내</p>
                    <p class="content-text">{{ params.bizDesc }}</p>
                  </div>
                  <div class="post-aside-content">
                    <p class="content-title">지원기간</p>
                    <p class="content-text">{{ aplyYmd }}</p>
                  </div>
                  <div class="space"></div>
                  <div class="post-aside-buttons">
                    <a :href="params.aplyUrl"  title="새창" target="_blank" class="btn btn-lg w-100 btn-secondary">
                      <span class="text">지원하기</span>
                    </a>
                  </div>
                </div>
              </aside>

              <div class="post-editor">
                <div class="gachi-view" v-html="params.sprtBizCn"></div>
              </div>

            </div>
          </section>
        </div>

        <div class="page-buttons">
          <router-link
              custom
              to="/campaign"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <button style="width: 50px; margin-left: 45%" class="btn btn-lg btn-primary" @click="goList">
              <span class="text">목록</span>
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {mapGetters} from 'vuex';
import {ACT_GET_COMMON_CODE_LIST, ACT_GET_SUPPORT_BUSINESS} from '../../../store/_act_consts';
import {getCheckItems, getItem, lengthCheck, queryToValue, setParams, ymdToFormat} from '../../../assets/js/utils';
import {MUT_SET_COMMON_CODE_ITEMS} from '../../../store/_mut_consts';

export default {
  name: 'SupportView',
  data: () => ({
    sprtBizId: 0,
    params: {
      sprtBizId:0,
      sprtBizNm:'',
      sprtBizYr:'',
      aplyBgngYmd:'',
      aplyEndYmd:'',
      aplyUrl:'',
      sprtBizCn:'',
      sprtPattnDcd: '',
      bizDesc:'',
      instNm:''
    },
    isLoading: false,
  }),
  computed:{
    ...mapGetters('common', ['isMobile', 'commonCode']),
    ...mapGetters('auth', ['isAuth', 'session']),
    aplyYmd() {
      return `${ymdToFormat(this.params.aplyBgngYmd,'.')} ~ ${ymdToFormat(this.params.aplyEndYmd, '.')}`
    },
    sprtPattn(){
      const filtered = this.commonCode.filter(f => this.params.sprtPattnDcd === f.dcd);
      if(filtered.length > 0) return filtered[0].dcdVal
    }
  },
  created() {
    if(this.commonCode.length === 0) this.getCommonCdList();
    this.sprtBizId = this.$route.params.sprtBizId;
    this.getSupprotBiz();
  },
  methods: {
    getCommonCdList(){
      this.$store.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, {}).then(res => {
        this.$store.commit(`common/${MUT_SET_COMMON_CODE_ITEMS}`, getCheckItems(res));
      });
    },
    getSupprotBiz() {
      this.$store.dispatch(`support/${ACT_GET_SUPPORT_BUSINESS}`, this.sprtBizId).then(res=>{
        if(lengthCheck(res)){
          setParams(this.params, getItem(res));
        }
      }).catch(e=>{
        console.error(e);
      })
    },
    goList(){
      this.$router.push({name:'Support'});
    }
  }
};
</script>
