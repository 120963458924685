<template>
  <div class="layer-popup-container">
    <div class="layer-popup" :class="{'gachi-mobile-popup': isMobile}" id="popup-mypage-notice">
      <div>
        <div class="popup-wrapper">
          <header class="popup-header">
            <button type="button" class="btn-close" @click="closeModal">
              <i class="icon-close">팝업닫기</i>
            </button>
          </header>
          <div class="popup-body" :class="{'gachi-popup-md-body': !isMobile}">
            <div class="board-view-container mypage-notice-view">
              <div class="board-view-header">
                <div class="badge">
                  <span class="badge-text">정보</span>
                </div>
                <h3 class="view-header-title" :class="{'mt-3': isMobile, 'mb-3': isMobile}">{{ notice.sprtBizNtcTit }}</h3>
                <span class="view-header-date">{{ getTimestampToDateFormat(notice.regDt) }}</span>
              </div>
              <div class="board-view-content" :class="{'mt-3': isMobile}">
                <div class="gachi-view" v-html="notice.sprtBizNtcCn"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {timestampToDateFormat} from '../../assets/js/utils';
import {MUT_CLOSE_NOTICE_MODAL} from '../../store/_mut_consts';

export default {
  name: 'NoticeModal',
  computed: {
    ...mapGetters('common', ['isMobile']),
    ...mapGetters('support', ['notice'])
  },
  methods: {
    getTimestampToDateFormat(timestamp){
      return timestampToDateFormat(timestamp, 'yyyy.MM.dd');
    },
    closeModal(){
      this.$store.commit(`support/${MUT_CLOSE_NOTICE_MODAL}`);
    }
  }
};
</script>
