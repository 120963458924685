import ApiService from '../../assets/js/api.service';
import {
	ACT_GET_LIVE_SBPTN,
	ACT_SAVE_LIVE_SBPTN
} from '../_act_consts';

const state = {
	moreCmntCrtr: 2,
	liveController: null,
}

const getters = {
	moreCmntCrtr(state) {
		return state.moreCmntCrtr;
	},
}

const mutations = {
	
}

const actions = {
	[ACT_GET_LIVE_SBPTN]({ }, params) {
		return new Promise(resolve => {
			ApiService.query('/v1/app/sbptn/liveSbptn', params).then(response => resolve(response));
		});
	},
	[ACT_SAVE_LIVE_SBPTN]({ }, params) {
		return new Promise(resolve => {
			ApiService.post('/v1/app/sbptn/liveSbptn', params).then(response => resolve(response));
		});
	},

}


export const sbptn = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}