import {ACT_GET_NOTICE} from '../_act_consts';
import ApiService from '../../assets/js/api.service';
import {
  MUT_CLOSE_COMMON_VIDEO_MODAL,
  MUT_SHOW_COMMON_VIDEO_MODAL
} from '../_mut_consts';

const state = {
  video: {
    show: false
  }
}

const getters = {
  video(state) {
    return state.video;
  }
}

const mutations = {
  [MUT_SHOW_COMMON_VIDEO_MODAL](state, video){
    state.video.show = true;
  },
  [MUT_CLOSE_COMMON_VIDEO_MODAL](state, video){
    state.video.show = false;
  }
}

/* const actions = {
  [ACT_GET_NOTICE](){
    return new Promise(resolve => {
      ApiService.query('/v1/app/notice/ntcList').then(response => resolve(response));
    });
  }
} */

export const video = {
  namespaced: true,
  state,
  getters,
  mutations,
  //actions
}
