import {
  ACT_GET_SCOMMUNITY_LIST,
  ACT_GET_SCOMMUNITY_INFO,
  ACT_GET_SCOMMUNITY_USER_ATBT,
  ACT_INSERT_SCOMMUNITY_USER,
  ACT_GET_SCOMMUNITY_POST_LIST,
  ACT_INSERT_SCOMMUNITY_POST,
  ACT_UPDATE_SCOMMUNITY_POST,
  ACT_UPDATE_SCOMMUNITY_POST_TOP,
  ACT_DELETE_SCOMMUNITY_POST,
  ACT_INSERT_SCOMMUNITY_POST_LIK,
  ACT_GET_SCOMMUNITY_POST_RPLY_LIST,
  ACT_INSERT_SCOMMUNITY_POST_RPLY,
  ACT_INSERT_SCOMMUNITY_POST_RRPLY,
  ACT_UPDATE_SCOMMUNITY_POST_RPLY,
  ACT_DELETE_SCOMMUNITY_POST_RPLY,
  ACT_GET_SCOMMUNITY_BRD_MNT,
  ACT_GET_SCOMMUNITY_BRD_LIST,
  ACT_GET_SCOMMUNITY_BRD_INFO,
  ACT_GET_SCOMMUNITY_BRD_ATCH,
  ACT_GET_SCOMMUNITY_PRHB_WRDS,
  ACT_UPLOAD_SCOMMUNITY_POST_IMG,
} from '../_act_consts';
import ApiService from '../../assets/js/api.service';
import {
  MUT_CLOSE_SCOMMUNITY_MAINVIEW,
  MUT_SHOW_SCOMMUNITY_MAINVIEW,
  MUT_SET_SCOMMUNITY_PRHB_WRDS,
  MUT_SET_SCOMMUNITY_USER_ATBT,
} from '../_mut_consts';
  
const state = {
  mainView: {
    show: true
  },
  prhbWrds: [],
  userAtbt: {},
}

const getters = {
  mainView(state) {
      return state.mainView;
  },
  prhbWrds(state){
    return state.prhbWrds;
  },
  userAtbt(state){
    return state.userAtbt;
  },
}

const mutations = {
  [MUT_CLOSE_SCOMMUNITY_MAINVIEW](state){
    state.mainView.show = false;
  },
  [MUT_SHOW_SCOMMUNITY_MAINVIEW](state){
    state.mainView.show = true;
  },
  [MUT_SET_SCOMMUNITY_PRHB_WRDS](state, prhbWrds){
    state.prhbWrds = prhbWrds;
  },
  [MUT_SET_SCOMMUNITY_USER_ATBT](state, userAtbt){
    state.userAtbt = userAtbt;
  }
}

const actions = {
  [ACT_GET_SCOMMUNITY_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/scommunity/list', params).then(response => resolve(response));
    });
  },
  [ACT_GET_SCOMMUNITY_INFO]({}, cmntId){
    return new Promise(resolve => {
      ApiService.get(`/v1/app/scommunity/${cmntId}`, '').then(response => resolve(response));
    });
  },
  [ACT_GET_SCOMMUNITY_USER_ATBT]({}, {cmntId, params}){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/scommunity/${cmntId}/userAtbtInfo`, params).then(response => resolve(response));
    });
  },
  [ACT_INSERT_SCOMMUNITY_USER]({}, {cmntId, params}){
    return new Promise(resolve => {
        ApiService.post(`/v1/app/scommunity/${cmntId}/user`, params, true).then(response => resolve(response));
    });
  },
  [ACT_GET_SCOMMUNITY_POST_LIST]({}, {cmntId, params}){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/scommunity/${cmntId}/post`, params).then(response => resolve(response));
    });
  },
  [ACT_INSERT_SCOMMUNITY_POST]({}, {cmntId, params}){
    return new Promise(resolve => {
        ApiService.post(`/v1/app/scommunity/${cmntId}/post`, params, true).then(response => resolve(response));
    });
  },
  [ACT_UPDATE_SCOMMUNITY_POST]({}, {cmntId, cmntPostId, params}){
    return new Promise(resolve => {
        ApiService.update(`/v1/app/scommunity/${cmntId}/post/${cmntPostId}`, '', params, true).then(response => resolve(response));
    });
  },
  [ACT_UPDATE_SCOMMUNITY_POST_TOP]({}, {cmntId, cmntPostId, params}){
    return new Promise(resolve => {
        ApiService.update(`/v1/app/scommunity/${cmntId}/postTop/${cmntPostId}`, '', params, true).then(response => resolve(response));
    });
  },
  [ACT_DELETE_SCOMMUNITY_POST]({}, {cmntId, cmntPostId, userId}){
    return new Promise(resolve => {
        ApiService.delete(`/v1/app/scommunity/${cmntId}/post/${cmntPostId}`,  userId, true).then(response => resolve(response));
    });
  },
  [ACT_INSERT_SCOMMUNITY_POST_LIK]({}, {cmntId, cmntPostId, userId}){
    return new Promise(resolve => {
        ApiService.post(`/v1/app/scommunity/${cmntId}/post/${cmntPostId}/lik/${userId}`, {}, true).then(response => resolve(response));
    });
  },
  [ACT_GET_SCOMMUNITY_POST_RPLY_LIST]({}, {cmntId, cmntPostId}){
    return new Promise(resolve => {
      ApiService.get(`/v1/app/scommunity/${cmntId}/post/${cmntPostId}/rply`, '').then(response => resolve(response));
    });
  },
  [ACT_INSERT_SCOMMUNITY_POST_RPLY]({}, {cmntId, cmntPostId, params}){
    return new Promise(resolve => {
        ApiService.post(`/v1/app/scommunity/${cmntId}/post/${cmntPostId}/rply`, params, true).then(response => resolve(response));
    });
  },
  [ACT_INSERT_SCOMMUNITY_POST_RRPLY]({}, {cmntId, cmntPostId, cmntPostRplyId, params}){
    return new Promise(resolve => {
        ApiService.post(`/v1/app/scommunity/${cmntId}/post/${cmntPostId}/rply/${cmntPostRplyId}`, params, true).then(response => resolve(response));
    });
  },
  [ACT_UPDATE_SCOMMUNITY_POST_RPLY]({}, {cmntId, cmntPostId, cmntPostRplyId, params}){
    return new Promise(resolve => {
        ApiService.update(`/v1/app/scommunity/${cmntId}/post/${cmntPostId}/rply/${cmntPostRplyId}`, '', params, true).then(response => resolve(response));
    });
  },
  [ACT_DELETE_SCOMMUNITY_POST_RPLY]({}, {cmntId, cmntPostId, cmntPostRplyId, userId}){
    return new Promise(resolve => {
        ApiService.delete(`/v1/app/scommunity/${cmntId}/post/${cmntPostId}/rply/${cmntPostRplyId}`,  userId, true).then(response => resolve(response));
    });
  },
  [ACT_GET_SCOMMUNITY_BRD_MNT]({}, cmntId){
    return new Promise(resolve => {
      ApiService.get(`/v1/app/scommunity/${cmntId}/brd`, '').then(response => resolve(response));
    });
  },
  [ACT_GET_SCOMMUNITY_BRD_LIST]({}, {cmntId, cmntBrdMntId, params}){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/scommunity/${cmntId}/brd/${cmntBrdMntId}`, params).then(response => resolve(response));
    });
  },
  [ACT_GET_SCOMMUNITY_BRD_INFO]({}, {cmntId, cmntBrdMntId, cmntBrdId}){
    return new Promise(resolve => {
      ApiService.get(`/v1/app/scommunity/${cmntId}/brd/${cmntBrdMntId}/info/${cmntBrdId}`, '').then(response => resolve(response));
    });
  },
  [ACT_GET_SCOMMUNITY_BRD_ATCH]({}, {cmntId, cmntBrdMntId, cmntBrdId}){
    return new Promise(resolve => {
      ApiService.get(`/v1/app/scommunity/${cmntId}/brd/${cmntBrdMntId}/atch/${cmntBrdId}`, '').then(response => resolve(response));
    });
  },
  [ACT_GET_SCOMMUNITY_PRHB_WRDS]({}, params){
    return new Promise(resolve => {
      ApiService.get(`/v1/app/scommunity/prhbWrd`, '').then(response => resolve(response));
    });
  },
  [ACT_UPLOAD_SCOMMUNITY_POST_IMG]({}, {cmntId, fileList}){
    return ApiService.upload(`/v1/app/scommunity/${cmntId}/post/image/upload`, fileList);
  },
}

export const scommunity = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};