import {
    ACT_CHECK_CMSTR_APLY,
    ACT_CHECK_DUPLICATE_BUSINESSMAN_REG_NO,
    ACT_CHECK_DUPLICATE_SCM_ID, ACT_GET_CMSTR_APLY_STATUS,
    ACT_INSERT_CMSTR_APLY,
    ACT_INSERT_CMSTR_APLY_DOC,
    ACT_UPDATE_CMSTR_APLY,
    ACT_GET_CMSTR_APLY_PDPLOR,
} from '../_act_consts';
import ApiService from '../../assets/js/api.service';

const actions = {
// {bzmnRegNo, scmMngrPasswd}
    [ACT_CHECK_CMSTR_APLY]({}, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/cmstr/aply', params).then(response => resolve(response));
        });
    },
    [ACT_GET_CMSTR_APLY_STATUS]({}){
        return new Promise(resolve => {
            ApiService.query('/v1/app/cmstr/aply/sts', {}, true).then(response => resolve(response));
        });
    },

    [ACT_INSERT_CMSTR_APLY]({}, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/cmstr/aply', params).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_CMSTR_APLY]({}, params){
        return new Promise(resolve => {
            ApiService.put('/v1/app/cmstr/aply', params).then(response => resolve(response));
        });
    },
    [ACT_GET_CMSTR_APLY_PDPLOR]({}, params){
        return new Promise(resolve => {
            ApiService.get('/v1/app/cmstr/aply/pdPlor', params).then(response => resolve(response));
        });
    },
    [ACT_INSERT_CMSTR_APLY_DOC]({}, formData){
        return ApiService.post('/v1/app/cmstr/docs', formData)
    },
    [ACT_CHECK_DUPLICATE_BUSINESSMAN_REG_NO]({}, bzmnRegNo){
        return new Promise(resolve => {
            ApiService.query('/v1/app/cmstr/dpcnBzmnRegNo',  {bzmnRegNo}).then(response => resolve(response));
        });
    },
    [ACT_CHECK_DUPLICATE_SCM_ID]({}, scmMngrId){
        return new Promise(resolve => {
            ApiService.shopMallAxios.get('/v1/api/if/pd/cmstrAply/dpcnScmMngrId', {params: {scmMngrId: scmMngrId}}).then(response => resolve(response));
        });
    },

}


export const commerce = {
    namespaced: true,
    actions
}
