import ApiService from '../../assets/js/api.service';
import {
  ACT_GET_PLAYGROUND2,
  ACT_GET_PLAYGROUND2_LIST,
  ACT_GET_STUDIO_INFO_LIST,
  ACT_GET_STUDIO_RESERVATION_LIST,
  ACT_GET_STUDIO_RESERVATION_SCHEDULE_LIST,
  ACT_GET_STUDIO_REVIEW_COUNT_LIST,
  ACT_GET_STUDIO_REVIEW_LIST,
  ACT_GET_STUDIO_ROOM,
  ACT_INSERT_STUDIO_RESERVATION,
  ACT_INSERT_STUDIO_REVIEW, ACT_UPDATE_STUDIO_RESERVATION_CANCEL,
} from '../_act_consts';
import {
  MUT_CLOSE_RESERVATION_MODAL, MUT_CLOSE_REVIEW_MODAL,
  MUT_SET_RESERVATION_CATEGORIES,
  MUT_SHOW_RESERVATION_MODAL, MUT_SHOW_REVIEW_MODAL,
} from '../_mut_consts';

const state = {
  reservation: {
    show: false,
    roomId: 0,
    roomName: '',
    rsvtYmd:  '',
    applyDate: {},
    imgPtUrl: null,
    afterFunc: null
  },
  review:{
    show: false,
    roomId: 0,
    rsvtId: 0,
    afterFunc: null
  },
  useCategories: [],
  shootCategories: [],
  strPattnCategories:[],
  osdAddExpctEquCategories:[],
}

const getters =  {
  reservation(state){
    return state.reservation;
  },
  review(state){
    return state.review;
  },
  useCategories(state){
    return state.useCategories;
  },
  shootCategories(state){
    return state.shootCategories;
  },
  strPattnCategories(state){
    return state.strPattnCategories;
  },
  osdAddExpctEquCategories(state){
    return state.osdAddExpctEquCategories;
  }
}

const mutations = {
  [MUT_SHOW_RESERVATION_MODAL](state, reservation){
    reservation.show =true;
    state.reservation = reservation;
  },
  [MUT_CLOSE_RESERVATION_MODAL](state){
    state.reservation.show = false;
  },
  [MUT_SET_RESERVATION_CATEGORIES](state, {key, categories}){
    state[`${key}Categories`] = categories;
  },
  [MUT_SHOW_REVIEW_MODAL](state, review){
    review.show =true;
    state.review = review;
  },
  [MUT_CLOSE_REVIEW_MODAL](state){
    state.review.show = false;
  },
}

const actions = {
  [ACT_GET_STUDIO_INFO_LIST]({}){
    return new Promise(resolve => {
      ApiService.query('/v1/app/studio/infos', {}).then(response => resolve(response));
    });
  },
  // [ACT_GET_STUDIO_ROOM]({}, roomId){
  //   return new Promise(resolve => {
  //     ApiService.get('/v1/app/studio/room', roomId).then(response => resolve(response));
  //   });
  // },
  [ACT_GET_STUDIO_ROOM]({}, roomId){
    return ApiService.get('/v1/app/studio/room', roomId);
  },
  [ACT_GET_STUDIO_RESERVATION_SCHEDULE_LIST]({}, {roomId, params}){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/studio/room/${roomId}/rsvt/schds`, params).then(response => resolve(response));
    });
  },
  [ACT_GET_STUDIO_RESERVATION_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/studio/rsvts`, params, false).then(response => resolve(response));
    });
  },
  [ACT_INSERT_STUDIO_RESERVATION]({}, {roomId, params}){
    return new Promise(resolve => {
      ApiService.post(`/v1/app/studio/room/${roomId}/rsvt`, params, true).then(response => resolve(response));
    });
  },
  [ACT_UPDATE_STUDIO_RESERVATION_CANCEL]({}, params){
    return new Promise(resolve => {
      ApiService.put(`/v1/app/studio/rsvts/sts`, params).then(response => resolve(response));
    });
  },
  [ACT_GET_STUDIO_REVIEW_LIST]({}, studoId){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/studio/${studoId}/revws`, {}).then(response => resolve(response));
    });
  },
  [ACT_GET_STUDIO_REVIEW_COUNT_LIST]({}, studoId){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/studio/${studoId}/revw/cnts`, {}).then(response => resolve(response));
    });
  },
  [ACT_INSERT_STUDIO_REVIEW]({}, {roomId, params}){
    return new Promise(resolve => {
      ApiService.post(`/v1/app/studio/room/${roomId}/revw`, params, true).then(response => resolve(response));
    });
  },
  [ACT_GET_PLAYGROUND2_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/studio/plygrnds2', params).then(response => resolve(response));
    });
  },
  [ACT_GET_PLAYGROUND2]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/studio/plygrnd2', params).then(response => resolve(response));
    });
  },
}

export const studio = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
