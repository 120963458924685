<template>
  <main class="page-container" id="sme-community-view">
    <div class="community-view-body page-component">
      <span class="visually-hidden">게시글 본문 시작</span>
      <div class="community-view-header">
        <div class="header-column header-avatar">
          <div class="avatar">
            <img v-if="session.profileImg" :src="session.profileImg" alt="">
            <img v-else src="../../../../assets/img/community/reply_unknown_icon.svg" alt="">
          </div>
        </div>
        <div class="header-column header-content">
          <span class="title">{{ comunBltartclTit }}</span>
          <!--<span class="date">{{ getRegisterTime(regDt) }}</span>-->
        </div>
        <div class="header-column header-badge">
          <div class="badge">
            <span class="visually-hidden">게시글 카테고리</span>
            <span class="badge-text">{{ comunCtgr }}</span>
          </div>
        </div>
      </div>

      <div class="community-view-body">
        <div id="gachi-editor-container" class="editor-area">
          <div class="gachi-view" v-html="comunBltartclCn"></div>
          <!-- 여기서부터는 실제 에디터 스타일을 변경하여 구현 -->
        </div>
      </div>
<!--      <div class="community-view-actions">-->
<!--        <login-check-button-->
<!--            :classes="['btn']"-->
<!--            :title="'팝업 열림'"-->
<!--            :is-auth="isAuth"-->
<!--            :click-func="showConnect"-->
<!--        >-->
<!--          <i class="icon-message"></i>-->
<!--          <span class="text text-primary">연락하기</span>-->
<!--        </login-check-button>-->
<!--      </div>-->


      <div class="comment-container">
        <span class="visually-hidden">댓글 영역 시작</span>
        <div class="comment-list">

          <div v-for="(comment, idx) in comments" class="comment-item gachi-comment-list" :key="idx">
            <div class="comment-column avatar">
              <img v-if="comment.imgPtUrl" :src="comment.imgPtUrl" :alt="`${comment.userNm} 이미지`" />
              <img v-else src="../../../../assets/img/community/reply_unknown_icon.svg" :alt="`${comment.userNm} 이미지`" />
            </div>
            <div class="comment-column contents">
              <div class="comment-author">
                <span class="comment-author-text">{{ getWriterMask(comment.userNm) }}</span>
                <div v-if="comment.manager" class="badge">
                  <span class="badge-text">운영자</span>
                </div>
                <div v-if="session.userId === userId" class="badge">
                  <span class="badge-text">작성자</span>
                </div>
                <div v-if="session.userId === comment.userId" class="badge">
                  <span class="badge-text">본인</span>
                </div>
                <div v-if="session.userId === comment.userId" class="badge" style="background-color: #ff7300">
                  <button class="badge-text" style="color: #ffffff !important" @click="clickUpdateComment(comment.cmntId)">수정</button>
                </div>
                <div v-if="session.userId === comment.userId" class="badge" style="background-color: #ff4e4e">
                  <button class="badge-text" style="color: #ffffff !important" @click="clickRemoveComment(comment.cmntId)">삭제</button>
                </div>
              </div>

              <div class="comment-date">
                <span class="comment-date">{{ getRegisterTime(comment.regDt) }}</span>
              </div>
              <div class="comment-content">
                <p class="comment-text">{{ comment.cmntCn }}</p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="hasMore" class="page-buttons gachi-comment" data-align="center" @click="morePage">
          <button class="btn btn-outline-white">
            <span class="text">더보기</span>
          </button>
        </div>

        <div  class="comment-write">
          <div v-if="isAuth" class="comment-item">
            <div class="comment-column avatar">
              <img src="../../../../assets/img/community/reply_unknown_icon.svg" alt="프로필 기본 이미지" />
            </div>
            <div class="comment-column contents">
              <div class="comment-author">
                <span class="comment-author-text">{{ session.userNm }}</span>
                <div v-if="session.userId === userId" class="badge">
                  <span class="badge-text">작성자</span>
                </div>
              </div>
              <div class="comment-input">
                <textarea v-model="cmntCn" cols="30" rows="1" class="form-control comment-input-textarea" placeholder="댓글을 입력하세요"></textarea>
                <div class="comment-input-actions">
                  <div class="comment-input-byte">
                    <span class="byte text-dark">{{ cmntLen }}</span>
                    <span class="byte">/ 300</span>
                  </div>
                  <button v-if="!this.modify" type="button" class="btn btn-primary btn-sm" :disabled="(cmntLen === 0)" @click="insertCommunityComment">
                    <span class="text">등록</span>
                  </button>
                  <button v-else type="button" class="btn btn-primary btn-sm" :disabled="(cmntLen === 0)" @click="ClickupdateCommunityComment">
                    <span class="text">수정</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page-buttons" data-align="space-between">
        <div v-if="isAuth && session.userId === userId"  class="button-column">
          <button class="btn btn-lg btn-outline-white" style="margin-right: 5px" @click="goEdit">
            <span class="text text-secondary">수정</span>
          </button>
          <loading-button
              button-text="삭제"
              :is-loading="isRmLoading"
              :click-func="clickRemove"
              :button-classes="['btn-lg', 'btn-secondary']"
              :text-classes="[]"
              loading-size="sm"
          ></loading-button>
        </div>
        <div class="page-buttons" data-align="center" style="flex-direction: row;width: 900px;margin: auto">
          <a href="javascript:">
            <next-prev-button
                route-name="CommunityView"
                param-key="comunBltartclId"
                queryKey="ctgrCode"
                :query="$route.query.ctgrCode"
                :pre-id="preId"
                :next-id="nextId"
            ></next-prev-button>
          </a>
        </div>
        <a href="javascript:">
          <div class="list-btn-cont" style="width: 100px;position: absolute;left: calc(50% - 50px);top: calc(100% - 100px);">
            <button class="btn btn-lg btn-primary" @click="goList">
              <span class="text">목록</span>
            </button>
          </div>
        </a>
<!--        <div class="button-column">-->
<!--          <button class="btn btn-lg btn-primary" @click="goList">-->
<!--            <span class="text">목록</span>-->
<!--          </button>-->
<!--        </div>-->
      </div>
    </div>
  </main>
</template>
<script>
import {
  ACT_DELETE_SB_COMMUNITY, ACT_DELETE_SB_COMMUNITY_COMMENT,
  ACT_GET_SB_COMMUNITY,
  ACT_GET_SB_COMMUNITY_COMMENT_LIST,
  ACT_INSERT_SB_COMMUNITY_COMMENT, ACT_UPDATE_SB_COMMUNITY_COMMENT,
} from '../../../../store/_act_consts';
import {
  getItem,
  getItems,
  getRegisterTimeFormat,
  getResult,
  goToTop,
  isSuccess,
  lengthCheck, queryToValue,
  setPaging,
  writerMask,
} from '../../../../assets/js/utils';
import {mapGetters} from 'vuex';
import {slideImageInit} from '../../../../assets/js/ckeditor4/ckeditor-slide-image';
import {MUT_SHOW_ALERT, MUT_SHOW_CONFIRM, MUT_SHOW_CONNECT} from '../../../../store/_mut_consts';
import LoadingButton from '../../../../components/common/LoadingButton';
import LoginCheckButton from '../../../../components/common/LoginCheckButton';
import NextPrevButton from "@/components/common/NextPrevButton";

export default {
  name: 'MobileCommunityView',
  components: {
    NextPrevButton,
    LoadingButton,
    LoginCheckButton
  },
  data: () => ({
    comunBltartclId: 0,
    comunCtgr: '',
    comunBltartclTit: '',
    comunBltartclCn: '',
    regDt: 0,
    userId: 0,

    comments: [],
    pageNo: 1,
    pageSize: 999,
    hasMore: false,
    totalCount: 0,
    cmntCn: '',
    isRmLoading: false,
    preId: 0,
    nextId: 0,
    modify: false,
  }),
  watch: {
    cmntLen(){
      if(this.cmntLen > 300){
        this.cmntCn = this.cmntCn.substr(0, 300);
      }
    },
    $route(to){
      this.comunBltartclId = queryToValue(to.params.comunBltartclId, true, 0);
      this.getSbCommunity();
    }
  },
  computed: {
    ...mapGetters('auth', ['isAuth', 'session']),
    cmntLen(){
      return this.cmntCn.length;
    }
  },
  created() {
    goToTop(400);

    if(this.$route.params.comunBltartclId) this.comunBltartclId = this.$route.params.comunBltartclId;
    setTimeout(()=>{
      this.getSbCommunity();
    },500)
    this.getSbCommunityCommentList();
  },
  methods: {
    goList(){
      this.$router.push({name: 'Community', query:{ctgrCode:this.$route.query.ctgrCode}});
    },
    goEdit(){
      this.$router.push({name: 'CommunityEdit', params: {comunBltartclId: this.comunBltartclId}});
    },
    getSbCommunity(){
      this.$store.dispatch(`community/${ACT_GET_SB_COMMUNITY}`, {
        comunBltartclId: this.comunBltartclId,
        params: {
          comunCtgrDcd: this.$route.query.ctgrCode
        }
      }).then(res => {
        if(lengthCheck(res)){
          const item = getItem(res);
          this.comunCtgr = item.comunCtgr;
          this.comunBltartclTit = item.comunBltartclTit;
          this.comunBltartclCn = item.comunBltartclCn;
          this.regDt = item.regDt;
          this.userId = item.userId;
          this.preId = item.preId;
          this.nextId = item.nextId;
          this.setSlideImage();
        }else{
          this.comunBltartclId = 0;
        }
      }).catch(e => {
        console.error(e);
        this.comunBltartclId = 0;
      });
    },
    getRegisterTime(timestamp) {
      return getRegisterTimeFormat(timestamp);
    },
    setSlideImage(){
      setTimeout(() => {
        slideImageInit(document.getElementById('gachi-editor-container'));
      }, 500);
    },
    getSbCommunityCommentList(isInit, pageSize){
      if(isInit) this.comments = [];
      this.$store.dispatch(`community/${ACT_GET_SB_COMMUNITY_COMMENT_LIST}`, {
        comunBltartclId: this.comunBltartclId,
        params: {
          pageNo: this.pageNo,
          pageSize: (pageSize ? pageSize : this.pageSize)
        }
      }).then(res => {
        if(lengthCheck(res)){
          this.comments = this.comments.concat(getItems(res));
          setPaging(this, res);
        }
      }).catch(e => {
        console.error(e);
        this.hasMore = false;
      });
    },
    morePage(){
      if(this.hasMore) {
        this.pageNo++;
        this.getSbCommunityCommentList();
      }
    },
    // strByteLength(s,b,i,c){
    //   for(b=i=0;c=s.charCodeAt(i++);b+=c>>11?3:c>>7?2:1);
    //   return b
    // }
    insertCommunityComment(){
      if(this.comunBltartclId > 0 && this.cmntLen > 0){
        this.$store.dispatch(`community/${ACT_INSERT_SB_COMMUNITY_COMMENT}`, {
          comunBltartclId: this.comunBltartclId,
          params: { cmntCn: this.cmntCn }
        }).then(res => {
          if(isSuccess(res)){
            this.comments.push({
              cmntCn: this.cmntCn,
              cmntId: res.cmntId,
              comunBltartclId: this.comunBltartclId,
              manager: (this.session.userPattnCd === '100'),
              regDt: new Date().getTime(),
              userId: this.session.userId,
              userNm: this.session.userNm,
              nickNm: this.session.nickNm
            });
            this.cmntCn = '';
          }
        }).catch(e => {
          console.error(e);
        });
      }
    },
    clickRemove(){
      if(this.isRmLoading) return;
      this.isRmLoading = true;

      this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
        title: '게시글을 삭제하시겠습니까?',
        // text: '삭제된 캠페인은 원상복구 할 수 없습니다.',
        showCancelButton: true,
        yesfunc: this.deleteSbCommunity,
        nofunc: () => {this.isRmLoading = false;}
      });
    },
    deleteSbCommunity(){
      this.$store.dispatch(`community/${ACT_DELETE_SB_COMMUNITY}`, this.comunBltartclId).then(res => {
        this.isRmLoading = false;
        if(isSuccess(res)){
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
            title: `게시글이 삭제되었습니다.`,
            yesfunc: this.goList,
            rightNow: true
          });
        }else{
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: getResult(res).message});
        }
      }).catch(e => {
        console.error(e);
        this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '오류가 발생했습니다. 다시 시도해주세요.'});
        this.isRmLoading = false;
      })
    },

    clickRemoveComment(cmntId){
      this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
        title: '댓글을 삭제하시겠습니까?',
        showCancelButton: true,
        yesfunc: ()=> {
          this.deleteComment(cmntId);
        },
        nofunc: () => {this.isRmLoading = false;}
      });
    },
    deleteComment(cmntId){
      this.$store.dispatch(`community/${ACT_DELETE_SB_COMMUNITY_COMMENT}`, {
        comunBltartclId: this.comunBltartclId,
        cmntId: cmntId
      }).then(res=>{
        if(isSuccess(res)){
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
            title: `삭제되었습니다.`,
            yesfunc: this.initComment,
            rightNow: true
          });
        }
        console.log(res);
      }).catch(e => {
        console.error(e);
        this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '오류가 발생했습니다. 다시 시도해주세요.'});
      })
    },
    initComment(){
      this.pageNo = 1;
      this.cmntCn ="";
      this.modify = false;
      this.getSbCommunityCommentList(true);
    },
    clickUpdateComment(cmntId){
      this.cmntCn = this.comments.filter(x => (x.cmntId === cmntId))[0].cmntCn;
      this.modify = true;
      this.modifyCmntId = cmntId;
    },
    ClickupdateCommunityComment() {
      if(this.comunBltartclId > 0 && this.cmntLen > 0){
        this.$store.dispatch(`community/${ACT_UPDATE_SB_COMMUNITY_COMMENT}`, {
          comunBltartclId: this.comunBltartclId,
          params: { cmntCn: this.cmntCn, cmntId: this.modifyCmntId, }
        }).then(res => {
          if(isSuccess(res)){
            this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
              title: `수정되었습니다.`,
              yesfunc: this.initComment,
              rightNow: true
            });
          }
        }).catch(e => {
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '오류가 발생했습니다. 다시 시도해주세요.'});
        });
      }
    },

    showConnect(){
      this.$store.commit(`common/${MUT_SHOW_CONNECT}`, {
        rcvrUserId: this.userId,
        rltdTblNo: 2,
        rltdTblKey: this.comunBltartclId
      });

    },
    /*
      작성자 마스킹 처리 (20220831 hib)
     */
     getWriterMask(autho){
      return writerMask(autho);
    }

  }
}
</script>
