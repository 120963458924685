<template>
	<div class="layer-popup-container gachi-layer-popup-container">
		<div class="layer-popup" id="popup-sso-reconsent">
			<div>
				<div class="popup-wrapper">
					<header class="popup-header">
						<h3 class="header-title">통합기업 회원 전환을 위한 서비스 재동의 안내</h3>
						<button type="button" class="btn-close" @click="closeModal">
							<i class="icon-close">팝업닫기</i>
						</button>
					</header>
					<div class="popup-body" :class="{ 'is-pc': !isMobile }">
						<p class="popup-body-desc">기존 가치삽시다의 일반회원 분들을 대상으로 플랫폼 서비스 전체와 판판대로 서비스를 모두 사용할 수 있는 통합기업 회원 전환을 아래 신규 서비스 약관에 재동의를 하시면 진행할 수 있습니다.</p>
						<div class="form-checkbox is-round is-largetext">
							<input type="checkbox" v-model="allAgree" id="check11" @change="allAgreeClick" />
							<label for="check11">모두 동의합니다</label>
						</div>
						<!-- s: 이용약관에 재동의 -->
						<section class="section">
							<div class="section-header">
								<h4 class="title">
									<div class="form-checkbox is-round">
										<input type="checkbox" v-model="termsAgree" id="check12" @change="agreeClick" />
										<label for="check12">
											<span>이용약관에 재동의<em class="color-orange">(필수)</em></span>
										</label>
									</div>
								</h4>
							</div>
							<div class="section-content">
								<!-- 2024-08-21 수정 이용약관 -->
								<terms-of-use />
							</div>
						</section>
						<!-- e: 이용약관에 재동의 -->
						<!-- s: 개인정보 수집 및 이용에 재동의 -->
						<section class="section">
							<div class="section-header">
								<h4 class="title">
									<div class="form-checkbox is-round">
										<input type="checkbox" v-model="privacyAgree" id="check13" @change="agreeClick" />
										<label for="check13">
											<span>개인정보 수집 및 이용에 재동의<em class="color-orange">(필수)</em></span>
										</label>
									</div>
								</h4>
							</div>
							<div class="section-content">
								<div class="terms-wrap">
									<section class="terms-section">
										<h4 class="terms-section-title">통합기업회원용</h4>
										<p>
											본 기관은 가치삽시다 회원가입 신청을 위하여 아래와 같이 개인정보를 수집·이용하고자 합니다. <br />
											내용을 자세히 읽으신 후 동의여부를 결정하여 주십시오.
										</p>
										개인정보 수집·이용 내역<br />
										<div class="table-responsive">
											<table class="table is-bordered">
												<caption>
													수집·이용 항목, 수집·이용 목적, 보유기간 목록
												</caption>
												<colgroup>
                          <col style="width: 20%" />
                          <col style="width: 30%" />
                          <col style="width: 30%" />
                          <col style="width: 20%" />
												</colgroup>
												<thead>
													<tr>
                            <th scope="col">구분</th>
														<th scope="col">수집·이용 항목</th>
														<th scope="col">수집·이용 목적</th>
														<th scope="col">보유기간 목록</th>
													</tr>
												</thead>
												<tbody>
													<tr>
                            <td>통합기업회원 서비스이용</td>
														<td>
															[필수] <br />
                              통합기업회원 아이디, 비밀번호,<br/>
                              (담당자)성명,<br/>
                              (담당자)휴대전화번호,<br/>
                              (담당자)이메일,<br/>
                              사용자 인증(CI),<br/>
                              (대표자)성명,<br/>
                              사업자등록번호
															<br />
															[선택]<br />
															회사명, 법인등록번호
														</td>
														<td>
															1. 회원 관리<br />
															2. 민원사무 처리<br />
															3. 재화 또는 서비스 제공<br />
															4. 마케팅 및 광고 활용<br />
															5. 지원사업 관리
														</td>
														<td>
															<span class="color-orange">3년</span><br />
															<span class="color-orange">(회원탈퇴시까지)</span>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<p>※ 동의를 거부할 권리 및 거부할 경우의 불이익 : 위 개인정보의 수집·이용에 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 저희가 제공하는 서비스 이용에 제한을 받을 수 있습니다.</p>
									</section>
<!--									<section class="terms-section">-->
<!--										<h4 class="terms-section-title">일반회원용</h4>-->
<!--										<p>본 기관은 가치삽시다 회원가입 신청을 위하여 아래와 같이 개인정보를 수집·이용하고자 합니다. 내용을 자세히 읽으신 후 동의여부를 결정하여 주십시오.</p>-->
<!--										개인정보 수집·이용 내역<br />-->
<!--										<div class="table-responsive">-->
<!--											<table class="table is-bordered">-->
<!--												<caption>-->
<!--													수집·이용 항목, 수집·이용 목적, 보유기간 목록-->
<!--												</caption>-->
<!--												<colgroup>-->
<!--													<col style="width: 50%" />-->
<!--													<col style="width: 30%" />-->
<!--													<col style="width: 20%" />-->
<!--												</colgroup>-->
<!--												<thead>-->
<!--													<tr>-->
<!--														<th scope="col">수집·이용 항목</th>-->
<!--														<th scope="col">수집·이용 목적</th>-->
<!--														<th scope="col">보유기간 목록</th>-->
<!--													</tr>-->
<!--												</thead>-->
<!--												<tbody>-->
<!--													<tr>-->
<!--														<td>-->
<!--															[필수] <br />-->
<!--															아이디, 비밀번호, 담당자성명, 담당자휴대폰번호, 담당자이메일, e-러닝 정보제공-->

<!--															<br />-->
<!--															[선택]<br />-->
<!--															담당자전화번호-->
<!--														</td>-->
<!--														<td>-->
<!--															1. 회원 관리<br />-->
<!--															2. 민원사무 처리<br />-->
<!--															3. 재화 또는 서비스 제공<br />-->
<!--															4. 마케팅 및 광고 활용<br />-->
<!--															5. 지원사업 관리-->
<!--														</td>-->
<!--														<td>-->
<!--															<span class="color-orange">3년</span><br />-->
<!--															<span class="color-orange">회원 탈퇴 시 까지</span>-->
<!--														</td>-->
<!--													</tr>-->
<!--												</tbody>-->
<!--											</table>-->
<!--										</div>-->
<!--										<p>※ 위와 같이 가치삽시다 서비스 이용에 필요한 최소한의 개인정보 수집·이용에 동의하지 않을 권리가 있으며, 동의 거부 시 원활한 서비스 제공과 이용에 일부 제한을 받을 수 있습니다.</p>-->
<!--									</section>-->
								</div>
							</div>
						</section>
						<!-- e: 개인정보 수집 및 이용에 재동의 -->
					</div>
					<div class="popup-buttons pd0">
						<button type="button" class="button-default is-large is-primary is-rounded" @click="confirmClick">확인</button>
					</div>

					<div class="popup-checkfooter">
						<div class="form-checkbox">
							<input type="checkbox" v-model="noShow" id="chkNoShow" />
							<label for="chkNoShow">3일동안 보지 않기</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACT_UPDATE_USER_RETERMS_AGREE } from '../../store/_act_consts';
import { MUT_SHOW_ALERT, MUT_CLOSE_RETERMS_MODAL } from '../../store/_mut_consts';
import { getResult } from '../../assets/js/utils';
import TermsOfUse from '../../views/pages/user/TermsOfUse';

export default {
	name: 'SsoReconsentModal',
	components: { TermsOfUse },
	data: () => ({
		isLoading: false,
		allAgree: false,
		termsAgree: false,
		privacyAgree: false,
		noShow: false,
	}),
	computed: {
		...mapGetters('auth', ['session']),
		...mapGetters('common', ['isMobile']),
	},
	mounted() {},
	watch: {},
	created() {},
	methods: {
		allAgreeClick() {
			if (this.allAgree) {
				this.termsAgree = true;
				this.privacyAgree = true;
			} else {
				this.termsAgree = false;
				this.privacyAgree = false;
			}
		},
		agreeClick() {
			if (this.privacyAgree && this.termsAgree) {
				this.allAgree = true;
			} else {
				this.allAgree = false;
			}
		},
		noShowClick() {
			if (this.noShow) {
				this.$cookies.set('gachi-reterms-popup', 1, '3d');
				this.$store.commit(`auth/${MUT_CLOSE_RETERMS_MODAL}`);
			}
		},
		confirmClick() {
			if (this.allAgree) {
				this.$store.dispatch(`auth/${ACT_UPDATE_USER_RETERMS_AGREE}`, {}).then((res) => {
					if (getResult(res).number === 200) {
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: `약관 재동의 완료되었습니다.` });
						this.$router.push({ name: 'ConvertMembership' });
					} else {
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: getResult(res).message });
					}
				});
			} else {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: `약관 동의를 체크해주세요.` });
			}
		},
		closeModal() {
			if (this.noShow) {
				this.$cookies.set('gachi-reterms-popup', 1, '3d');
			}
			this.$store.commit(`auth/${MUT_CLOSE_RETERMS_MODAL}`);
		},
	},
};
</script>
