<template>
  <main class="page-container" id="sme-campaign-view" >
    <div class="page-component">
      <div class="page-body">
        <div class="post-view-container" ref="view-container">
          <section class="post-view-wrapper">
            <header class="post-view-header">
              <div class="header-category">
              </div>
              <h3 class="header-title">{{ params.sprtBizNm}}</h3>
            </header>
            <div class="badge_wrap">
              <span v-if="params.instNm !==''" class="badge-text">{{params.instNm}}</span>
            </div>
            <div class="post-view-body">
              <article class="post-campaing">
                <div class="post-campaing-wrapper">
                  <h4 class="post-campaing-title">지원사업 안내</h4>
                  <p class="content-text">{{ params.bizDesc }}</p>
                  <div class="post-campaing-content">
                    <p class="content-row">
                      <span class="content-title">지원기간</span>
                      <span class="content-text">{{ aplyYmd }}</span>
                    </p>
                  </div>

                  <div class="post-campaing-buttons" :class="{'is-deactive': isDeactive}">
                    <!-- 220608 디자인 수정 -->
                    <aside class="post-aside apply_wrap">
                      <div class="post-aside-wrapper">
                        <div class="post-aside-title post-aside-content">
                          <p class="content-title">지원사업 안내</p>
                          <p class="content-text">{{ params.bizDesc }}</p>
                        </div>
                        <div class="post-aside-content">
                          <p class="content-title">지원기간</p>
                          <p class="content-text">{{ aplyYmd }}</p>
                        </div>
                        <div class="post-aside-buttons">
                          <button
                              class="btn btn-lg w-100 btn-secondary"
                              title="'팝업 열림'"
                              @click="clickApply"
                          >
                            <span class="text">지원하기</span>
                          </button>
                        </div>
                      </div>
                    </aside>
                    <!-- // 220608 디자인 수정 -->
                  </div>

                </div>
              </article>
              <div class="post-editor">
                <div class="gachi-view" v-html="params.sprtBizCn"></div>
              </div>
            </div>
          </section>
        </div>
        <div class="page-buttons" data-direction="column">
          <button
              class="btn btn-lg btn-secondary gachi-cursor-none"
              title="'팝업 열림'"
              @click="clickApply"
          >
            <span class="text">지원하기</span>
          </button>
          <router-link
              custom
              to="/support"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <button class="btn btn-lg btn-primary" @click="goList">
              <span class="text">목록</span>
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {mapGetters} from 'vuex';
import {
  ACT_GET_CAMPAIGN,
  ACT_GET_CATEGORY_LIST,
  ACT_GET_COMMON_CODE_LIST, ACT_GET_SUPPORT_BUSINESS,
  ACT_INSERT_CAMPAIGN_APPLY,
} from '../../../../store/_act_consts';
import {
  getCheckItems,
  getItem,
  getRegisterTimeFormat,
  getResult,
  isSuccess,
  lengthCheck, queryToValue,
  setParams, ymdToFormat,
} from '../../../../assets/js/utils';
import {
  MUT_SET_CATEGORY_LIST,
  MUT_SET_COMMON_CODE_ITEMS,
  MUT_SHOW_ALERT,
  MUT_SHOW_CONFIRM,
} from '../../../../store/_mut_consts';
import LoginCheckButton from '../../../../components/common/LoginCheckButton';

export default {
  name: 'MobileSupportView',
  components: {
    LoginCheckButton
  },
  data: () => ({
    scrollPosition:0,
    sprtBizId: 0,
    params: {
      sprtBizId:0,
      sprtBizNm:'',
      sprtBizYr:'',
      aplyBgngYmd:'',
      aplyEndYmd:'',
      aplyUrl:'',
      sprtBizCn:'',
      sprtPattnDcd: '',
      bizDesc:'',
      instNm:''
    },
    isLoading: false,
    isDeactive: false
  }),
  computed:{
    ...mapGetters('common', ['isMobile', 'commonCode']),
    ...mapGetters('auth', ['isAuth', 'session']),
    aplyYmd() {
      return `${ymdToFormat(this.params.aplyBgngYmd,'.')} ~ ${ymdToFormat(this.params.aplyEndYmd, '.')}`
    },
    sprtPattn(){
      const filtered = this.commonCode.filter(f => this.params.sprtPattnDcd === f.dcd);
      if(filtered.length > 0) return filtered[0].dcdVal
    }
  },
  created() {

    if(this.commonCode.length === 0) this.getCommonCdList();
    if(this.$route.params.sprtBizId) this.sprtBizId = queryToValue(this.$route.params.sprtBizId, true);
    setTimeout(()=>{
      this.getSupprotBiz();
    },500)
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.updateScroll);
  },
  methods: {
    updateScroll(){
      this.scrollPosition = window.scrollY;
      if(this.$refs['view-container'] !== undefined){
        const containerHeight = this.$refs['view-container'].getBoundingClientRect().height;
        this.isDeactive = (this.scrollPosition > (containerHeight - window.innerHeight));
      }
    },
    getCommonCdList(){
      this.$store.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, {}).then(res => {
        this.$store.commit(`common/${MUT_SET_COMMON_CODE_ITEMS}`, getCheckItems(res));
      });
    },
    getSupprotBiz() {
      this.$store.dispatch(`support/${ACT_GET_SUPPORT_BUSINESS}`, this.sprtBizId).then(res=>{
        if(lengthCheck(res)){

          setParams(this.params, getItem(res));
        }
      }).catch(e=>{
        console.error(e);
      })
    },
    goList(){
      this.$router.push({name:'Support'});
    },
    clickApply(){
      window.open(this.params.aplyUrl)
    }
  }
};
</script>