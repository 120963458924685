import {ACT_GET_EDU_COURSE_LIST, ACT_POST_EDU_SSO} from '../_act_consts';
import ApiService from '../../assets/js/api.service';

const state = {}

const actions = {
  [ACT_GET_EDU_COURSE_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/main/courses`, params).then(response => resolve(response));
    });
  },
  [ACT_POST_EDU_SSO]({}){
    return new Promise(resolve => {
      ApiService.post(`/v1/app/auth/sso/encrypt`).then(response => resolve(response));
    });
  }
}

export const education = {
  namespaced: true,
  state,
  actions
}