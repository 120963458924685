<template>
  <div class="layer-popup-container gachi-layer-popup-container">
    <div class="layer-popup" :class="{'gachi-mobile-popup': isMobile}" id="popup-mypage-dm">
      <div>
        <div class="popup-wrapper">
          <header class="popup-header">
            <h3 class="header-title">연락함</h3>
            <button type="button" class="btn-close" @click="closeModal">
              <i class="icon-close">팝업닫기</i>
            </button>
          </header>
          <div class="popup-body">
            <div class="mypage-dm-card">
              <div class="card-image">
                <a class="image-link">
                  <img v-if="connectView.imgPtUrl" :src="connectView.imgPtUrl" alt="">
                  <img v-else src="../../assets/img/community/reply_unknown_icon.svg" alt="">
                </a>
              </div>
              <div class="card-content">
                <a class="sender-link" data-anim="true">
                  <span class="text sender">{{ connectView.userNm }}</span>
                  <span class="text">{{ connectView.regDt }}</span>
                </a>
              </div>
            </div>
            <div class="mypage-dm-contents">
              <pre style="text-align: left" class="livestudio-warning-description gachi-pre">{{ connectView.msgCn }}</pre>
            </div>
          </div>
          <div class="popup-buttons">
            <button class="btn btn-outline-white btn-lg" @click="clickRemove">
              <span class="text text-secondary">삭제</span>
            </button>
            <button v-if="(session.prtlMngrSiteCntnPssYn === 'Y' && session.userPattnCd === '100' && !(connectView.userNm == session.userNm))" class="btn btn-primary btn-lg" @click="clickReSend">
              <span class="text">답변쓰기</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {
  MUT_CLOSE_CONNECT_VIEW,
  MUT_SHOW_CONFIRM,
  MUT_SHOW_ALERT, MUT_SHOW_CONNECT,
} from '../../store/_mut_consts';
import {ACT_DELETE_MESSAGE, ACT_UPDATE_READG_MESSAGE} from '../../store/_act_consts';
import {getRegisterTimeFormat, getResult, isSuccess} from '../../assets/js/utils';

export default {
  name: 'ConnectViewModal',
  computed: {
    ...mapGetters('common', ['isMobile', 'connectView']),
    ...mapGetters('auth', ['session']),
  },
  data: ()=>({
    isRmLoading: false
  }),
  created() {
    this.getConnect();
  },
  methods: {
    getConnect(){
      if(this.connectView.readgYn === 'N'){
        this.$store.dispatch(`common/${ACT_UPDATE_READG_MESSAGE}`, {
          msgId: this.connectView.msgId,
        }).then(res=>{
          if(isSuccess(res)){
            if(this.connectView.readgFunc instanceof Function) this.connectView.readgFunc();
          }
        }).catch(e=>{
          console.error(e);
        });
      }
    },
    closeModal(){
      this.$store.commit(`common/${MUT_CLOSE_CONNECT_VIEW}`);
    },
    clickRemove(){
      if(this.isRmLoading) return;
      this.isRmLoading = true;

      this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
        title: '해당 메시지를 삭제하시겠습니까?',
        showCancelButton: true,
        yesfunc: this.deleteMsg,
        nofunc: () => {this.isRmLoading = false;}
      });
    },
    deleteMsg() {
      this.$store.dispatch(`common/${ACT_DELETE_MESSAGE}`, this.connectView.msgId)
      .then(res=>{
        if(isSuccess(res)){
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
            title: `메시지가 삭제되었습니다.`,
            yesfunc: () => {
              if(this.connectView.deleteMsgFunc instanceof Function) this.connectView.deleteMsgFunc();
              this.closeModal();
            },
            rightNow: true
          });
        }else{
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: getResult(res).message});
        }
      }).catch(e => {
        console.error(e);
        this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '오류가 발생했습니다. 다시 시도해주세요.'});
        this.isRmLoading = false;
      })
    },
    clickReSend() {
      this.$store.commit(`common/${MUT_SHOW_CONNECT}`, {
        rcvrUserId: this.connectView.userId,
        relMsgId: this.connectView.msgId,
        rltdTblNo: 4,
        rltdTblKey: this.connectView.msgId,
        msgCn: this.connectView.msgCn
      });
      this.closeModal();
    },
    registerTimeFormat(timestamp){
      return getRegisterTimeFormat(timestamp);
    }
  }
};
</script>
