import {
  ACT_GET_LIVE_VIDEO_LIST,
  ACT_GET_BRC_INFO,
  ACT_GET_PROG_BANNER_LIST,
  ACT_GET_PROG_SMSUSER_LIST,
  ACT_INSERT_PROG_SMSUSER,
  ACT_INSERT_ALL_PROG_SMSUSER,
  ACT_DELETE_PROG_SMSUSER,
  ACT_DELETE_ALL_PROG_SMSUSER,
  ACT_GET_PROG_SCHEDULE_LIST,
  ACT_INSERT_RECOMM_CNT,
  ACT_GET_LIVE_COMMERCE_LIST,
  ACT_INSERT_BRC_DCLR
} from '../_act_consts';
import ApiService from '../../assets/js/api.service';
import {
  MUT_CLOSE_BROADCAST_CALENDAR_MODAL,
  MUT_SHOW_BROADCAST_CALENDAR_MODAL,
  MUT_CLOSE_BROADCAST_LIVE_MODAL,
  MUT_SHOW_BROADCAST_LIVE_MODAL,
  MUT_CLOSE_BROADCAST_VOD_MODAL,
  MUT_SHOW_BROADCAST_VOD_MODAL,
  MUT_SET_RETURN_BRC_INFO_NO
} from '../_mut_consts';

const state = {
  broadcastCalendar: {
    show: false
  },
  broadcastLive: {
    show: false
  },
  broadcastVod: {
    show: false
  },
  returnBrcInfoNo : 0,

  previewTime: 3 * 60, //비회원 3분 미리보기
  shortsBrcTyp: 3,
}

const getters = {
  broadcastCalendar(state) {
      return state.broadcastCalendar;
  },
  broadcastLive(state) {
    return state.broadcastLive;
  },
  broadcastVod(state) {
    return state.broadcastVod;
  },
  returnBrcInfoNo(state) {
    return state.returnBrcInfoNo;
  },
  previewTime(state) {
    return state.previewTime;
  },
  shortsBrcTyp(state) {
    return state.shortsBrcTyp;
  },
}

const mutations = {
  [MUT_SHOW_BROADCAST_CALENDAR_MODAL](state, broadcastCalendar){
      state.broadcastCalendar.show = true;
  },
  [MUT_CLOSE_BROADCAST_CALENDAR_MODAL](state, broadcastCalendar){
    state.broadcastCalendar.show = false;
  },
  [MUT_SHOW_BROADCAST_LIVE_MODAL](state, broadcastLive){
    state.broadcastLive.show = true;
  },
  [MUT_CLOSE_BROADCAST_LIVE_MODAL](state, broadcastLive){
    state.broadcastLive.show = false;
  },
  [MUT_SHOW_BROADCAST_VOD_MODAL](state, broadcastVod){
    state.broadcastVod.show = true;
  },
  [MUT_CLOSE_BROADCAST_VOD_MODAL](state, broadcastVod){
    state.broadcastVod.show = false;
  },
  [MUT_SET_RETURN_BRC_INFO_NO] (state, returnBrcInfoNo) {
      state.returnBrcInfoNo = returnBrcInfoNo;
  },
}

const actions = {
  [ACT_GET_LIVE_VIDEO_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/broadcast/list', params).then(response => resolve(response));
    });
  },
  [ACT_GET_LIVE_COMMERCE_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/broadcast/lc', params).then(response => resolve(response));
    });
  },
  [ACT_GET_BRC_INFO]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/broadcast/brcInfo', params).then(response => resolve(response));
    });
  },
  [ACT_GET_PROG_BANNER_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/broadcast/sms/progbannrs', params).then(response => resolve(response));
    });
  },
  [ACT_GET_PROG_SMSUSER_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.post('/v1/app/broadcast/sms/prog/list', params).then(response => resolve(response));
    });
  },
  [ACT_INSERT_PROG_SMSUSER]({}, params){
    return new Promise(resolve => {
      ApiService.post('/v1/app/broadcast/sms/prog/add', params).then(response => resolve(response));
    });
  },
  [ACT_INSERT_ALL_PROG_SMSUSER]({}, params){
    return new Promise(resolve => {
      ApiService.post('/v1/app/broadcast/sms/prog/addAll', params).then(response => resolve(response));
    });
  },
  [ACT_DELETE_PROG_SMSUSER]({}, params){
    return new Promise(resolve => {
      ApiService.post('/v1/app/broadcast/sms/prog/del', params).then(response => resolve(response));
    });
  },
  [ACT_DELETE_ALL_PROG_SMSUSER]({}, params){
    return new Promise(resolve => {
      ApiService.post('/v1/app/broadcast/sms/prog/delAll', params).then(response => resolve(response));
    });
  },
  [ACT_GET_PROG_SCHEDULE_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/broadcast/sms/prog/schedule', params).then(response => resolve(response));
    });
  },
  [ACT_INSERT_RECOMM_CNT]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/broadcast/brcSts', params).then(response => resolve(response));
    });
  },
  [ACT_INSERT_BRC_DCLR]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/broadcast/brcDclr', params).then(response => resolve(response));
    });
  },
}

export const broadcast = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
