<template>
	<div>
		<router-view />
		<common-alert />
	</div>
</template>

<script>
import SkipNav from './navigation/SkipNav';
import CommonAlert from '../../components/common/CommonAlert';
export default {
	name: 'LiveViewLayout',
	components: { CommonAlert },
	computed: {},
	data: () => ({}),
	async created() {},
	beforeDestroy() {},
	methods: {},
};
</script>
